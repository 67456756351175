import { PrismText } from '@prism-ui/react';
import classNames from 'classnames';
import './style.css';

const OfferDetails = ({ service }: any) => {
  const offerDetailsTableRow = classNames({
    'table-row': true,
    'table-row-rule-prominent': !service?.contractDetails,
  });

  return (
    <>
      <div className={offerDetailsTableRow}>
        <div className="table-row-key-prominent">
          <PrismText className="table-key-bold" display="bodyL">
            {service?.name}
          </PrismText>
        </div>
        {service?.total && (
          <div className="table-row-value-prominent">
            <PrismText display="bodyL">{service?.total}</PrismText>
          </div>
        )}
      </div>
      {service?.contractDetails && (
        <div className="table-row table-row-rule-contract">
          <div className="table-row-key contract-details">
            <PrismText display="bodyL">
              {service?.contractDetails}
            </PrismText>
          </div>
        </div>
      )}
    </>
  );
};

export default OfferDetails;

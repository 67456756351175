import { useContext, useEffect, useState } from 'react';
import { PrismInput, PrismText } from '@prism-ui/react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './style.css';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { pageConfirmation, pageError } from '../../../utility/global-constants/pages';
import { getInviteUser, getUpdateOrder } from '../../../api/index';
import LoadingIndicator from '../../molecules/atoms/accents/loadingIndicator';
import UserAgentContext from '../../../context/userAgent';
import AccountRegistrationContext from '../../../context/AccountRegistration';
import { registrationTypeValue, registrationStatusValue } from '../../../utility/global-constants/registrationStates';
import PrimaryManagerContext from '../../../context/primaryManager';
import { formStartEvent } from '../../../utility/updateDataLayer';
import { scrollToErrorField } from '../../../utility/submit';

interface IInviteRegProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
  confirmEmailAddress: string;
  requiredFields: string;
  sessionId: string;
  cimaToken: string;
  getNextStepBar: Function;
  validation: Validation;
}

export type Validation = {
  firstName: string;
  lastName: string;
  emailAddress: EmailValidation;
  confirmEmailAddress: EmailValidation;
};

type EmailValidation = {
  required: string;
  valid: string;
};

interface IFormInviteRegInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  confirmEmailAddress: string;
}

const fullFormSchema = (validation: Validation) =>
  yup.object().shape({
    firstName: yup.string().required(validation?.firstName),
    lastName: yup.string().required(validation?.lastName),
    emailAddress: yup.string().required(validation?.emailAddress?.required).email(validation?.emailAddress?.valid),
    confirmEmailAddress: yup
      .string()
      .required(validation?.confirmEmailAddress?.required)
      .oneOf([yup.ref('emailAddress')], validation?.confirmEmailAddress?.valid),
  });

export default function InviteRegistration({
  firstName,
  lastName,
  emailAddress,
  confirmEmailAddress,
  requiredFields,
  sessionId,
  cimaToken,
  getNextStepBar,
  validation,
}: IInviteRegProps) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInviteRegInput>({
    resolver: yupResolver(fullFormSchema(validation)),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const history = useHistory();
  const { updateRegistrationState } = useContext(AccountRegistrationContext);
  const { updatePrimaryManager } = useContext(PrimaryManagerContext);

  const userData = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    contactEmail: watch('emailAddress'),
    confirmEmail: watch('confirmEmailAddress'),
    scmsSessionId: sessionId,
  };

  const inviteUserMutation = useMutation(
    () => {
      setIsLoading(true);
      return getInviteUser(cimaToken, userData);
    },
    {
      onSuccess: () => {
        updatePrimaryManager(userData?.firstName, userData?.lastName, userData?.confirmEmail);
      },
    }
  );

  const updateOrderMutation = useMutation(() => {
    setIsLoading(true);
    return getUpdateOrder(cimaToken, sessionId);
  });

  useEffect(() => {
    document.querySelector('form')?.addEventListener('invalid', (e) => e.preventDefault(), true);
  }, []);

  useEffect(() => {
    if (errors.firstName) {
      scrollToErrorField('firstName');
    } else if (errors.lastName) {
      scrollToErrorField('lastName');
    } else if (errors.emailAddress) {
      scrollToErrorField('emailAddress');
    } else if (errors.confirmEmailAddress) {
      scrollToErrorField('confirmEmailAddress');
    } 
  }, [errors]);

  useEffect(() => {
    if (inviteUserMutation.isSuccess && updateOrderMutation.data?.status === 'Success') {
      updateRegistrationState(registrationTypeValue.invite, registrationStatusValue.success);
      setIsLoading(false);
      history.push(pageConfirmation.route);
    } else if (inviteUserMutation.isError && updateOrderMutation.data?.status === 'Success') {
      updateRegistrationState(registrationTypeValue.invite, registrationStatusValue.failed);
      setIsLoading(false);
      history.push(pageConfirmation.route);
    } else if (updateOrderMutation.data?.response?.data?.status === 'Failure') {
      setIsLoading(false);
      history.push(pageError.route);
    }
  }, [inviteUserMutation, updateOrderMutation, history, updateRegistrationState]);

  const onSubmit: SubmitHandler<IFormInviteRegInput> = (value: any) => {
    inviteUserMutation.mutateAsync();
    updateOrderMutation.mutateAsync();
  };

  const isUserAgent = useContext(UserAgentContext);

  return (
    <form data-tracking='{"container": ["invite-registration"]}' onSubmit={handleSubmit(onSubmit)}>
      <div className="invite-reg-container">
        <div className="invite-reg-inner">
          <div className="invite-reg-section1">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={firstName}
                  name="firstName"
                  type="text"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'invite');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.firstName?.message ? true : false}
                  invalidMessage={errors.firstName?.message}
                  value={value}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={lastName}
                  name="lastName"
                  type="text"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'invite');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.lastName?.message ? true : false}
                  invalidMessage={errors.lastName?.message}
                  value={value}
                />
              )}
            />
          </div>
          <div className="invite-reg-section2">
            <Controller
              name="emailAddress"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={emailAddress}
                  name="emailAddress"
                  type="text"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'invite');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.emailAddress?.message ? true : false}
                  invalidMessage={errors.emailAddress?.message}
                  value={value}
                />
              )}
            />
            <Controller
              name="confirmEmailAddress"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={confirmEmailAddress}
                  name="confirmEmailAddress"
                  type="text"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'invite');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.confirmEmailAddress?.message ? true : false}
                  invalidMessage={errors.confirmEmailAddress?.message}
                  value={value}
                />
              )}
            />
          </div>
        </div>
        <div className="invite-reg-required-text">
          <PrismText>{requiredFields}</PrismText>
        </div>
        {isLoading && <LoadingIndicator />}
        {getNextStepBar()}
      </div>
    </form>
  );
}

import { analyticsEventName } from './global-constants/analytics';

export const pageViewUpdate = () => {
  document.dispatchEvent(
    new CustomEvent(analyticsEventName.pageViewEvent, {
      bubbles: true,
    })
  );
};

export const spaTransition = () => {
  document.dispatchEvent(
    new CustomEvent(analyticsEventName.spaTransitionEvent, {
      bubbles: true,
    })
  );
};

export const formStartEvent = (target, page) => {
  target?.dispatchEvent(
    new CustomEvent(analyticsEventName.actionEvent, {
      detail: {
        eventAction: `${page}-registration-form`,
        eventMethod: 'form-start',
        eventName: 'form start',
      },
      bubbles: true,
    })
  );
};

export const trackDOMCustomEvents = (target, eventDetail) => {
  target?.dispatchEvent(
    new CustomEvent(analyticsEventName.actionEvent, {
      detail: eventDetail,
      bubbles: true,
    })
  );
};

export const customPageStartEvent = (target, page) => {
  target?.dispatchEvent(
    new CustomEvent(analyticsEventName.actionEvent, {
      detail: {
        eventPage: `${page}`,
        eventMethod: 'fmarr-Track',
        registrationStepName: 'fmarr|registration start',
      },
      bubbles: true,
    })
  );
};

import TableRow from '../../listTable/tableRow';
import './style.css';

interface Offer {
  provider: string;
  card: 'amazon-white' | 'amazon-black' | 'visa-white' | 'visa-black';
  left: string;
}

interface Offers {
  rows?: Offer[];
}

interface Props {
  specialOffers: Offers;
}

function SpecialOffers({ specialOffers }: Props) {
  const rows = specialOffers && specialOffers.rows ? specialOffers.rows : [];

  return (
    <>
      {rows && (
        <>
          <div className="special-offers">
            {rows.map((offer: Offer, index: number) => (
              <TableRow
                key={`special-offer-${index}`}
                provider={offer.provider}
                card={offer.card}
                left={offer.left}
              ></TableRow>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default SpecialOffers;

import { letterOfAgency, respOrgAgreement } from '../../utility/global-constants/contractTypes';
import {
  pageTermsAndConditions,
  pageDocumentsAndAgreements,
  pageOrderDetails,
  pageRegistration,
  phoneTransfer,
  pageConfirmation,
} from '../../utility/global-constants/pages';
import { useQuery, useQueries, useQueryClient } from 'react-query';
import { getInitializeData, getContentData, getBootstrapData, getOnboardingUrl } from '../../api';
import { IBootstrapData } from '../../interfaces';
import {
  GET_CONTENT_DATA_CALL,
  GET_INITIALIZE_DATA_CALL,
  BOOTSTRAP_CALL,
} from '../../utility/global-constants/queryKeys';
import { sharedConfig } from '../../config/shared/config';
import { env } from '../../utility/global-constants/queryKeys';

export const useGetCimaToken = () => {
  const { data: bootstrapData, isError } = useQuery<IBootstrapData, Error>(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;
  const paramError = bootstrapData?.isError;

  return { cimaToken, isError, paramError };
};

export const useGetInitializeData = (token: any) => {
  return useQuery(['esignInitialize', token], getInitializeData, { enabled: !!token });
};

export const useGetDocuments = (token: any) => {
  const { data, error, isError, isIdle, isLoading } = useGetInitializeData(token);
  return {
    documents: data?.attachments?.documents,
    queryStatus: {
      error: error,
      isError: isError,
      isIdle: isIdle,
      isLoading: isLoading,
    },
  };
};

export const useContentData = () => {
  const { data, error, isError, isIdle, isLoading, isSuccess } = useContentApiData();

  return {
    contentData: data,

    queryStatus: {
      error,
      isError,
      isIdle,
      isLoading,
      isSuccess,
    },
  };
};

export const useContentApiData = () => {
  return useQuery(GET_CONTENT_DATA_CALL, getContentData);
};

export const pageAllocation = (data: any, contentData: any) => {
  var pageObject: any[] = [];
  var pageHeading: any[] = [];
  var pageIndex: any[] = [];
  var count = 1;
  if (data) {
    if (!data.isOrderAlreadySubmited && !data.isUpdateOrderSuccess) {
      pageObject.push(pageOrderDetails);
      pageHeading.push(contentData?.components?.header?.reviewOrder);
      pageIndex.push(count);
    } else {
      pageObject.push(pageConfirmation);
      pageHeading.push('');
      pageIndex.push(count);
    }

    if (data?.attachments?.documents) {
      pageObject.push(pageDocumentsAndAgreements);
      pageHeading.push(contentData?.components?.header?.documentsAgreements);
      pageIndex.push(count + 1);
    } else if (data?.attachments?.contracts) {
      pageObject.push(pageTermsAndConditions);
      pageHeading.push(contentData?.components?.header?.termsConditions);
      pageIndex.push(count + 1);
    }

    if (data?.attachments?.contracts) {
      data.attachments.contracts.forEach((row: any) => {
        if (row.contractType === letterOfAgency || row.contractType === respOrgAgreement) {
          pageObject.push(phoneTransfer);
          pageHeading.push(contentData?.components?.header?.phoneTransferAgreement);
          pageIndex.push(count + 1);
        }
      });
    }
    if (data.isEligibleForAccountRegistration) {
      pageObject.push(pageRegistration);
      pageHeading.push(contentData?.components?.header?.myAccount);
      pageIndex.push(count + 1);
    }

    let routes = pageObject.map((item: any) => item.route);

    let finalRoutes = routes.filter((value: any, index: any) => routes.indexOf(value) === index);

    let finalPageHeading = pageHeading.filter((value: any, index: any) => pageHeading.indexOf(value) === index);

    let finalData = {
      finalRoutes,
      finalPageHeading,
      pageIndex,
    };

    return finalData;
  } else {
    return null;
  }
};

export const useGetCustomerAndContentData = (): any => {
  const { cimaToken, isError, paramError } = useGetCimaToken();
  const queryArray = [
    {
      queryKey: GET_INITIALIZE_DATA_CALL,
      queryFn: () => getInitializeData(cimaToken),
      enabled: !!cimaToken,
      retryDelay: () => (env?.initializeCall504DelayEnabled ? 5000 : 0),
      retry: (failureCount: any, error: any) => (failureCount < 1 && error?.response?.status === 504 ? true : false),
    },
    { queryKey: GET_CONTENT_DATA_CALL, queryFn: () => getContentData() },
  ];
  const results = useQueries(queryArray);
  const queryClient = useQueryClient();
  const initializeData = queryClient.getQueryState(GET_INITIALIZE_DATA_CALL);
  const contentData = queryClient.getQueryState(GET_CONTENT_DATA_CALL);

  return {
    isLoading: results.some((result) => result.isLoading),
    isSuccess: results.every((result) => result.isSuccess),
    isError: isError || paramError || results.some((result) => result.isError),
    initializeData: initializeData?.data ? initializeData.data : {},
    contentData: contentData?.data ? contentData.data : {},
  };
};

export const useGetOnboardingUrl = (sessionId: any, isOnboardingUrlRequired: any, environment: any) => {
  const { cimaToken } = useGetCimaToken();
  const {
    data: registrationUrl,
    isError,
    isLoading,
  } = useQuery([cimaToken, sessionId], getOnboardingUrl, {
    enabled: isOnboardingUrlRequired,
  });

  var onboardingUrl = isError
    ? `${environment?.myAccount?.baseUrl}${sharedConfig.frictionLess.routes.myAccount}`
    : registrationUrl;
  onboardingUrl = onboardingUrl ? onboardingUrl : null;

  return { onboardingUrl, isLoading };
};

import Content from '../../interfaces/api/content';
import Initialize from '../../interfaces/api/initialize';
import { termsAndConditionsObject } from './helper';

export default function derivedDataTerms<T extends Content, U extends Initialize>({
  contentData,
  initializeData,
}: {
  contentData: T;
  initializeData: U;
}) {
  try {
    const { attachments, contact, offer: { promoCodeText }, specialInstructions } = initializeData;

    const { contracts } = attachments;

    const {
      pages: {
        agreements: {
          documents: { termsAndConditions },
        },
      },
    } = contentData;

    const promoOfferDescription = specialInstructions ? specialInstructions : promoCodeText;
    
    const { nextButton } = contentData.pages.review.orderDetails;

    const orderCompleteButton = contentData?.pages?.agreements?.documents?.common?.orderCompleteButton;

    let finalObject = {
      termsAndConditions: termsAndConditionsObject(termsAndConditions, contracts, contact, promoOfferDescription),
      nextButton,
      orderCompleteButton,
    };

    return finalObject;
  } catch (err) {
    return null;
  }
}

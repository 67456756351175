import { useEffect, useContext } from 'react';
import { useQueries, useQueryClient } from 'react-query';
import { getContentData, getBootstrapData, getInitializeData } from '../../api/index';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { pageAllocation } from '../../hooks/dml/documents.hooks';
import {
  GET_CONTENT_DATA_CALL,
  GET_INITIALIZE_DATA_CALL,
  BOOTSTRAP_CALL,
} from '../../utility/global-constants/queryKeys';
import { PrismLoading } from '@prism-ui/react';
import LoadingIndicator from '../molecules/atoms/accents/loadingIndicator';
import AccountRegistrationContext from '../../context/AccountRegistration';
import { registrationTypeValue } from '../../utility/global-constants/registrationStates';
import { getQueryStringObject } from '../../utility/helper';
import { env } from '../../utility/global-constants/queryKeys';

const Documents = () => {
  const bootstrapQuery = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  var cimaToken = bootstrapQuery?.data?.cimaToken?.accessToken;
  const { registrationState, updateRegistrationState } = useContext(AccountRegistrationContext);

  var history = useHistory();

  const all = useQueries([
    {
      queryKey: GET_INITIALIZE_DATA_CALL,
      queryFn: () => getInitializeData(cimaToken),
      enabled: !!cimaToken,
      retryDelay: () => (env?.initializeCall504DelayEnabled ? 5000 : 0),
      retry: (failureCount, error) => (failureCount < 1 && error?.response?.status === 504 ? true : false),
    },
    {
      queryKey: GET_CONTENT_DATA_CALL,
      queryFn: () => getContentData(),
    },
  ]);

  const queryClient = useQueryClient();
  const initializeData = queryClient.getQueryState(GET_INITIALIZE_DATA_CALL);

  useEffect(() => {
    const queryParams = getQueryStringObject();
    if (queryParams['triggererror']) {
      throw new Error('Forced error for testing');
    }
  }, []);

  // updates linkAccount registration state
  useEffect(() => {
    if (
      initializeData?.data?.isEligibleForAccountRegistration === false &&
      registrationState.type !== registrationTypeValue.signNow
    )
      updateRegistrationState(registrationTypeValue.linkAccount, null);
  }, [initializeData?.data, registrationState, updateRegistrationState]);

  if (bootstrapQuery.isError || bootstrapQuery?.data?.isError)
    throw new Error('The bootstrap/v1/frictionless/token api call failed');
  if (all.some((e) => e.isLoading) || all.some((e) => e.isIdle))
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  if (all.some((e) => e.error))
    return <div>There's been an error getting the data from the server. Refresh the page.</div>;
  if (all.some((e) => e.data) || all.some((e) => e.isIdle)) {
    const finalPages = pageAllocation(all[0].data);
    history.push(finalPages.finalRoutes[0]);
  }
  return (
    <div>
      <PrismLoading />
    </div>
  );
};

export default Documents;

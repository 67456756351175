import './style.css';
import { PrismButton, PrismIcon, PrismImg, PrismText } from '@prism-ui/react';

export default function NavigationSection(props: any) {
  return (
    <div className="confirmation-navigation-section">
      <div className="navigation-subsection section1">
        <PrismImg src={props.image}></PrismImg>
      </div>
      <div className="navigation-subsection section2">
        <div className="navigation-space1" />
        <PrismText display={'heading5'}>{props.title}</PrismText>
        <div className="navigation-space2" />
        <PrismText display={'bodyM'}>{props.description}</PrismText>
        <div className="navigation-space3" />
        <div className="navigation-link">
          <PrismButton class="button" display="link" slot="button" target="_blank" href={props.link}>
            {props.label}
            <PrismIcon slot="icon-trailing" name="arrow_right" size="sm" theme="outline" />
          </PrismButton>
        </div>
      </div>
    </div>
  );
}

import { PrismIcon, PrismText } from '@prism-ui/react';
import './style.css';

export default function MyAccountLink(props: any) {
  return (
    <div className="account-management-section">
      <PrismIcon size='md' slot="icon" path={props.iconName} />
      <div className='additional-padding'/>
      <PrismText display={'heading7'}>{props.title}</PrismText>
      <div className='additional-padding'/>
      <PrismText display={'bodyM'}>{props.description}</PrismText>
    </div>
  );
}

import './checkbox.css';
import { useState, useEffect } from 'react';
import { CheckmarkBox, Label, ErrorMessage } from './components';

interface IPrismCheckbox {
  label?: string;
  invalidMessage?: string;
  required: boolean;
  disabled: boolean;
  name?: string;
  className?: string;
  onPrismFocus?: Function;
  onPrismValueChange?: Function;
  invalid?: boolean;
  checked?: boolean;
  labelForModal?: React.ReactNode;
}

export default function PrismCheckbox({
  label,
  invalidMessage,
  required,
  disabled,
  name,
  className,
  onPrismFocus,
  onPrismValueChange,
  invalid,
  checked,
  labelForModal,
}: IPrismCheckbox) {
  const uniqueId = Math.random().toString(36).substring(3);
  const errorId = `${uniqueId}-error`;
  const [inputStates, setInputStates] = useState({
    isNew: true,
    isValid: false,
    isChecked: false,
    isReadOnly: false,
    onFocus: false,
    onHover: false,
  });
  const { isNew, isValid, isChecked, onFocus } = inputStates;

  useEffect(() => {
    if (invalid && invalidMessage) {
      setInputStates((inputs) => ({ ...inputs, isNew: false, isValid: false, isChecked: false }));
    }
  }, [invalid, invalidMessage]);

  const handleChange = (event: any) => {
    if (isNew) {
      setInputStates((inputs) => ({ ...inputs, isNew: false }));
    }
    if (isChecked) {
      setInputStates((inputs) => ({ ...inputs, isChecked: false, isValid: false }));
    } else {
      setInputStates((inputs) => ({ ...inputs, isChecked: true, isValid: true }));
    }
    if (onPrismValueChange) {
      onPrismValueChange(event);
    }
    event.stopPropagation();
  };

  const onCheckValidity = (event: any) => {
    setInputStates((inputs) => ({ ...inputs, isNew: false, isValid: false }));
    event.preventDefault();
  };

  const handleFocus = (event: any) => {
    setInputStates((inputs) => ({ ...inputs, onFocus: true }));
    if (onPrismFocus) {
      onPrismFocus(event);
    }
  };

  return (
    <div className={`prism-checkbox ${className}`}>
      <div className={`flex items-start ${disabled ? '' : 'label'} ${isChecked ? 'checked' : ''}`}>
        <label className={'flex'}>
          <input
            name={name}
            id={uniqueId}
            className={'input custom-checkbox'}
            type="checkbox"
            checked={isChecked || checked}
            onChange={handleChange}
            disabled={disabled}
            required={required}
            aria-invalid={!isValid}
            aria-required={required}
            onFocus={(e) => {
              handleFocus(e);
            }}
            onBlur={(e) => {
              setInputStates((inputs) => ({ ...inputs, onFocus: false }));
            }}
            onInvalid={(e) => onCheckValidity(e)}
            readOnly={inputStates.isReadOnly}
          />
          <CheckmarkBox disabled={disabled} isChecked={isChecked} isNew={isNew} onFocus={onFocus} />
          <div>
            <Label label={label} modal={labelForModal}/>
          </div>
        </label>
      </div>
      <p></p>
      <ErrorMessage invalidMessage={invalidMessage} errorId={errorId} isChecked={isChecked} isNew={isNew} />
    </div>
  );
}

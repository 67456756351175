import { PrismText } from '@prism-ui/react';
import { Heading } from '../../../interfaces';
import './style.css';

interface Props extends Heading {
  description?: string;
}

function TableSectionHeader({ heading, description }: Props) {
  return (
    <div className="table-section-header">
      <PrismText display="heading5">{heading}</PrismText>
      {description && (
        <div className="table-section-header-description">
          <PrismText display="bodyS">{description}</PrismText>
        </div>
      )}
    </div>
  );
}

export default TableSectionHeader;

import { useContext, useEffect, useState } from 'react';
import NavigationBar from '../../components/navigation-bar';
import { pageTermsAndConditions } from '../../utility/global-constants/pages';
import { PrismSectionHeading } from '@prism-ui/react';
import './style.css';
import { useGetCustomerAndContentData } from '../../hooks/dml/documents.hooks';
import { pageAllocation } from '../../hooks/dml/documents.hooks';
import derivedDataTerms from './derivedData';
import Container from '../../components/container';
import StepperNavigation from '../../containers/stepper/stepperNavigation';
import NextStepBar from '../../containers/stepper/nextStepBar';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useQuery } from 'react-query';
import { BOOTSTRAP_CALL } from '../../utility/global-constants/queryKeys';
import { getBootstrapData } from '../../api/index';
import { SessionContext } from '../../../src/App';
import { pageViewUpdate, spaTransition } from '../../utility/updateDataLayer';
import TrackingData from '../../components/experienceTracking/TrackingData';
import { onSubmit, checkBoxEventListener, checkInvalidMessage } from '../../utility/submit';
import Contracts from '../../components/contracts';
import { isLastStep } from '../../utility/steps';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';

export default function TermsAndConditions() {
  const { isLoading, isError, initializeData, contentData } = useGetCustomerAndContentData();
  const data = derivedDataTerms({ contentData, initializeData });
  var pageData: any;
  var isSubmission: boolean = false;
  var history = useHistory();
  const [isLoader, setIsLoader] = useState(false);

  const page = {
    pageInfo: {
      screenName: pageTermsAndConditions.screenName,
    },
  };

  useEffect(() => {
    document.title = 'Terms & Conditions';
    checkBoxEventListener();
    pageViewUpdate();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return history?.listen(() => {
      spaTransition();
    });
  }, [history]);

  if (data) {
    pageData = pageAllocation(initializeData, contentData);
  }

  const sessionData = useContext(SessionContext);

  if (pageData && pageData.finalRoutes) {
    pageData.finalRoutes.forEach(function (route: any, index: any) {
      if (window.location.href.indexOf(route) > -1) {
        if (pageData.finalRoutes[pageData.finalRoutes.length - 1] === route) {
          isSubmission = true;
        }
      }
    });
  }

  const { data: bootstrapData } = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;

  interface IFormInputs {
    LegalAgreement: boolean;
  }

  const nextButtonClicked = (item: any) => {
    setImmediate(checkInvalidMessage);
  };

  const { handleSubmit, control } = useForm<IFormInputs>();

  const submit: SubmitHandler<IFormInputs> = async (value: any) => {
    setIsLoader(true);
    const pageHistory = await onSubmit(isSubmission, sessionData.id, cimaToken, history.location.pathname, pageData);
    history.push(pageHistory);
  };

  if (isError) return <Redirect to="/error" />;

  return (
    <div>
      <NavigationBar page={pageTermsAndConditions.name} />
      <StepperNavigation
        pageData={pageData}
        history={history}
        backButtonText={contentData?.common?.actions?.backAction}
      />
      <Container>
        {(!data || isLoading || isLoader) && <LoadingIndicator />}
        {data && (
          <>
            <PrismSectionHeading heading={data?.termsAndConditions?.title} />
            <div className="terms-legal-main">
              <div className="terms-legal">
                <form onSubmit={handleSubmit(submit)}>
                  {data?.termsAndConditions?.sections?.length !== 0 && (
                    <div className="terms-and-condition">
                      <Controller
                        name="LegalAgreement"
                        control={control}
                        defaultValue={true}
                        rules={{ required: true }}
                        render={({ field }) => <Contracts sections={data?.termsAndConditions?.sections || []} />}
                      />
                    </div>
                  )}
                  <NextStepBar
                    pageData={pageData}
                    history={history}
                    completeOrder={isLastStep({ pageData })}
                    sessionData={sessionData}
                    nextClicked={nextButtonClicked}
                    content={data}
                  />
                </form>
              </div>
            </div>
          </>
        )}
      </Container>
      <TrackingData type="page" trackingInfo={page} />
    </div>
  );
}

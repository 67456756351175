import React, { FunctionComponent } from 'react';
import { default as stringifyDataTracking } from './stringifyDataTracking';

export type TrackingDataProps = {
  type?: string;
  trackingInfo?: unknown;
  dataTrackingAppend?: boolean;
};

export const TrackingData: FunctionComponent<TrackingDataProps> = ({
  type = 'digitalData',
  trackingInfo,
  dataTrackingAppend,
}) => {
  return (
    <script type={`tracking-data-${type}`} data-tracking-append={dataTrackingAppend && ''}>
      {stringifyDataTracking(trackingInfo)}
    </script>
  );
};

export default TrackingData;

import axios from 'axios';
import { cloneDeep } from 'lodash';
import {
  getQueryStringObject,
  checkSessionQueryParams,
  getRequestSensitiveFields,
  getResponseSensitiveFields,
} from '../utility/helper';
import { getConfig } from '../utility/env';
import { sharedConfig } from '../config/shared/config';
import { bootstrapHeaders } from '../utility/global-constants/queryKeys';
import { MessageType } from '../types/enums';
import version from '../version';
import { getBaseUrlAndUri } from '../utility/parseURL';
import { isSuccessCode } from '../utility/saLogging';
import { maskFields } from '../utility/format/index';

const bootstrapQueryParams = getQueryStringObject();
const env = getConfig();

export async function getContentData() {
  const fullContentRouteName = `${env.content.baseUrl}${sharedConfig.frictionLess.routes.content}`;
  const { data } = await axios.get(fullContentRouteName);
  return data.data;
}

export async function getBootstrapData() {
  if (checkSessionQueryParams()) {
    const fullBootstrapRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.bootstrap}`;
    const { data } = await axios.post(fullBootstrapRouteName, bootstrapQueryParams, { headers: bootstrapHeaders });
    return data.data;
  } else {
    return {
      isError: true,
    };
  }
}

export async function getInitializeData(token) {
  bootstrapHeaders['Authorization'] = token;
  const fullInitializeRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.initialize}`;
  const { data } = await axios.post(fullInitializeRouteName, bootstrapQueryParams, { headers: bootstrapHeaders });
  return data.data;
}

export async function getUpdateOrder(token, sessionId) {
  bootstrapHeaders['Authorization'] = token;
  const fullUpdateRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.update}?id=${sessionId}`;
  try {
    const { data } = await axios.post(fullUpdateRouteName, '', { headers: bootstrapHeaders });
    return data;
  } catch (error) {
    if (error && error?.response?.status === 504) {
      // Added 5 Sec delay to update cache incase of 504 gateway timeout
      if (env?.updateCall504DelayEnabled) await new Promise((resolve) => setTimeout(resolve, 5000));
      const { data } = await axios.post(fullUpdateRouteName, '', { headers: bootstrapHeaders });
      return data;
    } else if (error && !error.response) {
      // handling CORS issue or error without response
      const err = {
        response: {
          data: {
            status: 'Failure',
          },
        },
      };
      return err;
    }
    return error;
  }
}

export async function getCreateUser(token, userData) {
  bootstrapHeaders['Authorization'] = token;
  bootstrapHeaders['Content-Type'] = 'application/json';
  const fullCreateUserRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.createUser}`;
  const { data } = await axios.post(fullCreateUserRouteName, userData, { headers: bootstrapHeaders });
  return data;
}

export async function getInviteUser(token, userData) {
  bootstrapHeaders['Authorization'] = token;
  bootstrapHeaders['Content-Type'] = 'application/json';
  const fullCreateUserRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.inviteUser}`;
  const { data } = await axios.post(fullCreateUserRouteName, userData, { headers: bootstrapHeaders });
  return data.data;
}

export async function uploadDocument(requestBody, token) {
  bootstrapHeaders['Authorization'] = token;
  const fullUploadRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.uploadDocument}`;
  const { data } = await axios.post(fullUploadRouteName, requestBody, { headers: bootstrapHeaders });
  return data;
}

export async function uploadDocumentToS3Bucket(requestBody, url) {
  const { data } = await axios.post(url, requestBody, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function uploadDocumentStatus(requestBody, token) {
  bootstrapHeaders['Authorization'] = token;
  const fullUploadRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.updateStatus}`;
  const { data } = await axios.post(fullUploadRouteName, requestBody, { headers: bootstrapHeaders });
  return data;
}

export async function getOnboardingUrl(params) {
  const [cimaToken, sessionId] = params.queryKey;
  bootstrapHeaders['Authorization'] = cimaToken;
  bootstrapHeaders['Content-Type'] = 'application/json';
  const fullOnboardingUrlRouteName = `${env.eSign.baseUrl}${sharedConfig.frictionLess.routes.onboardingUrl}${sessionId}`;
  const { data } = await axios.get(fullOnboardingUrlRouteName, { headers: bootstrapHeaders });
  return data.data;
}

const logErrorBoundaryMessage = (message, trackingID, sessionID) => {
  const config = getConfig();

  const additionalData = {
    Host: config.eSign.baseUrl,
    LogType: 'Message',
    Level: MessageType.Error,
    Severity: 'High',
    Message: message,
    Properties: {
      location: window.location.pathname,
      componentName: 'ErrorBoundary',
      messageType: 'JS Error',
    },
  };

  api.logMessage(additionalData, trackingID, sessionID);
};

const logMessage = async (additionalData, trackingID, sessionID) => {
  try {
    const config = getConfig();
    const date = new Date().toISOString();
    const environment = config.name;
    const data = Object.assign(
      {},
      {
        AppId: `bsd-digital-bsee-frictionless-ui-${environment}`,
        TrackingId: trackingID,
        TraceId: trackingID,
        CreateDateUtc: date,
        AppType: 'ClientApp',
        AppVersion: version,
        Environment: environment,
        CustomerId: sessionID,
        StartTimeUtc: date,
        EndTimeUtc: date,
        Duration: 0,
      },
      additionalData
    );

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-api-key': config.logging.key,
        'Content-Type': 'application/json',
        'tracking-id': trackingID,
      },
      body: JSON.stringify(data),
    };

    await fetch(`${env.logging.baseUrl}${sharedConfig.frictionLess.routes.errorLog}`, options).then((response) => {
      console.log('Error log Response', response.json());
    });
  } catch (err) {
    console.error("The correct parameters weren't available for logging.");
  }
};

const serviceLogging = async (response, trackingID, sessionID) => {
  try {
    const config = getConfig();
    const date = new Date().toISOString();
    const cloneResponse = cloneDeep(response);
    const startTime = cloneResponse.config.metadata.startTime.toISOString();
    const endTime = cloneResponse.config.metadata.endTime.toISOString();
    const environment = config.name;
    const { baseUrl, uri } = getBaseUrlAndUri(cloneResponse.config.url);

    // Fetching the Sensitve fields in request based on the Resource Uri
    const requestSensitiveFields = getRequestSensitiveFields(uri);
    // Fetching the Sensitve fields in response based on the Resource Uri
    const responseSensitiveFields = getResponseSensitiveFields(uri);

    // Fetching the Request Data and converting it JsonObject
    let requestData = cloneResponse.request?.data
      ? JSON.parse(cloneResponse.request.data)
      : cloneResponse.config?.data
      ? JSON.parse(cloneResponse.config.data)
      : null;
    // Masking the RequestData
    requestData = requestSensitiveFields ? maskFields(requestData, requestSensitiveFields) : requestData;

    // Fetching and mask the Response Data
    const responseData =
      responseSensitiveFields && cloneResponse.data
        ? maskFields(cloneResponse.data, responseSensitiveFields)
        : cloneResponse.data;

    const data = {
      TrackingId: trackingID,
      TraceId: trackingID,
      AppId: `bsd-digital-bsee-frictionless-ui-${environment}`,
      CreateDateUtc: date,
      AppType: 'ClientApp',
      AppVersion: version,
      Environment: environment,
      BaseUrl: baseUrl,
      CustomerId: sessionID,
      LogType: 'SA',
      OperationType: cloneResponse.config.method.toUpperCase(),
      Level: isSuccessCode(cloneResponse.status) ? 'Info' : 'Error',
      Severity: 'Low',
      Status: isSuccessCode(cloneResponse.status) ? 'Success' : 'Failure',
      StatusCode: cloneResponse.status,
      Resource: uri,
      Request: JSON.stringify(requestData),
      Response: JSON.stringify(responseData),
      StartTimeUtc: startTime ? startTime : date,
      EndTimeUtc: endTime ? endTime : date,
      Duration: endTime - startTime,
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-api-key': config.logging.key,
        'Content-Type': 'application/json',
        'tracking-id': trackingID,
      },
      body: JSON.stringify(data),
    };

    await fetch(`${env.logging.baseUrl}${sharedConfig.frictionLess.routes.errorLog}`, options).then((response) => {
      console.log('ServiceLog Response', response.json());
    });
  } catch (err) {
    console.error("The correct parameters weren't available for the service logging.");
  }
};

const api = {
  logErrorBoundaryMessage,
  logMessage,
  serviceLogging,
};

export default api;

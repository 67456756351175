import { PrismIcon, PrismText } from '@prism-ui/react';
import './style.css';

export default function AutoAccountLink(props: any) {
  return (
    <div className="auto-account-management-container">
      <div className="auto-account-management-section">
        <div className="auto-icon-section">
          <PrismIcon size="md" slot="icon" path={props.iconName} />
        </div>
        <div className="auto-title-section">
          <PrismText display={'heading7'}>{props.title}</PrismText>
        </div>
      </div>
      <div className="auto-description-section">
        <PrismText display={'bodyM'}>{props.description}</PrismText>
      </div>
    </div>
  );
}

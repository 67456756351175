import { PrismText } from '@prism-ui/react';

interface ICheckmarkBox {
  isChecked: boolean;
  isNew: boolean;
  onFocus: boolean;
  disabled: boolean;
}

export const CheckmarkBox = ({ isChecked, isNew, onFocus, disabled }: ICheckmarkBox) => {
  return (
    <span
      className={`checkbox-border ${!isChecked && !isNew ? 'checkbox-error' : ''} ${onFocus ? 'checkbox-focus' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <CheckmarkIcon isChecked={isChecked} />
    </span>
  );
};

export const CheckmarkIcon = ({ isChecked }: { isChecked: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="7 8 17 14"
      fill="#414141"
      focusable="false"
      aria-hidden="true"
      role="presentation"
      stroke="none"
      className={`checkmark ${isChecked ? 'checked' : 'display-none'}`}
    >
      <path d="M12.6353698,21.7532192 L7.226033,15.7727216 C6.92465567,15.4436805 6.92465567,14.9081135 7.226033,14.5755719 L8.31871903,13.3801725 C8.62009637,13.0511314 9.11002773,13.0511314 9.41140506,13.3801725 L13.1824856,17.5666958 L21.5885949,8.24678085 C21.8899723,7.91773972 22.3799036,7.91773972 22.681281,8.24678085 L23.773967,9.44393049 C24.0753443,9.77297162 24.0753443,10.3102888 23.773967,10.6375797 L13.7280558,21.7532192 C13.4266785,22.0822603 12.9367471,22.0822603 12.6353698,21.7532192"></path>
    </svg>
  );
};

export const Label = ({ label, modal }: { label?: string; modal?: React.ReactNode }) => {
  return (
    <div className={'checkbox-label-text'}>
      <PrismText display="body1">
          <span dangerouslySetInnerHTML={{ __html: label ? label : '' }}/>
          {modal && <>&thinsp;{modal}</>}
      </PrismText>
    </div>
  );
};

interface IErrorMessage {
  invalidMessage?: string;
  errorId: string;
  isChecked: boolean;
  isNew: boolean;
}

export const ErrorMessage = ({ invalidMessage, errorId, isChecked, isNew }: IErrorMessage) => {
  if (!isChecked && !isNew) {
    return (
      <span className={'invalid flex items-center space-x-1 mt-4 marker'}>
        {/* <PrismIcon name="alert" /> */}
        <PrismText display="caption" id={errorId} className={'invalid-message font-bold'}>
          {invalidMessage ? invalidMessage : 'This field is required.'}
        </PrismText>
      </span>
    );
  }
  return null;
};

const CheckboxComponents = { CheckmarkBox, CheckmarkIcon, Label, ErrorMessage };

export default CheckboxComponents;

import { PrismInput } from '@prism-ui/react';
import { useContext, useState } from 'react';
import { uploadDocument, uploadDocumentStatus, uploadDocumentToS3Bucket } from '../../api';
import UserAgentContext from '../../context/userAgent';
import { getConfig } from '../../utility/env';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import UploadedDocument from './uploadedDocument';
import { returnFileSize } from '../../utility/format/index';

export default function UploadDocuments(props: any) {
  const config = getConfig();
  const isUserAgent = useContext(UserAgentContext);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onUploadFile = async (event: any, type: any, index: number) => {
    var documentID: any;

    setIsLoading(true);

    props.validateDoc(type, { value: false, empty: false });

    props.docs.forEach((item: any) => {
      if (item.type === type) {
        documentID = item.id;
      }
    });
    try {
      const reqBody = {
        id: props.sessionData.id,
        file: event.nativeEvent.target.files[0].name,
        type: type,
        environment: config.name,
        documentId: documentID,
      };
      var response = await uploadDocument(reqBody, props.token);
      const finalResponse = response.data.fields;

      const formData = new FormData();

      if (response.status === 'Success') {
        formData.append('Content-Type', finalResponse['Content-Type']);
        formData.append('key', finalResponse['key']);
        formData.append('bucket', finalResponse['bucket']);
        formData.append('X-Amz-Algorithm', finalResponse['X-Amz-Algorithm']);
        formData.append('X-Amz-Credential', finalResponse['X-Amz-Credential']);
        formData.append('X-Amz-Date', finalResponse['X-Amz-Date']);
        formData.append('X-Amz-Security-Token', finalResponse['X-Amz-Security-Token']);
        formData.append('Policy', finalResponse['Policy']);
        formData.append('X-Amz-Signature', finalResponse['X-Amz-Signature']);
        formData.append('X-Amz-Meta-Doc-Type', finalResponse['X-Amz-Meta-Doc-Type']);
        formData.append('file', event.nativeEvent.target.files[0]);

        var s3Response = await uploadDocumentToS3Bucket(formData, response.data.url);
        console.log('s3Response', s3Response);
        if(s3Response==="")
        {
          const statusReqBody = {
            id: props.sessionData.id,
            documentId: documentID,
            fileName: event.nativeEvent.target.files[0].name
          };
          var statusResponse = await uploadDocumentStatus(statusReqBody, props.token);
          const finalStatusResponse = statusResponse.data.status;
          console.log('finalStatusResponse', finalStatusResponse);
        }
        props.validateDoc(type, { value: false, empty: false });
        window.sessionStorage.setItem(`input-file-${index}`, JSON.stringify({name: event.nativeEvent.target.files[0].name, size: returnFileSize(event.nativeEvent.target.files[0].size)}));
        setIsLoading(false);
      }
    } catch (error) {
      props.validateDoc(type, { value: true, empty: false });
      setIsLoading(false);
    }
  };

  const clearFileInput = (index: number) => {
    document.getElementById(`input-file-${index}`)?.setAttribute('value', '');
    setRefresh(!refresh);
  };

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      {props?.sections?.map((section: any, index: any) => {
        return (
          window.sessionStorage.getItem(`input-file-${index}`) ? (
            <UploadedDocument
              key={`input-file-${index}`}
              header={section.title}
              description={section.description}
              fileName={JSON.parse(window.sessionStorage.getItem(`input-file-${index}`) || '')?.name}
              fileSize={JSON.parse(window.sessionStorage.getItem(`input-file-${index}`) || '')?.size}
              handleClick={() => {
                window.sessionStorage.removeItem(`input-file-${index}`);
                props.validateDoc(section.type, { value: true, empty: true });
                clearFileInput(index);
              }}
            />
          ) : (
            <PrismInput
              key={`input-file-${index}`}
              data-testid="docid"
              id={`input-file-${index}`}
              input-id={`input-file-${index}`}
              type="file"
              label="Attach A File"
              multiple={false}
              name={`input-file-${index}`}
              required={true}
              invalid={section.invalid}
              invalidMessage={section.invalidMessage}
              disabled={isUserAgent}
              fileHeading={section.title}
              fileDescription={section.description}
              onInput={(e) => {
                onUploadFile(e, section.type, index);
              }}
              onPrismValueChange={(e) => {
                if (e?.detail === null || e?.detail === '') {
                  props.validateDoc(section.type, { value: true, empty: true });
                }
              }}
            ></PrismInput>
          )
      )})}
    </div>
  );
}

import { apiKeys } from './../../utility/global-constants/authentication';

export const prodConfig = {
  name: 'prod',
  logging: {
    baseUrl: 'https://prod.core-gateway.api-business.comcast.com/',
    key: 'yAngNLqzCe5a1UkbuQohY4nKe9LVGsqP6zroKplB',
  },
  eSign: {
    baseUrl: 'https://ecom-gateway.api-business.comcast.com/',
  },
  content: {
    baseUrl: 'https://content.ecom.digital.business.comcast.com',
  },
  myAccount: {
    baseUrl: 'https://business.comcast.com/',
  },
  frictionLess: {
    baseUrl: '',
  },
  dataLayer: {
    src: 'https://static.cimcontent.net/data-layer/datalayer-commercial-dtm.min.js?appId=busn_ecom',
  },
  signNow: {
    confirmation: {
      new: 'https://comcast--c.na72.visual.force.com/apex/FT_FlexiQuote?id=<opp_id>&page=schedule',
      existing: 'https://prod-g.fxbuyflow.business.comcast.com/sign-now/success?id=<opp_id>',
    },
    back: {
      new: 'https://comcast--c.na72.visual.force.com/apex/FT_FlexiQuote?id=<opp_id>',
      existing: 'https://prod-g.fxbuyflow.business.comcast.com/sign-now/reconfigure?id=<opp_id>',
    },
  },
  'api-key': apiKeys.prod,
  serviceLoggingEnabled: true,
  initializeCall504DelayEnabled: true,
  updateCall504DelayEnabled: true
};

export const analyticsEventName = {
  pageViewEvent: 'c-tracking-log-page',
  spaTransitionEvent: 'c-tracking-log-page-start',
  actionEvent: 'c-tracking-log-dom',
};

export const tracking = {
  BSEE: 'bsee',
  BUSN: 'busn',
  FRICTIONLESS: 'frictionless',
  INVITATION_SETUP: 'invitation/setup',
  RESPONSIVE: 'responsive',
  SALES_SELF_SERVICE: 'sales/selfservice',
  SALES_MY_ACCOUNT: 'sales/myaccount',
  ACCOUNT_REGISTRATION: 'busn|selfservice|frictionless| set up my account',
  SETUP: 'setup',
  GATEWAY_ESIGN: 'gateway/esign',
  SALES: 'sales',
  SELF_SERVICE: 'selfservice',
  MY_ACCOUNT: 'myaccount',
};

import { PrismBanner } from '@prism-ui/react';
import { useContentData } from '../../hooks/dml/documents.hooks';
import { getHeading } from '../../utility/getHeading';
interface Props {
  page: string;
}

export default function NavigationBar({ page }: Props) {
  const { contentData } = useContentData();

  let heading = '';

  if (contentData) heading = getHeading({ page, contentData });

  return (
    <PrismBanner heading={heading}>
      <div slot="side">
        <svg aria-labelledby="comcast-business-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 36">
          <title id="comcast-business-logo"> Comcast Business Logo </title>
          <g fill="currentColor">
            <path d="M22.2 13.7c-3.2 0-5.9-2.6-5.9-5.9 0-3.2 2.6-5.9 5.9-5.9s5.9 2.6 5.9 5.9c0 3.2-2.7 5.9-5.9 5.9m0-13.2c-4 0-7.3 3.3-7.3 7.3s3.3 7.3 7.3 7.3 7.3-3.3 7.3-7.3c0-4.1-3.3-7.3-7.3-7.3M85 7.9c-.4-.2-2.8-1.4-3.1-1.6-1.2-.6-1.7-1.2-1.7-2.2 0-1.3 1.1-2.2 2.8-2.2 1 0 1.9.4 2.5.8.1.1.2.1.3.1.4 0 .7-.3.7-.7 0-.3-.1-.5-.4-.6-.7-.4-1.9-.9-3.2-.9-2.5 0-4.2 1.5-4.2 3.6 0 1.9 1.3 2.8 2.5 3.5.3.2 2.7 1.4 3.1 1.6.9.5 1.5 1.2 1.5 2.1 0 1.2-1 2.4-2.8 2.4s-3.1-1.2-3.5-1.5l-.1-.1-1.1 1 .1.1c.5.5 2.2 2 4.5 2 2.6 0 4.2-1.9 4.2-3.8.2-1.6-.6-2.9-2.1-3.6M7.3 1.9c1.6 0 3 .6 4.1 1.7.3.3.8.3 1 0 .1-.1.2-.3.2-.5s-.1-.4-.2-.5l-.1-.1c-1.3-1.3-3.1-2-5-2C3.3.5 0 3.7 0 7.8c0 4 3.3 7.3 7.3 7.3 2.1 0 4-.9 5.4-2.4l-1-1c-1.1 1.2-2.6 1.9-4.3 1.9-3.2 0-5.9-2.6-5.9-5.9-.1-3.2 2.6-5.8 5.8-5.8M56.7 1.9c1.6 0 3 .6 4.1 1.7.3.3.8.3 1 0 .1-.1.2-.3.2-.5s-.1-.4-.2-.5l-.1-.1C60.3 1.2 58.5.4 56.6.4c-4 0-7.3 3.3-7.3 7.3s3.3 7.3 7.3 7.3c2.1 0 4-.9 5.4-2.4l-1-1c-1.1 1.2-2.6 1.9-4.3 1.9-3.2 0-5.9-2.6-5.9-5.9 0-3.1 2.6-5.7 5.9-5.7M44.3.5c-.3 0-.5.2-.6.4s-4.3 10.6-4.3 10.6S35.2 1.1 35.1.9c-.1-.2-.3-.4-.6-.4s-.6.2-.6.5c-.1.3-2.4 13.3-2.4 13.3v.1c0 .4.3.6.6.6s.6-.2.6-.5l2-11.2s3.9 9.8 4 10 .3.4.6.4.5-.2.6-.4 4-10 4-10L46 14.9h1.3S44.8 1.2 44.7.9c.2-.2-.1-.4-.4-.4M68.1 8.1l2.2-5.3 2.2 5.3h-4.4zM70.2.4c-.3 0-.5.2-.6.4-.1.3-5.4 13.4-5.4 13.4v.3c0 .4.3.6.6.6s.5-.2.6-.4l2-5.1h5.5l2.2 5.4h1.4S70.9 1.1 70.8.9c-.1-.3-.3-.5-.6-.5zM98.6 1.3c0-.4-.3-.8-.7-.8h-8.1c-.4 0-.8.4-.8.8s.3.7.7.7h3.4v13h1.3V2h3.5c.4 0 .7-.3.7-.7" />
          </g>
          <g fill="currentColor">
            <path d="M43.1 20.1c-.7 0-1.2.5-1.2 1.1v13.4h2.3V21.3c.1-.6-.4-1.2-1.1-1.2M16.8 28.9v-7.7c0-.6-.5-1.1-1.2-1.1s-1.2.5-1.2 1.1v7.6c0 3.3 2.3 5.9 5.9 5.9s5.9-2.6 5.9-5.9v-8.7h-2.3v8.7c0 1.9-1.3 3.6-3.6 3.6-2.3.2-3.5-1.5-3.5-3.5M73 22.5c.8 0 1.2-.5 1.2-1.1 0-.7-.5-1.1-1.2-1.1h-7.1c-.7 0-1.1.5-1.1 1.1v12.2c0 .6.5 1.1 1.1 1.1h8.3v-2.3h-7.1v-4.2h5.3c.8 0 1.3-.5 1.3-1.1 0-.7-.4-1.1-1.3-1.1h-5.3v-3.4l5.9-.1zM6.7 32.5H3v-4.3h3.6c1.7 0 2.5 1 2.5 2.2 0 1.1-.8 2.1-2.4 2.1zM3 22.5h3.5c1.3 0 1.9.8 1.9 1.8s-.7 1.8-1.9 1.8H3v-3.6zm6.4 4.4c.4-.2 1.5-1.1 1.5-3 0-2.2-1.6-3.7-4-3.7h-5c-.7 0-1.1.5-1.1 1.1v13.3h6.4c2.6 0 4.4-1.9 4.4-4.3-.1-2.5-1.8-3.2-2.2-3.4zM50 20.1c-.7 0-1.2.5-1.2 1.1v12.4c0 .6.5 1.1 1.2 1.1.6 0 1.2-.5 1.2-1.1v-8.9s5.7 7.3 7.2 9.2c.4.5.6.9 1.3.9s1.1-.5 1.1-1.1V20.2h-2.3v10s-6.3-8.1-7.2-9.2c-.4-.5-.8-.9-1.3-.9M29 32.6c.3.4 1.7 2.2 4.7 2.2 2.9 0 4.9-1.7 4.9-4.1 0-1.6-.6-2.8-2.9-3.9-.6-.3-2.3-1.1-2.3-1.1-.9-.4-1.3-1-1.3-1.7 0-.8.6-1.6 2.1-1.6.9 0 1.5.3 1.9.4.2.1.6.3 1 .3.5 0 1.1-.4 1.1-1.1 0-.6-.4-1-.8-1.1-.6-.3-1.6-.7-3.2-.7-2.6 0-4.5 1.6-4.5 3.9 0 2.1 1.4 3.1 3.1 3.9.3.2 1.6.8 1.9.9 1.1.5 1.6 1 1.6 1.9 0 1-.9 1.8-2.3 1.8-1.9 0-3-1.3-3.2-1.5L29 32.6zM76.5 32.6c.3.4 1.7 2.2 4.7 2.2 2.9 0 4.9-1.7 4.9-4.1 0-1.6-.6-2.8-2.9-3.9-.6-.3-2.3-1.1-2.3-1.1-.9-.4-1.3-1-1.3-1.7 0-.8.6-1.6 2.1-1.6.9 0 1.5.3 1.9.4.2.1.6.3 1 .3.5 0 1.1-.4 1.1-1.1 0-.6-.4-1-.8-1.1-.6-.3-1.6-.7-3.2-.7-2.6 0-4.5 1.6-4.5 3.9 0 2.1 1.4 3.1 3.1 3.9.3.2 1.6.8 1.9.9 1 .5 1.6 1 1.6 1.9 0 1-.9 1.8-2.3 1.8-2 0-3-1.3-3.2-1.5l-1.8 1.5zM88.3 32.6c.3.4 1.6 2.2 4.6 2.2 2.9 0 4.9-1.7 4.9-4.1 0-1.6-.6-2.8-2.9-3.9-.6-.3-2.4-1.1-2.4-1.1-.9-.4-1.3-1-1.3-1.7 0-.8.6-1.6 2.1-1.6.9 0 1.5.3 1.9.4.2.1.6.3 1 .3.5 0 1.1-.4 1.1-1.1 0-.6-.4-1-.8-1.1-.6-.3-1.6-.7-3.2-.7-2.6 0-4.5 1.6-4.5 3.9 0 2.1 1.4 3.1 3.1 3.9.3.2 1.5.8 1.9.9 1.1.5 1.6 1 1.6 1.9 0 1-.9 1.8-2.3 1.8-1.9 0-3-1.3-3.2-1.5l-1.6 1.5z" />
          </g>
        </svg>
      </div>
    </PrismBanner>
  );
}

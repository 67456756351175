import React, { useContext, useEffect } from 'react';
import NavigationBar from '../../components/navigation-bar';
import { PrismButton, PrismIcon, PrismPageHeading, PrismSectionHeading, PrismText } from '@prism-ui/react';
import Container from '../../components/container';
import ContactInformation from '../../components/tables/contactInformation';
import Package from '../../components/tables/package';
import TableCentered from '../../components/listTable/tableCentered';
import { useGetCustomerAndContentData } from '../../hooks/dml/documents.hooks';
import { pageOrderDetails } from '../../utility/global-constants/pages';
import { Redirect } from 'react-router-dom';
import derivedData from './derivedData';
import './style.css';
import { pageAllocation } from '../../hooks/dml/documents.hooks';
import StepperNavigation from '../../containers/stepper/stepperNavigation';
import NextStepBar from '../../containers/stepper/nextStepBar';
import { getConfig } from '../../utility/env';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import { pageViewUpdate, spaTransition } from '../../utility/updateDataLayer';
import TrackingData from '../../components/experienceTracking/TrackingData';
import { SessionContext } from '../../App';

export default function OrderDetails() {
  var { isLoading, isError, initializeData, contentData } = useGetCustomerAndContentData();
  const data = derivedData({ contentData, initializeData });
  const { oppid, sourcesystem } = useContext(SessionContext);
  var pageData: any;
  var history = useHistory();

  const page = {
    pageInfo: {
      screenName: pageOrderDetails.screenName
    }
  };

  useEffect(() => {
    document.title = 'Review Order Details';
    pageViewUpdate();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return history?.listen(() => {
      spaTransition();
    });
  }, [history]);

  if (data) {
    pageData = pageAllocation(initializeData, contentData);
  }

  const env = getConfig();
  if (isError) return <Redirect to="/error" />;

  return (
    <div>
      <NavigationBar page={pageOrderDetails.name} />
      <StepperNavigation
        pageData={pageData}
        history={history}
        isOrderDetails={true}
        oppid={oppid}
        sourcesystem={sourcesystem}
        backButtonText={contentData?.common?.actions?.backAction}
      />
      <Container>
        {(!data || isLoading) && <LoadingIndicator />}
        {data && (
          <div>
            <div>
              <PrismSectionHeading heading={data.pageSectionHeading} />
              <PrismPageHeading heading={data.pageHeading}>
                <div slot="action" className="order-details-action">
                  <PrismButton display="link" onClick={() => window.print()}>
                    <PrismIcon slot="icon" path={`${env.content.baseUrl}/images/icons/print.svg`} />
                    Print
                  </PrismButton>
                </div>
              </PrismPageHeading>
              <ContactInformation heading={data.contactInfoHeading} rows={data.rows} />
            </div>
            <Package service={data.packageInfo} />

            <TableCentered>
              <div className="order-details-pricing">
                <PrismText display="bodyM"> {data.legal.pricingDescription} </PrismText>
              </div>
            </TableCentered>
            <div className="order-details-contact-us">
              <PrismText display="bodyM">
                <div
                  className="order-details-contact-us-link"
                  dangerouslySetInnerHTML={{ __html: data.legal.contactUs }}
                ></div>
              </PrismText>
            </div>
            <NextStepBar pageData={pageData} history={history} completeOrder={false} content={data}></NextStepBar>
          </div>
        )}
      </Container>
      <TrackingData type="page" trackingInfo={page} />
    </div>
  );
}

import { PrismText, PrismButton, PrismTooltip, PrismGrid, PrismSectionHeading, PrismIcon } from '@prism-ui/react';
import { useRef, useState } from 'react';
import './style.css';

interface ISetupTextProps {
  header: string;
  textOne: string;
  textTwo: string;
  linkText: string;
  linkHref: string;
  tooltipLabel: string;
  isSelfReg: boolean;
  regTypeToggle: Function;
  modalHeader: string;
  modalBody: string;
}

export default function SetupText({
  header,
  textOne,
  textTwo,
  linkText,
  linkHref,
  tooltipLabel,
  isSelfReg,
  regTypeToggle,
  modalHeader,
  modalBody,
}: ISetupTextProps) {
  const [showModal, setShowModal] = useState(false);
  const modalOverlay = useRef<any>(null);
  // eslint-disable-next-line no-restricted-globals
  const screenWidth = screen.width;

  const isMobileSize = () => {
    if (screenWidth < 1024) {
      return true;
    }

    return false;
  };

  const handleClick = () => {
    regTypeToggle(!isSelfReg);
  };

  return (
    <div className="setup-container">
      <PrismGrid span="2" fluid={true} flush={true}>
        <PrismGrid type="col">
          <PrismSectionHeading heading={header} />
          {isSelfReg ? (
            <div className="setup-text">
              <PrismText display={'bodyL'}>
                {`${textOne} `}
                <button className="setup-link" onClick={handleClick}>
                  {linkText}
                </button>
                {` ${textTwo}`}
                <PrismTooltip
                  label={isMobileSize() ? '' : tooltipLabel}
                  direction="right"
                  buttonAttributes={{ name: 'test', onclick: () => setShowModal(true) }}
                />
              </PrismText>
            </div>
          ) : (
            <div className="setup-text">
              <PrismText display={'bodyL'}>{textOne}</PrismText>
              <div className="invite-reg-commercial-link-container">
                <PrismButton className="setup-link-invite" display="link" href={linkHref} mode="commercial" onClick={handleClick}>
                  <PrismIcon slot="icon" name="arrow_left" size="md" theme="outline" />
                  <PrismText display={'bodyL'}>{linkText}</PrismText>
                </PrismButton>
              </div>
              <PrismText display={'bodyL'}>
                {textTwo}
                <PrismTooltip
                  label={isMobileSize() ? '' : tooltipLabel}
                  direction="right"
                  buttonAttributes={{ name: 'test', onclick: () => setShowModal(true) }}
                />
              </PrismText>
            </div>
          )}
        </PrismGrid>
      </PrismGrid>
      {showModal ? (
        <div className="modal">
          <div className="modal-overlay" ref={modalOverlay} onClick={
            (e: any) => {
              if (modalOverlay && modalOverlay.current?.contains(e.target)) {
                setShowModal(false)
              }
          }}/>
          <div className="modal-content">
            <div className="close" onClick={() => setShowModal(false)}>
              x
            </div>
            <div className="modal-header">{modalHeader}</div>
            <p className="modal-body">{modalBody}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

import Content from '../../interfaces/api/content';
import Initialize from '../../interfaces/api/initialize';
import { termsAndConditionsObject } from '../TermsAndConditions/helper';

export default function derivedData<T extends Content, U extends Initialize>({
  contentData,
  initializeData,
}: {
  contentData: T;
  initializeData: U;
}) {
  try {
    const {
      contact: { firstName, lastName },
      attachments: { documents, contracts },
      offer: { promoCodeText },
      specialInstructions,
    } = initializeData;

    const { contact } = initializeData;

    const {
      pages: {
        agreements: {
          documents: { pageDescription, termsAndConditions, uploadDocuments },
        },
      },
    } = contentData;

    const {
      title: uploadDocumentsTitle,
      description: uploadDocumentsDescription,
      fileGenericError,
      fileRequired,
    } = uploadDocuments;

    const uploadDocumentTypes = documents.map((document) => (document.status === 'Pending' && (document.type !== 'SIGN_NOW' &&  document.type !=='CUSTOMER_INITALS')? document.type : ''));
    
    const promoOfferDescription = specialInstructions ? specialInstructions : promoCodeText;

    const { nextButton } = contentData.pages.review.orderDetails;

    const orderCompleteButton = contentData?.pages?.agreements?.documents?.common?.orderCompleteButton;

    return {
      firstName,
      lastName,
      heading: pageDescription,
      termsAndConditions: termsAndConditionsObject(termsAndConditions, contracts, contact, promoOfferDescription),
      nextButton,
      orderCompleteButton,
      uploadDocuments: {
        title: uploadDocumentsTitle,
        description: uploadDocumentsDescription,
        fileGenericError,
        fileRequired,
        sections: Object.keys(uploadDocuments)
          .filter((key) => uploadDocuments[key].type && uploadDocumentTypes.includes(uploadDocuments[key].type))
          .map((key) => uploadDocuments[key]),
        document: documents,
      },
    };
  } catch (err) {
    return null;
  }
}

import { pageConfirmation, pageError } from '../../utility/global-constants/pages';
import { getUpdateOrder } from '../../api/index';

export async function onSubmit(
  isSubmission: boolean,
  sessionId: string,
  cimaToken: string,
  pageHistory: string,
  pageData: any
) {
  var history = pageHistory;
  if (isSubmission) {
    try {
      const response = await getUpdateOrder(cimaToken, sessionId);
      if (response?.data && response?.status === 'Success') {
        history = pageConfirmation.route;
      } else {
        history = pageError.route;
      }
    } catch (e) {
      history = pageError.route;
    }
  } else {
    var isRedirected: any;
    pageData.finalRoutes.forEach(function (route: any, index: any) {
      if (window.location.href.indexOf(route) > -1 && !isRedirected) {
        history = pageData.finalRoutes[index + 1];
        isRedirected = true;
      }
    });
  }
  return history;
}

export const checkBoxEventListener = () => {
  document.querySelector('form')?.addEventListener('invalid', (e) => e.preventDefault(), true);
};

export const checkInvalidMessage = () => {
  document
    .querySelector('[class^=invalid]')
    ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
};

export const checkSignPadInvalidMessage = () => {
  if (document.querySelector('[class^=invalid-sign-accept-button-error]')) {
    return true;
  } else if (document.querySelector('[class^=sign-accept-button]')) {
    document
      .querySelector('[class^=sign-accept-button]')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    return true;
  } else {
    return false;
  }
};

export const checkAcceptButton = () => {
  let first = false;
  let second = false;
  if (document.querySelector('[class^=sign-accept-button]')) {
    if (document.querySelector('[class*=initials-acc-button]')) {
      first = true;
    }
    if (document.querySelector('[class*=sign-now-acc-button]')) {
      second = true;
    }
  }
  return { initial: first, sign: second };
};

export const scrollToErrorField = (field: string) => {
  document.getElementsByName(field)[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
};

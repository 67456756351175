import { formatAddress } from '../../utility/format';
import Content from '../../interfaces/api/content';
import Initialize from '../../interfaces/api/initialize';
import getCarrierNumberRows from './phoneNumberCarrierInfo';
import { configurationTypes } from '../../utility/global-constants/configurationTypes';
import { termsAndConditionsObject } from '../TermsAndConditions/helper';

export default function derivedData<T extends Content, U extends Initialize>({
  contentData,
  initializeData,
}: {
  contentData: T;
  initializeData: U;
}) {
  try {
    const {
      contact: { billingAddress, firstName, lastName, businessName: companyName },
      attachments: { contracts },
    } = initializeData;

    const encryptionLabel = contentData.common?.labels?.encryption;
    const phoneTransfer = contentData.pages?.agreements?.phoneTransfer;
    const { pageDescription, phoneService, termsAndConditions, common } = phoneTransfer;

    const { orderCompleteButton } = contentData.pages?.agreements?.documents?.common;

    const carrierNumberPair = getCarrierNumberRows(
      configurationTypes.voicePhoneNumberCarrier,
      configurationTypes.voicePhoneNumberPorted,
      initializeData
    );
    const carrierTollFreeNumberPair = getCarrierNumberRows(
      configurationTypes.voiceTollFreePortedCarrier,
      configurationTypes.voiceTollFreePortedNumber,
      initializeData
    );

    const {
      billingAddress: billingAddressKey,
      companyName: companyNameKey,
      contactName: contactNameKey,
      title: orderInfoHeading,
    } = phoneService.orderDetails;

    return {
      pageSectionHeading: pageDescription,
      pageHeading: phoneService.title,

      orderInfoHeading,
      orderInfoRows: [
        { left: companyNameKey, right: companyName },
        { left: contactNameKey, right: `${firstName} ${lastName}` },
        { left: billingAddressKey, right: formatAddress(billingAddress) },
      ],

      transferNumberHeading: phoneService?.transferNumber,
      transferNumberRows: carrierNumberPair,

      transferTollFreeNumberHeading: phoneService?.transferTollFreeNumber,
      transferTollFreeRows: carrierTollFreeNumberPair,

      termsAndConditions: termsAndConditionsObject(termsAndConditions, contracts, initializeData.contact),

      encryptionLabel,
      nextButton: common.nextScreenNavigation,
      orderCompleteButton,
    };
  } catch (err) {
    return null;
  }
}

import { sharedConfig } from '../config/shared/config';

const additionalUrls = {
    s3: {
        baseUrl: 'https://s3.us-west-2.amazonaws.com/',
        uri: 'digital-ecom-frictionless'
    }
}

export const getBaseUrlAndUri = (url) => {
    for (const route in sharedConfig.frictionLess.routes) {
        if (url.includes(sharedConfig.frictionLess.routes[route])) {
            const uri = sharedConfig.frictionLess.routes[route];
            const baseUrl = url.slice(0, -uri.length);

            return {
                baseUrl,
                uri
            }
        }
    }

    if (url.includes(additionalUrls.s3.uri)) {
        return {
            baseUrl: additionalUrls.s3.baseUrl,
            uri: additionalUrls.s3.uri
        };
    }

    return {
        baseUrl: url,
        uri: ''
    };
}

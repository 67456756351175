import { PrismText } from '@prism-ui/react';
import './style.css';
import { Heading } from '../../../interfaces';

function TableHeader({ heading }: Heading) {
  return (
    <div className="table-header">
      <PrismText display="functional1">{heading}</PrismText>
    </div>
  );
}

export default TableHeader;

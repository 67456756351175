import { PrismButton, PrismText, PrismIcon } from '@prism-ui/react';
import PrismCheckbox from '../checkbox';
import './style.css';
import { getConfig } from '../../../utility/env';

export default function PrismLegalAgreement(props: any) {
  const env = getConfig();

  const displayActions = props.downloadPath || props.enablePrint;

  const onlegalCheckboxChecked = (event: any) => {
    props.checkboxStatus(event.target.checked);
  };

  const textArea = (
    <div id={`legal-text-area-content-${props.heading}`} className="text-area-main">
      <PrismText display="bodyS" tag="p">
        <div dangerouslySetInnerHTML={{ __html: props.description ? props.description : '' }} />
      </PrismText>
    </div>
  );

  const printTextArea = () => {
    let printContent = document.getElementById(`legal-text-area-content-${props.heading}`)?.innerHTML;
    let printWindow = window.open();
    printWindow?.document.open();
    printWindow?.document.write(
      `<html><head><title>${props.heading}</title></head><body><div style='font-size: 14px;font-family: Lato, Arial, "Helvetica Neue", Helvetica, sans-serif; font-weight: normal; letter-spacing: 1px; line-height: 20px; margin: 4rem'>${printContent}</div></body></html>`
    );
    printWindow?.document.close();
    printWindow?.print();
  };

  const downloadDocument = () => {
    fetch(props.downloadPath).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = props.heading;
        a.click();
      })
    })
  }

  return (
    <div>
      <div className="legal-agreement-header">
        <div className="title">
          <PrismText display="heading6" tag="p">
            {props.heading}
          </PrismText>
        </div>
        {displayActions && (
          <div className="download-print">
            {props.downloadPath && (
              <PrismButton display="link" onClick={downloadDocument}>
                <PrismIcon slot="icon" name="download" path={`${env.content.baseUrl}/images/icons/download.svg`} />
                Download
              </PrismButton>
            )}
            {props.enablePrint && (
              <div className="legal-print-icon">
                <PrismButton display="link" onClick={printTextArea}>
                  <PrismIcon name="print" path={`${env.content.baseUrl}/images/icons/print.svg`} slot="icon" /> Print
                </PrismButton>
              </div>
            )}
          </div>
        )}
      </div>
      {textArea}
      {!props.hideCheckBox && (
        <div className="legal-checkbox-main">
          <PrismCheckbox
            invalidMessage={props.inlineValidationMessage ? props.inlineValidationMessage : ''}
            className="legal-checkbox"
            label={props.label ? props.label : ''}
            required={props.required}
            disabled={props.disabled}
            onPrismValueChange={onlegalCheckboxChecked}
          ></PrismCheckbox>
        </div>
      )}
    </div>
  );
}

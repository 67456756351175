import { useEffect, useState, useContext, useCallback } from 'react';
import { PrismInput, PrismSelect, PrismText } from '@prism-ui/react';
import PrismCheckbox from '../../prism/checkbox';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './style.css';
import PasswordEssentials from '../PasswordEssentials/index';
import { getCreateUser, getUpdateOrder } from '../../../api/index';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { pageConfirmation, pageError } from '../../../utility/global-constants/pages';
import LoadingIndicator from '../../molecules/atoms/accents/loadingIndicator';
import ClientValidationContext from '../../../context/clientValidation';
import UserAgentContext from '../../../context/userAgent';
import AccountRegistrationContext from '../../../context/AccountRegistration';
import { registrationTypeValue, registrationStatusValue } from '../../../utility/global-constants/registrationStates';
import { formStartEvent } from '../../../utility/updateDataLayer';
import ProcessServerSideError from './ProcessServerSideErrors';
import * as yup from 'yup';
import { scrollToErrorField } from '../../../utility/submit';

export interface IFormSelfRegInput {
  password: string;
  confirmPassword: string;
  secretQuestion: string;
  secretAnswer: string;
  termsOfService: boolean;
}

interface ISelfRegistrationProps {
  nameLabel: string;
  firstName: string;
  lastName: string;
  emailLabel: string;
  emailValue: string;
  createPassword: string;
  confirmPassword: string;
  securityQuestion: string;
  securityQuestions: string[];
  securityAnswer: string;
  PeText: {
    passwordEssentials: string;
    lengthValidation: string;
    uppercase: string;
    lowercase: string;
    number: string;
    specialCharacter: string;
    noFirstOrLast: string;
    notEasy: string;
    mustMatch: string;
  };
  errorTreatment: any;
  showLink: string;
  requiredField: string;
  termsOfServiceLink: {
    text: string;
    href: string;
  };
  termsOfServiceText: string;
  sessionId: string;
  cimaToken: string;
  getNextStepBar: Function;
}

export default function SelfRegistration({
  nameLabel,
  firstName,
  lastName,
  emailLabel,
  emailValue,
  createPassword,
  confirmPassword,
  securityQuestion,
  securityQuestions,
  securityAnswer,
  PeText,
  errorTreatment,
  showLink,
  requiredField,
  termsOfServiceText,
  termsOfServiceLink,
  sessionId,
  cimaToken,
  getNextStepBar,
}: ISelfRegistrationProps) {
  const selectOptions = [
    { value: '', display: '--Select--', selected: true },
    {
      value: 'opt1',
      display: securityQuestions && securityQuestions[0] ? securityQuestions[0] : 'option 1',
      selected: false,
    },
    {
      value: 'opt2',
      display: securityQuestions && securityQuestions[1] ? securityQuestions[1] : 'option 2',
      selected: false,
    },
    {
      value: 'opt3',
      display: securityQuestions && securityQuestions[2] ? securityQuestions[2] : 'option 3',
      selected: false,
    },
    {
      value: 'opt4',
      display: securityQuestions && securityQuestions[3] ? securityQuestions[3] : 'option 4',
      selected: false,
    },
    {
      value: 'opt5',
      display: securityQuestions && securityQuestions[4] ? securityQuestions[4] : 'option 5',
      selected: false,
    },
    {
      value: 'opt6',
      display: securityQuestions && securityQuestions[5] ? securityQuestions[5] : 'option 6',
      selected: false,
    },
    {
      value: 'opt7',
      display: securityQuestions && securityQuestions[6] ? securityQuestions[6] : 'option 7',
      selected: false,
    },
    {
      value: 'opt8',
      display: securityQuestions && securityQuestions[7] ? securityQuestions[7] : 'option 8',
      selected: false,
    },
    {
      value: 'opt9',
      display: securityQuestions && securityQuestions[8] ? securityQuestions[8] : 'option 9',
      selected: false,
    },
    {
      value: 'opt10',
      display: securityQuestions && securityQuestions[9] ? securityQuestions[9] : 'option 10',
      selected: false,
    },
  ];
  const isClientValidationDisabled = useContext(ClientValidationContext);
  const fullFormSchema = yup.object().shape({
    password: yup
      .string()
      .required(errorTreatment?.password?.required)
      .min(8, errorTreatment?.password?.minLength)
      .max(16, errorTreatment?.password?.maxLength),
    confirmPassword: yup
      .string()
      .required(errorTreatment?.confirmPassword?.required)
      .oneOf([yup.ref('password'), null], errorTreatment?.confirmPassword?.mustMatch),
    secretQuestion: yup.string().required(errorTreatment?.securityQuestion?.required),
    secretAnswer: yup.string().required(errorTreatment?.securityQuestionAnswer?.required),
    termsOfService: yup
      .boolean()
      .oneOf([true], errorTreatment?.termsOfService?.required)
      .required(errorTreatment?.termsOfService?.required),
  });
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormSelfRegInput>({
    resolver: isClientValidationDisabled ? undefined : yupResolver(fullFormSchema),
  });

  const history = useHistory();
  const { updateRegistrationState } = useContext(AccountRegistrationContext);
  const isUserAgent = useContext(UserAgentContext);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateCompleted, setIsUpdateCompleted] = useState(false);

  const userData = {
    password: watch('password'),
    confirmPassword: watch('confirmPassword'),
    secretQuestion: watch('secretQuestion'),
    secretAnswer: watch('secretAnswer'),
    scmsSessionId: sessionId,
  };

  const createUserMutation = useMutation(
    () => {
      return getCreateUser(cimaToken, userData);
    },
    {
      onError: (error: any) => {
        return error.response;
      },
    }
  );

  const updateOrderMutation = useMutation(() => {
    return getUpdateOrder(cimaToken, sessionId);
  });

  // Password Essentials criteria states
  const [peMinMax, setPeMinMax] = useState('NEUTRAL');
  const [peOneUppercase, setPeOneUppercase] = useState('NEUTRAL');
  const [peOneLowercase, setPeOneLowercase] = useState('NEUTRAL');
  const [peOneDigit, setPeOneDigit] = useState('NEUTRAL');
  const [peOneSpecialChar, setPeOneSpecialChar] = useState('NEUTRAL');
  const [peNoFirstLastName, setPeNoFirstLastName] = useState('NEUTRAL');
  const [peMustMatch, setPeMustMatch] = useState('NEUTRAL');
  const [wasSubmitted, setWasSubmitted] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const PeFunctions = {
    setPeMinMax,
    setPeOneUppercase,
    setPeOneLowercase,
    setPeOneDigit,
    setPeOneSpecialChar,
    setPeNoFirstLastName,
    setPeMustMatch,
  };
  let PeStates = {
    peMinMax,
    peOneUppercase,
    peOneLowercase,
    peOneDigit,
    peOneSpecialChar,
    peNoFirstLastName,
    peMustMatch,
  };

  let watchPassword = watch('password');
  let watchConfirmPassword = watch('confirmPassword');

  const checkCriterias = useCallback(() => {
    if (
      peMinMax.includes('INVALID') ||
      peOneUppercase.includes('INVALID') ||
      peOneLowercase.includes('INVALID') ||
      peOneDigit.includes('INVALID') ||
      peOneSpecialChar.includes('INVALID') ||
      peNoFirstLastName.includes('INVALID') ||
      peMustMatch.includes('INVALID')
    ) {
      return true;
    }

    return false;
  }, [peMinMax, peMustMatch, peNoFirstLastName, peOneDigit, peOneLowercase, peOneSpecialChar, peOneUppercase]);

  useEffect(() => {
    if (
      watchPassword &&
      !(
        watchPassword.toUpperCase().match(firstName.toUpperCase()) &&
        watchPassword.toUpperCase().match(lastName.toUpperCase())
      )
    ) {
      setPeNoFirstLastName('VALID');
    }
    if (
      watchPassword &&
      (watchPassword.toUpperCase().match(firstName.toUpperCase()) ||
        watchPassword.toUpperCase().match(lastName.toUpperCase()))
    ) {
      setPeNoFirstLastName('INVALID');
    }
    if (watchPassword && watchConfirmPassword && watchPassword === watchConfirmPassword) {
      setPeMustMatch('VALID');
    }
    if (watchPassword && watchConfirmPassword && watchPassword !== watchConfirmPassword) {
      setPeMustMatch('INVALID');
    }
    clearErrors();
  }, [watchPassword, watchConfirmPassword, firstName, lastName, clearErrors]);

  useEffect(() => {
    if (updateOrderMutation.data?.status === 'Success') {
      setIsLoading(false);
      history.push(pageConfirmation.route);
    } else if (updateOrderMutation.data?.response?.data?.status === 'Failure') {
      setIsLoading(false);
      history.push(pageError.route);
    }
  }, [updateOrderMutation, history]);

  useEffect(() => {
    if (createUserMutation.isSuccess) {
      updateRegistrationState(registrationTypeValue.selfregistration, registrationStatusValue.success);
      if (!isUpdateCompleted) {
        updateOrderMutation.mutateAsync();
        setIsUpdateCompleted(true);
      }
    } else if (createUserMutation.isError && wasSubmitted) {
      const setPasswordReqError = () => {
        setError('password', {
          type: 'required',
          message: errorTreatment.password.serverSideReq,
        });
      };
      const setEasyToGuessError = () => {
        setError('password', {
          type: 'required',
          message: errorTreatment.password.easilyGuessed,
        });
      };
      const setSecurityQuestionError = () => {
        setError('secretAnswer', {
          type: 'required',
          message: errorTreatment.securityQuestionAnswer.serverSideReq,
        });
      };

      const { isError, funcsToRun } = ProcessServerSideError(createUserMutation.error.response.data.messages, {
        setEasyToGuessError,
        setSecurityQuestionError,
        ...PeFunctions,
      });

      setTimeout(() => {
        scrollToErrorField('password');

        setWasSubmitted(false);
        if (isError) {
          setPasswordReqError();
          for (const func in funcsToRun) {
            funcsToRun[func]();
          }
          setIsLoading(false);
        } else {
          updateRegistrationState(registrationTypeValue.selfregistration, registrationStatusValue.failed);
        }
      }, 0);
    }
  }, [
    createUserMutation,
    updateOrderMutation,
    history,
    setError,
    setWasSubmitted,
    wasSubmitted,
    errorTreatment,
    updateRegistrationState,
    PeFunctions,
    isUpdateCompleted,
    setIsUpdateCompleted,
  ]);

  useEffect(() => {
    if (checkCriterias()) {
      scrollToErrorField('password');
    }
    if (errors.password) {
      scrollToErrorField('password');
    } else if (errors.confirmPassword) {
      scrollToErrorField('confirmPassword');
    } else if (errors.secretQuestion) {
      scrollToErrorField('secretQuestion');
    } else if (errors.secretAnswer) {
      scrollToErrorField('secretQuestion');
    } else if (errors.termsOfService) {
      scrollToErrorField('termsOfService');
    }
  }, [checkCriterias, errors, setError, errorTreatment.password.serverSideReq]);

  const onSubmit: SubmitHandler<IFormSelfRegInput> = async (value: any) => {
    if (!checkCriterias() || isClientValidationDisabled) {
      setTimeout(() => {
        setWasSubmitted(true);
        setIsLoading(true);
      }, 0);
      createUserMutation.mutateAsync();
    } else {
      scrollToErrorField('password');
      setError('password', {
        type: 'required',
        message: errorTreatment.password.serverSideReq,
      });
    }
  };

  return (
    <form data-tracking='{"container": ["self-registration"]}' onSubmit={handleSubmit(onSubmit)}>
      <div className="self-reg-container">
        <div className="self-reg-details">
          <div className="self-reg-left">
            <PrismText tag="p" display="label" className="self-reg-text">
              {nameLabel}
            </PrismText>
            <PrismText tag="p" display="bodyM" className="self-reg-text">{`${firstName} ${lastName}`}</PrismText>
          </div>
          <div className="self-reg-right">
            <PrismText tag="p" display="label" className="self-reg-text">
              {emailLabel}
            </PrismText>
            <PrismText tag="p" display="bodyM" className="self-reg-text">
              {emailValue}
            </PrismText>
          </div>
        </div>
        <div className="self-reg-form">
          <div className="self-reg-field">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={createPassword}
                  name="password"
                  type="password"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'self');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.password?.message ? true : false}
                  invalidMessage={errors.password?.message}
                  value={value}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={confirmPassword}
                  name="confirmPassword"
                  type="password"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'self');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.confirmPassword?.message ? true : false}
                  invalidMessage={errors.confirmPassword?.message}
                  value={value}
                />
              )}
            />
            <div className="hidden-PE visible-PE-mobile">
              <PasswordEssentials PeFunctions={PeFunctions} PeStates={PeStates} PeText={PeText} watch={watch} />
            </div>
            <Controller
              name="secretQuestion"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismSelect
                  className="text-field"
                  label={securityQuestion}
                  name="secretQuestion"
                  options={selectOptions}
                  value={value}
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'self');
                    }
                  }}
                  onPrismValueChange={(e) => {
                    onChange(e.detail);
                  }}
                  invalid={errors.secretQuestion?.message ? true : false}
                  invalidMessage={errors.secretQuestion?.message}
                />
              )}
            />
            <Controller
              name="secretAnswer"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrismInput
                  className="text-field"
                  label={securityAnswer}
                  name="secretAnswer"
                  type="text"
                  disabled={isUserAgent}
                  onPrismFocus={(e) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'self');
                    }
                  }}
                  onPrismInputChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  invalid={errors.secretAnswer?.message ? true : false}
                  invalidMessage={errors.secretAnswer?.message}
                  value={value}
                />
              )}
            />
            <Controller
              name="termsOfService"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <PrismCheckbox
                  className="self-reg-checkbox"
                  label={termsOfServiceText}
                  labelForModal={
                    <a href={termsOfServiceLink?.href} rel="noreferrer" target="_blank">
                      {termsOfServiceLink?.text}
                    </a>
                  }
                  name="termsOfService"
                  onPrismFocus={(e: any) => {
                    if (!isFocused) {
                      setIsFocused(true);
                      formStartEvent(e.currentTarget, 'self');
                    }
                  }}
                  onPrismValueChange={onChange}
                  invalid={errors.termsOfService?.message ? true : false}
                  invalidMessage={errorTreatment?.termsOfService?.required}
                  checked={value}
                  disabled={isUserAgent}
                  required={false}
                />
              )}
            />
            <div className="self-reg-required-text">
              <PrismText>{requiredField}</PrismText>
            </div>
          </div>
          <div className="hidden-PE-mobile visibile-PE">
            <PasswordEssentials PeFunctions={PeFunctions} PeStates={PeStates} PeText={PeText} watch={watch} />
          </div>
        </div>
      </div>
      {isLoading && <LoadingIndicator />}
      {getNextStepBar()}
    </form>
  );
}

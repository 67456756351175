import React from 'react';
import ReactDOM from 'react-dom';
//PRISM-UI: Includes: Normalize, Tailwind Base, Global Page CSS
import '@prism-ui/core/dist/prism-ui/prism-ui.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//PRISM-UI: Basic site data
import { site } from './data/dictionary.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
//PRISM-UI: Adding React Router
import { BrowserRouter as Router, Link } from 'react-router-dom';
//PRISM-UI: Adding Prism UI components
import { PrismLayout } from '@prism-ui/react';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <div theme={site.theme} mode="commercial">
          <PrismLayout flush="true">
            <Link slot="menu" className="menu-action" to="/">
              {' '}
              Home{' '}
            </Link>
            <Link slot="menu" className="menu-action" to="/about">
              {' '}
              About{' '}
            </Link>
            <Link slot="menu" className="menu-action" to="/contact">
              {' '}
              Contact{' '}
            </Link>

            <App />
          </PrismLayout>
        </div>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

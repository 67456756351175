import { useEffect } from 'react';
import { PrismText } from '@prism-ui/react';
import NavigationBar from '../../components/navigation-bar';
import { useContentApiData } from '../../hooks/dml/documents.hooks';
import { pageError } from '../../utility/global-constants/pages';
import queryStringHelpers from '../../utility/queryStrings';
import './style.css';
import { errorTypes, ERROR_TYPE_QUERY_STRING, ERROR_MESSAGE_FALLBACK } from '../../utility/global-constants/error';
import { useHistory } from 'react-router-dom';
import { pageViewUpdate, spaTransition } from '../../utility/updateDataLayer';
import TrackingData from '../../components/experienceTracking/TrackingData';

interface GetErrorMessageProps {
  error?: boolean;
  genericErrorMessage?: string;
  orderFailureErrorHeading?: string;
  orderFailureErrorMessage?: string;
}

export default function ErrorComponent() {
  let messages = {};

  const { data, error, isLoading } = useContentApiData();
  var history = useHistory();

  const page = {
    pageInfo: {
      screenName: pageError.screenName,
    },
  };

  useEffect(() => {
    // TODO: TBD
    document.title = "Error";
    pageViewUpdate();
  }, []);

  useEffect(() => {
    return history?.listen(() => {
      spaTransition();
    });
  }, [history]);

  if (error) {
    messages = { error: true };
  }

  if (data) {
    try {
      messages = data.common.messages;
    } catch (err) {
      messages = { error: true };
    }
  }

  const getErrorMessage = (messages: GetErrorMessageProps) => {
    if (!Object.keys(messages).length) return;

    const { genericErrorMessage, orderFailureErrorHeading, orderFailureErrorMessage, error } = messages;
    const type = queryStringHelpers.getQueryStringValue(ERROR_TYPE_QUERY_STRING);
    const errorType = type && !error ? type : null;

    switch (errorType) {
      case errorTypes.GENERIC:
        return (
          <>
            <h1 className="screen-reader"> Order Error </h1>
            <PrismText display="subhead1"> {genericErrorMessage} </PrismText>
          </>
        );
      case errorTypes.ORDER_FAILURE:
        return (
          <>
            <PrismText tag="h1" className="error-text-heading" display="heading5">
              {orderFailureErrorHeading}
            </PrismText>
            <PrismText display="bodyL">{orderFailureErrorMessage}</PrismText>
          </>
        );
      default:
        return (
          <>
            <h1 className="screen-reader"> Order Error </h1>
            <PrismText display="subhead1">{ERROR_MESSAGE_FALLBACK} </PrismText>
          </>
        );
    }
  };

  return (
    <div className="error-block">
      <NavigationBar page={pageError.name} />
      <div className="error-text-centered">
        <div className="error-text">{!isLoading ? getErrorMessage(messages) : <p> This is loading... </p>} </div>
      </div>
      <TrackingData type="page" trackingInfo={page} />
    </div>
  );
}

interface PageData {
  finalRoutes: string[];
}

export const isLastStep = ({ pageData }: { pageData: PageData }) => {
  return (
    pageData &&
    pageData.finalRoutes &&
    pageData.finalRoutes.length > 0 &&
    window.location.href.includes(pageData.finalRoutes[pageData.finalRoutes.length - 1])
  );
};

export const isFirstStep = ({ pageData }: { pageData: PageData }) => {
  return (
    pageData &&
    pageData.finalRoutes &&
    pageData.finalRoutes.length > 0 &&
    pageData.finalRoutes.length !== 1 &&
    window.location.href.includes(pageData.finalRoutes[0])
  );
};

import queryStringHelpers from './queryStrings';
import { getConfig } from './env';

const env = getConfig();

export const addDatalayerScripts = () => {
  if (queryStringHelpers.getQueryStringValue('disablescripts') !== 'true') {
    const script = document.createElement('script');
    script.src = env.dataLayer.src;
    document.body.appendChild(script);
  }
};

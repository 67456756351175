import Initialize from '../../interfaces/api/initialize';
import { formatPhone } from '../../utility/format';

export default function getCarrierNumberRows(
  carrierConfigType: string,
  numberConfigType: string,
  initializeData: Initialize
) {
  const carrierNumberRows: any[] = [];

  const portedCarrier = initializeData?.cartItems
    .map((cartItem) =>
      cartItem.configurationType === carrierConfigType
        ? { key: cartItem.groupSequence, value: cartItem.selectedValue }
        : null
    )
    .filter((value) => value != null);

  const portedNumber = initializeData?.cartItems
    .map((cartItem) =>
      cartItem.configurationType === numberConfigType
        ? { key: cartItem.groupSequence, value: cartItem.selectedValue }
        : null
    )
    .filter((value) => value != null);

  portedCarrier.forEach((carrier) => {
    portedNumber.forEach((number) => {
      if (carrier?.key === number?.key) {
        var formattedNumber =
          number?.value.length === 10
            ? formatPhone(number.value)
            : number?.value;
        carrierNumberRows.push({ left: formattedNumber, right: carrier?.value });
      }
    });
  });
  return carrierNumberRows;
}

import Table from '../../listTable/table';
import TableRow from '../../listTable/tableRow';
interface PropsObject {
  left: string;
  right?: string;
  border?: boolean;
}

interface Props {
  heading: string;
  rows: PropsObject[];
}

function ContactInformation({ heading, rows }: Props) {
  return (
    <Table heading={heading}>
      {rows.map((row, index) => (
        <TableRow key={`contact-information-${index}`} {...row} rule={true} />
      ))}
    </Table>
  );
}

export default ContactInformation;

import { formatAddress } from '../../utility/format';
import Content from '../../interfaces/api/content';
import Initialize, { InitializeTempVal, LineOfBusinessType } from '../../interfaces/api/initialize';
import { InitializePackageProps } from '../../interfaces/api/initialize';
import { addCharge, getLineOfBusiness, convertToDollar, sortCartItems } from './helper';
import { GIFT_CARDS } from '../../utility/global-constants/components';
import { INITIALIZE } from '../../utility/global-constants/api';

export default function derivedData<T extends Content, U extends Initialize>({
  contentData,
  initializeData,
}: {
  contentData: T;
  initializeData: U;
}) {
  try {
    const { contact, salesAgentInfo, cartItems, offer, totalMonthlyCharges, totalOneTimeCharges } = initializeData;
    const { name: offerName, price: offerTotal, contractDescription } = offer;
    const {
      billingAddress,
      serviceAddress,
      firstName,
      lastName,
      businessName: companyName,
      telephoneNumber: phoneNumber,
    } = contact;

    const { telephoneNumber: salesAgentTelephoneNumber, emailAddress: salesAgentEmailAddress } = salesAgentInfo;

    const encryptionLabel = contentData.common.labels.encryption;
    const review = contentData.pages.review;
    const { pageDescription, orderDetails } = review;

    const { customerInfo, title, nextButton, packageDetails } = orderDetails;

    const orderCompleteButton = contentData?.pages?.agreements?.documents?.common;

    const {
      billingAddress: billingAddressKey,
      companyName: companyNameKey,
      contactName: contactNameKey,
      phoneNumber: phoneNumberKey,
      serviceAddress: serviceAddressKey,
      header: contactInfoHeading,
    } = customerInfo;
    const {
      contactUs,
      header,
      internet,
      monthlyCharges: monthlyChargesHeader,
      oneTimeChargesDescription,
      oneTimeChargesHeader,
      oneTimeTotalCharges,
      pricingDescription,
      specialOffers: specialOffersHeader,
      television,
      totalMonthlyCharges: totalMonthlyChargesHeader,
      voice,
    } = packageDetails;

    const tempVal: InitializeTempVal = {
      monthlyCharges: {
        internet: [],
        voice: [],
        tv: [],
      },
      oneTimeCharges: {
        internet: [],
        voice: [],
        tv: [],
      },
      specialOffers: [],
      professionalInstall: null,
    };

    cartItems.forEach((item) => {
      const {
        hideInCart,
        configurationType,
        oneTimeCharges,
        monthlyCharges,
        configAttributes,
        groupSequence,
        isChildItem,
        giftCardType: giftCardTypeUnformatted,
        name,
        lineOfBusiness: lineOfBusinessUnformatted,
        standardName,
      } = item;

      const lineOfBusiness = getLineOfBusiness(lineOfBusinessUnformatted);

      if (!lineOfBusiness) return;

      const noCharges = !oneTimeCharges && !monthlyCharges;

      if (hideInCart) return;

      if (configurationType === INITIALIZE.GIFT_CARD) {
        const giftCardType = giftCardTypeUnformatted.toLowerCase();
        const provider = GIFT_CARDS.includes(giftCardType) ? giftCardType : 'visa';

        return tempVal.specialOffers.push({
          left: name,
          provider: provider,
          card: `${provider}-black`,
        });
      }

      let updatedName = name;

      if (!isChildItem) {
        if (configurationType === 'VoiceLine') updatedName = 'Line ' + groupSequence + ' : ' + name;

        if (oneTimeCharges)
          if (configurationType === 'InstallationProfessional') {
            tempVal.professionalInstall = {
              left: updatedName,
              right: tempVal?.professionalInstall?.right
                ? tempVal.professionalInstall.right + oneTimeCharges
                : oneTimeCharges,
            };
          } else {
            tempVal.oneTimeCharges[lineOfBusiness].push(
              addCharge({
                name: updatedName,
                charge: oneTimeCharges,
                configAttributes,
                groupSequence,
                configurationType,
                lineOfBusinessUnformatted,
                rows: [],
                standardName,
              })
            );
          }

        if ((monthlyCharges || noCharges) && configurationType !== 'InstallationProfessional')
          tempVal.monthlyCharges[lineOfBusiness].push(
            addCharge({
              name: updatedName,
              charge: monthlyCharges,
              configAttributes,
              groupSequence,
              configurationType,
              lineOfBusinessUnformatted,
              rows: [],
              standardName,
            })
          );
      }
    });

    const lob: LineOfBusinessType[] = ['internet', 'voice', 'tv'];
    // Adding Child Items
    lob.forEach((item) => {
      tempVal.monthlyCharges[item].forEach((element) => {
        const { left, configurationType, groupSequence, lineOfBusinessUnformatted, rows, standardName } = element;
        if (configurationType === 'VoiceLine') {
          cartItems.forEach((val) => {
            const {
              hideInCart,
              monthlyCharges,
              oneTimeCharges,
              groupSequence: groupNo,
              isChildItem,
              name,
              lineOfBusiness: childLineOfBusiness,
            } = val;

            if (!childLineOfBusiness) return;

            const noCharges = !oneTimeCharges && !monthlyCharges;

            if (hideInCart) return;

            if (isChildItem && lineOfBusinessUnformatted === childLineOfBusiness && groupSequence === groupNo) {
              if (oneTimeCharges) {
                let exist = false;
                tempVal.oneTimeCharges[item].forEach((oneTimeVal) => {
                  if (oneTimeVal.configurationType === configurationType && oneTimeVal.groupSequence === groupNo) {
                    oneTimeVal.rows.push({
                      left: name,
                      right: oneTimeCharges ? convertToDollar(oneTimeCharges) : '',
                    });
                    exist = true;
                  }
                });
                if (!exist) {
                  tempVal.oneTimeCharges[item].push({
                    left: left,
                    right: '',
                    config: [],
                    groupSequence: groupNo,
                    configurationType: configurationType,
                    lineOfBusinessUnformatted,
                    rows: [
                      {
                        left: name,
                        right: oneTimeCharges ? convertToDollar(oneTimeCharges) : '',
                      },
                    ],
                    standardName,
                  });
                }
              }
              if (monthlyCharges || noCharges) {
                rows.push({ left: name, right: monthlyCharges ? convertToDollar(monthlyCharges) : '' });
              }
            }
          });
        }
      });
    });

    // Sorting CartItems
    lob.forEach((item) => {
      tempVal.monthlyCharges[item].sort((a, b) => sortCartItems(a, b));
      tempVal.oneTimeCharges[item].sort((a, b) => sortCartItems(a, b));
    });

    const packageInfo: InitializePackageProps = {
      name: offerName,
      total: offerTotal ? convertToDollar(offerTotal) : '',
      contractDetails: contractDescription?.length > 0 ? contractDescription : null,
      packageHeader: header,
      monthlyChargesHeader,
      oneTimeChargesDescription,
      oneTimeChargesHeader,
      oneTimeTotalCharges,
      specialOffersHeader,
      totalMonthlyChargesHeader,
      monthlyCharges: {
        total: totalMonthlyCharges ? convertToDollar(totalMonthlyCharges) : '',
        rows: [
          {
            heading: internet,
            rows: tempVal.monthlyCharges['internet'],
          },
          {
            heading: voice,
            rows: tempVal.monthlyCharges['voice'],
          },
          {
            heading: television,
            rows: tempVal.monthlyCharges['tv'],
          },
        ],
      },
      oneTimeCharges: {
        total: totalOneTimeCharges ? convertToDollar(totalOneTimeCharges) : '',
        rows: [
          {
            heading: internet,
            rows: tempVal.oneTimeCharges['internet'],
          },
          {
            heading: voice,
            rows: tempVal.oneTimeCharges['voice'],
          },
          {
            heading: television,
            rows: tempVal.oneTimeCharges['tv'],
          },
        ],
      },
      specialOffers: {
        rows: tempVal.specialOffers,
      },
      professionalInstall: tempVal?.professionalInstall
        ? addCharge({
            name: tempVal.professionalInstall.left,
            charge: tempVal.professionalInstall.right,
            configAttributes: {},
            groupSequence: 1,
            configurationType: 'InstallationProfessional',
            lineOfBusinessUnformatted: '',
            rows: [],
            standardName: '',
          })
        : null,
    };

    return {
      pageSectionHeading: pageDescription
        .replace('{{contact.firstName}}', firstName)
        .replace('{{contact.lastName}}', lastName),
      pageHeading: title,
      contactInfoHeading,
      rows: [
        { left: companyNameKey, right: companyName },
        { left: contactNameKey, right: `${firstName} ${lastName}` },
        { left: serviceAddressKey, right: formatAddress(serviceAddress) },
        { left: billingAddressKey, right: formatAddress(billingAddress) },
        { left: phoneNumberKey, right: phoneNumber },
      ],
      encryptionLabel,
      nextButton,
      orderCompleteButton,
      packageInfo,
      legal: {
        contactUs: contactUs
          .replace(/{{salesAgentInfo.telephoneNumber}}/, salesAgentTelephoneNumber.replace(/\D/g, ''))
          .replace(/{{salesAgentInfo.telephoneNumber}}/, salesAgentTelephoneNumber)
          .replace(/{{salesAgentInfo.emailAddress}}/g, salesAgentEmailAddress),
        pricingDescription,
      },
    };
  } catch (err) {
    return null;
  }
}

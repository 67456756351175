import { PrismButton, PrismIcon, PrismStepperNavigation } from '@prism-ui/react';
import './style.css';
import { getConfig } from '../../utility/env';

export default function StepperNavigation(props) {
  const env = getConfig();
  var finalStepperList;
  var stepNo;

  let backUrl;
  if (props.sourcesystem === 'EC') {
    backUrl = env.signNow.back.existing;
  } else {
    backUrl = env.signNow.back.new;
  }

  if (props.pageData) {
    finalStepperList = `{"steps":["${props.pageData?.finalPageHeading?.join('","')}"]}`;
    props.pageData.finalRoutes.forEach(function (route: any, index: any) {
      if (window.location.href.indexOf(route) > -1) {
        return (stepNo = index + 1);
      }
    });
  }

  const goBack = () => {
    if (props.pageData) {
      props.pageData.finalRoutes.forEach(function (route: any, index: any) {
        if (window.location.href.indexOf(route) > -1) {
          props.history.push(props.pageData.finalRoutes[index - 1]);
        }
      });
    }
  };

  return (
    <div>
      <div className="stepper-container">
        {props.isOrderDetails && props.oppid && props.oppid !== '' && (
          <div className="sign-now-back">
            <PrismButton class="button" display="link" href={backUrl.replace('<opp_id>', props.oppid)}>
              <PrismIcon slot="icon" name="arrow_left" size="md" theme="outline" />
              {props.backButtonText}
            </PrismButton>
          </div>
        )}
        <PrismStepperNavigation step={stepNo} steps={finalStepperList}>
          <PrismButton class="button" display="link" slot="button" onClick={() => goBack()}>
            <PrismIcon slot="icon" name="arrow_left" size="md" theme="outline" />
            {props.backButtonText}
          </PrismButton>
        </PrismStepperNavigation>
      </div>
      <div className="stepper-navigation-rule" />
    </div>
  );
}

import classNames from 'classnames';
import { PrismText } from '@prism-ui/react';
import './style.css';

type Size = 'lg' | '';

type Props = {
  size?: Size;
  message?: string;
};

const LoadingIndicator = ({ size, message }: Props) => {
  const sizeClass = size && `cb-loading-indicator--${size}`;

  return (
    <div className="frictionless-loading-holder">
      <div className="frictionless-loading-block">
        <div className="cb-loading-indicator-wrap">
          <div className={classNames('cb-loading-indicator', sizeClass)}>
            <svg viewBox="0 0 120 120" className="cb-loading">
              <title> Loading the Page </title>
              <defs>
                <radialGradient
                  cx="6.055%"
                  cy="68.768%"
                  fx="6.055%"
                  fy="68.768%"
                  r="116.437%"
                  gradientTransform="scale(.93507 1) rotate(-20.139 .063 .676)"
                  id="blue-fade"
                >
                  <stop stopColor="#1471DA" offset="0%" />
                  <stop stopColor="#6EA8EA" stopOpacity=".001" offset="99.911%" />
                  <stop stopColor="#6EA8EA" stopOpacity="0" offset="100%" />
                </radialGradient>
              </defs>
              <circle
                className="cb-loading-circle cb-loading-base"
                stroke={`url(${window.location.href}#blue-fade)`}
                r="50"
                cx="60"
                cy="60"
              />
            </svg>
          </div>
          {message ? (
            <div className="headline">
              <PrismText display="bodyL" tag="h2">
                {message}
              </PrismText>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LoadingIndicator;

import { useContext } from 'react';
import PrismLegalAgreement from './../prism/legal-agreement'
import { getConfig } from '../../utility/env';
import UserAgentContext from '../../context/userAgent';
import './style.css';
import Signaturepad from '../../components/signature-pad/signaturePad';
import { SessionContext } from '../../../src/App';
import { packagePromotion } from '../../utility/global-constants/contractTypes';

const env = getConfig();


export default function Contracts(props: any) {
  const isUserAgent = useContext(UserAgentContext);
  const sessionData = useContext(SessionContext);

  const validateSignNow =(value:any)=>{
    if(sessionData.oppid !== '')
    {
      props.signNowStatus(value)
    }
  }

  return (
    <div>
      {props.sections?.map((section: any, index: any) => {
        let { description, acknowledgement, inlineValidationMessage } = section;
        const { title, showPrint, showDownload, downloadLink, hideCheckBox } = section;
        const links = Object.keys(section).filter((key) => /Link$/.test(key));

        links.forEach((link) => {
          description = description
            .replace(`{{${link}.text}}`, section[link].text)
            .replace(`{{${link}.href}}`, section[link].href);

          acknowledgement = acknowledgement
            .replace(`{{${link}.text}}`, section[link].text)
            .replace(`{{${link}.href}}`, section[link].href);
        });

        return (
          <div className="legal-agreement">
            <PrismLegalAgreement
              key={`legal-agreement-${index}`}
              heading={title}
              enablePrint={showPrint}
              downloadPath={showDownload ? `${env.content.baseUrl}${downloadLink}` : ''}
              label={acknowledgement ? acknowledgement : ''}
              required={true}
              disabled={isUserAgent}
              hideCheckBox={hideCheckBox}
              description={description}
              inlineValidationMessage={inlineValidationMessage}
            ></PrismLegalAgreement>
            {section.type === packagePromotion && sessionData.oppid !== '' && (
              <Signaturepad
                key={`signature-pad-${index}`}
                sessionData={sessionData}
                signNowLabels={section.signNow}
                documents={props.docs}
                section={section}
                checkboxClicked={props.checkboxClicked}
                signNowValidate={validateSignNow}
                acceptedError={props.acceptedError}
              ></Signaturepad>
            )}
          </div>
        );
      })}
    </div>
  );
}

import './App.css';
import { createContext } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import AccountRegistration from './pages/AccountRegistration';
import Confirmation from './pages/Confirmation';
import DocumentsAndAgreements from './pages/DocsAndAgreements';
import ErrorComponent from './pages/Error';
import OrderDetails from './pages/OrderDetails';
import TermsAndCondtions from './pages/TermsAndConditions';
import PhoneTransfer from './pages/PhoneTransfer';
import SvgSpriteMap from './components/molecules/svgSpriteMap';
import {
  pageConfirmation,
  pageDocumentsAndAgreements,
  pageError,
  pageOrderDetails,
  pageRegistration,
  pageTermsAndConditions,
  phoneTransfer,
} from './utility/global-constants/pages';
import { QueryClient, QueryClientProvider } from 'react-query';
import { staleTime } from './utility/global-constants/queryKeys';
import { ErrorBoundary } from 'react-error-boundary';
import { errorBoundaryHelper } from './utility/error';
import { stopReportingRuntimeErrors } from 'react-error-overlay';
import { getQueryStringObject } from '../src/utility/helper';
import { addDatalayerScripts } from '../src/utility/dataLayerScripts';
import { useState, useEffect } from 'react';
import UserAgentContext from './context/userAgent';
import ClientValidationContext from './context/clientValidation';
import AccountRegistrationContext from './context/AccountRegistration';
import PrimaryManagerContext from './context/primaryManager';
import { version as packageJsonVersion } from '../package.json';
import { registrationTypeValue, registrationStatusValue } from './utility/global-constants/registrationStates';
import { setSALogging } from './utility/saLogging';
import { trackingID } from './utility/global-constants/queryKeys';
import { tracking } from './utility/global-constants/analytics';
import TrackingData from './components/experienceTracking/TrackingData';

const bootstrapQueryParams = getQueryStringObject();

export const SessionContext = createContext();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: staleTime,
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors(); // disables error overlays
}

addDatalayerScripts(); // Add dataLayer to Application if disablescript is false

function App() {
  const [isUserAgent, setIsUserAgent] = useState(false);
  const [isClientValidationDisabled, setIsClientValidationDisabled] = useState(false);
  const [registrationState, setRegistrationState] = useState({
    type: registrationTypeValue.selfregistration,
    status: registrationStatusValue.success,
  });
  const updateRegistrationState = (typeVal, statusVal) => {
    setRegistrationState({ type: typeVal, status: statusVal });
  };
  const [primaryManager, setPrimaryManager] = useState({ firstName: 'fn', lastName: 'ln', emailAddress: 'ed' });
  const updatePrimaryManager = (first, last, address) => {
    setPrimaryManager({ firstName: first, lastName: last, emailAddress: address });
  };

  const showUiVersion = () => {
    window.uiVersion = packageJsonVersion;
  };

  useEffect(() => {
    setSALogging(trackingID, bootstrapQueryParams.id);
  }, []);

  useEffect(() => {
    showUiVersion();
    const queryParams = getQueryStringObject();
    if (queryParams['oppid']) {
      updateRegistrationState(registrationTypeValue.signNow, null);
    }
    if (queryParams && queryParams['accesstype'] && queryParams['accesstype'] === 'read') setIsUserAgent(true);
    if (queryParams && queryParams['isvalidationdisabled'] && queryParams['isvalidationdisabled'] === true)
      setIsClientValidationDisabled(true);
  }, []);

  const schemaVersion = 0.11;
  const page = {
    pageInfo: {
      screenName: tracking.GATEWAY_ESIGN
    },
    codebase: {
      name: tracking.BSEE,
      releaseVersion: packageJsonVersion,
    },
    category: {
      primaryCategory: tracking.SALES,
      subCategory1: tracking.FRICTIONLESS,
      businessType: tracking.BUSN,
      siteType: tracking.SALES,
      designType: tracking.RESPONSIVE,
    }
  };
 
  const digitalData = {
    schemaVersion: schemaVersion,
    page:page
  }
  return (
    <div>
      <TrackingData type="digitalData" trackingInfo={digitalData} />
      <ErrorBoundary
        FallbackComponent={ErrorComponent}
        onError={(error, info) => errorBoundaryHelper(error, info, trackingID, 'sessionID')}
      >
        <SvgSpriteMap />
        <QueryClientProvider client={queryClient}>
            <SessionContext.Provider value={bootstrapQueryParams}>
              <UserAgentContext.Provider value={isUserAgent}>
                <ClientValidationContext.Provider value={isClientValidationDisabled}>
                  <AccountRegistrationContext.Provider value={{ registrationState, updateRegistrationState }}>
                    <PrimaryManagerContext.Provider value={{ primaryManager, updatePrimaryManager }}>
                        <BrowserRouter>
                          <Switch>
                            <Route exact path={pageConfirmation.route}>
                              <Confirmation />
                            </Route>
                            <Route exact path={pageDocumentsAndAgreements.route}>
                              <DocumentsAndAgreements />
                            </Route>
                            <Route exact path={pageError.route}>
                              <ErrorComponent />
                            </Route>
                            <Route exact path={pageOrderDetails.route}>
                              <OrderDetails />
                            </Route>
                            <Route exact path={pageRegistration.route}>
                              <AccountRegistration />
                            </Route>
                            <Route exact path={pageTermsAndConditions.route}>
                              <TermsAndCondtions />
                            </Route>
                            <Route exact path={phoneTransfer.route}>
                              <PhoneTransfer />
                            </Route>
                            <Route path="/about">
                              <About />
                            </Route>
                            <Route path="/">
                              <Home />
                            </Route>
                          </Switch>
                        </BrowserRouter>
                    </PrimaryManagerContext.Provider>
                  </AccountRegistrationContext.Provider>
                </ClientValidationContext.Provider>
              </UserAgentContext.Provider>
            </SessionContext.Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;

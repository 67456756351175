export const RegistrationAPIPasswordValidationCodes = {
    PasswordRequired: 'Pwd-001',
    PasswordLengthValidationFailed: 'Pwd-002',
    PasswordShouldHaveUppercaseLetter: 'Pwd-003',
    PasswordShouldHaveLowercaseLetter: 'Pwd-004',
    PasswordShouldHaveNumber: 'Pwd-005',
    PasswordCannotContainFirstName: 'Pwd-006',
    PasswordCannotContainLastName: 'Pwd-007',
    PasswordDoesNotMatchConfirmPassword: 'Pwd-008',
    PasswordShouldHaveSpecialCharacter: 'Pwd-009',
    PasswordCanBeEasilyGuessed: 'Pwd-010',
    PasswordValidationServiceFailure: 'Pwd-011',
    SecurityAnswerFailed: 'SEC-001'
};
type Params = {
  [index: string]: any;
};

const getQueryString = () => {
  return window.location.search.substring(1);
};

const getQueryStringParameters = () => {
  const queryString = helpers.getQueryString();
  const params: Params = {};

  let temp: string[] = [];

  if (queryString) {
    queryString.split('&').forEach((query) => {
      temp = query.split('=');

      params[temp[0]] = temp[1];
    });
  }

  return params;
};
const getQueryStringValue = (parameter: string) => {
  const params = helpers.getQueryStringParameters();

  return params[parameter];
};

const helpers = {
  getQueryString,
  getQueryStringValue,
  getQueryStringParameters,
};

export default helpers;

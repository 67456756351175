import { AccountRegistrationState, PrimaryManagerState } from '../../interfaces';
import Content from '../../interfaces/api/content';
import Initialize from '../../interfaces/api/initialize';
import { formatAddress } from '../../utility/format';
import { registrationTypeValue, registrationStatusValue } from '../../utility/global-constants/registrationStates';
import { updateTempValues, replaceSalesAgentInfo, replaceContact } from './helper';
import { confirmationPages, confirmationScreenNames } from '../../utility/global-constants/pages';

export default function derivedDataConfirmation<
  T extends Content,
  U extends Initialize,
  X extends AccountRegistrationState,
  V extends PrimaryManagerState,
  W extends String
>({
  contentData,
  initializeData,
  registrationState,
  primaryManager,
  onboardingUrl,
}: {
  contentData: T;
  initializeData: U;
  registrationState: X;
  primaryManager: V;
  onboardingUrl: W;
}) {
  const { contact, salesAgentInfo, offer } = initializeData;
  const { firstName, lastName, billingAddress, serviceAddress } = contact;
  const isEPCEcoBillDiscountEnabled = offer.isEPCEcoBillDiscountEnabled;
  const { type: registrationType, status: registrationStatus } = registrationState;

  const linkAccountDetails = contentData?.pages?.confirmation?.linkAccount;
  const selfRegistration = contentData?.pages?.confirmation?.selfRegistration;
  const inviteUser = contentData?.pages?.confirmation?.inviteUser;
  const signNow = contentData?.pages?.confirmation?.signNow;

  let confirmationContent;
  let tempValues;
  let orderDescription;
  let navigation;

  if (
    registrationType === registrationTypeValue.selfregistration ||
    registrationType === registrationTypeValue.linkAccount ||
    registrationType === registrationTypeValue.signNow
  ) {
    switch (registrationType) {
      case registrationTypeValue.selfregistration:
        confirmationContent = selfRegistration;
        break;
      case registrationTypeValue.linkAccount:
        confirmationContent = linkAccountDetails;
        break;
      case registrationTypeValue.signNow:
        confirmationContent = signNow;
        break;
    }
    tempValues = updateTempValues(confirmationContent, salesAgentInfo, registrationType, primaryManager);
    navigation = null;
  } else if (registrationType === registrationTypeValue.invite) {
    confirmationContent = inviteUser;
    tempValues = updateTempValues(confirmationContent, salesAgentInfo, registrationType, primaryManager);
    navigation = inviteUser?.navigation;
  }

  orderDescription = confirmationContent?.orderConfirmation?.description;

  if (registrationStatus === registrationStatusValue.failed) {
    tempValues = {
      ...tempValues,
      accountTitle:
        registrationType === registrationTypeValue.selfregistration
          ? selfRegistration?.error?.title
          : inviteUser?.error?.title,
      accountDescription:
        registrationType === registrationTypeValue.selfregistration
          ? selfRegistration?.error?.description
          : inviteUser?.error?.description,
      servicesTitle: null,
      servicesDescription: null,
      billPaymentTitle: null,
      billPaymentDescription: null,
      notificationsTitle: null,
      notificationsDescription: null,
      myAccountButton:
        registrationType === registrationTypeValue.selfregistration
          ? selfRegistration?.error?.myAccountButton
          : inviteUser?.error?.myAccountButton,
      contactUs:
        registrationType === registrationTypeValue.invite
          ? replaceSalesAgentInfo(inviteUser?.error?.contactUs || '', salesAgentInfo)
          : tempValues?.contactUs,
      dashboardbutton: null,
    };

    orderDescription =
      registrationType === registrationTypeValue.invite
        ? inviteUser?.error?.confirmationDescription
        : confirmationContent?.orderConfirmation?.description;

    navigation = null;
  }

  let screenName;
  let pageLoadEventValue;
  switch (registrationType) {
    case registrationTypeValue.selfregistration:
      if (registrationStatus === registrationStatusValue.failed) {
        screenName = confirmationScreenNames.selfRegistrationFailure;
        pageLoadEventValue = confirmationPages.selfRegistrationFailure;
      } else {
        screenName = confirmationScreenNames.selfRegistrationSuccess;
        pageLoadEventValue = confirmationPages.selfRegistrationSuccess;
      }
      break;
    case registrationTypeValue.invite:
      if (registrationStatus === registrationStatusValue.failed) {
        screenName = confirmationScreenNames.inviteFailure;
        pageLoadEventValue = confirmationPages.inviteFailure;
      } else {
        screenName = confirmationScreenNames.inviteSuccess;
        pageLoadEventValue = confirmationPages.inviteSuccess;
      }
      break;
    case registrationTypeValue.linkAccount:
      screenName = confirmationScreenNames.linkAccount;
      pageLoadEventValue = confirmationPages.linkAccount;
      break;
    case registrationTypeValue.signNow:
      screenName = confirmationScreenNames.signNow;
      pageLoadEventValue = confirmationPages.signNow;
      break;
  }

  const finalData = {
    pageTitle: confirmationContent?.pageTitle,
    title: confirmationContent?.orderConfirmation?.title,
    description: replaceSalesAgentInfo(replaceContact(orderDescription || '', contact), salesAgentInfo),
    accountTitle: tempValues?.accountTitle,
    accountDescription: tempValues?.accountDescription,
    servicesTitle: tempValues?.servicesTitle,
    servicesDescription: tempValues?.servicesDescription,
    billPaymentTitle: tempValues?.billPaymentTitle,
    billPaymentDescription: tempValues?.billPaymentDescription,
    notificationsTitle: tempValues?.notificationsTitle,
    notificationsDescription: tempValues?.notificationsDescription,
    myAccountButton: tempValues?.myAccountButton,
    contactUs: tempValues?.contactUs,
    accountLinkTitle: confirmationContent?.accountTitle,
    addressTitle: confirmationContent?.addressTitle,
    firstName: firstName,
    lastName: lastName,
    serviceAddress: formatAddress(serviceAddress),
    billingAddress: formatAddress(billingAddress),
    dashboardbutton: tempValues?.dashboardbutton,
    navigation: navigation,
    screenName: screenName,
    isEPCEcoBillDiscountEnabled: isEPCEcoBillDiscountEnabled,
    registrationUrl: onboardingUrl,
    pageLoadEventValue: pageLoadEventValue
  };

  return finalData;
}

export const pageConfirmation = {
  name: 'CONFIRMATION',
  route: '/confirmation',
  screenName: 'confirmation',
};

export const pageDocumentsAndAgreements = {
  name: 'DOCUMENTS AND AGREEMENTS',
  route: '/documents-and-agreements',
  screenName: 'documents-and-agreements',
};

export const pageError = {
  name: 'ERROR',
  route: '/error',
  screenName: 'error',
};

export const pageOrderDetails = {
  name: 'ORDER DETAILS',
  route: '/order-details',
  screenName: 'order-details',
};

export const pageRegistration = {
  name: 'SET UP MY ACCOUNT',
  route: '/registration',
  screenName: 'set-up-my-account',
};

export const pageTermsAndConditions = {
  name: 'TERMS AND CONDITIONS',
  route: '/terms-and-conditions',
  screenName: 'terms-and-conditions',
};

export const phoneTransfer = {
  name: 'PHONE TRANSFER AGREEMENTS',
  route: '/phone-transfer',
  screenName: 'phone-transfer-agreements',
};

export const confirmationScreenNames = {
  selfRegistrationSuccess: 'confirmation-self-registration-success',
  selfRegistrationFailure: 'confirmation-self-registration-failure',
  inviteSuccess: 'confirmation-invite-registration-success',
  inviteFailure: 'confirmation-invite-registration-failure',
  linkAccount: 'confirmation-link-account',
  signNow: 'confirmation-sign-now',
};

export const confirmationPages = {
  selfRegistrationSuccess: 'fmarr|self registration complete',
  selfRegistrationFailure: 'fmarr error|error setting up my account',
  inviteSuccess: 'fmarr|invited user registration complete',
  inviteFailure: 'fmarr error|error sending invitation',
  linkAccount: "fmarr|you're already registered for my account",
  signNow: 'fmarr|sign now complete',
};

export const localhost = 'localhost';

export const devUrl = 'dev.esign.np.digital.business.comcast.com';

export const qaUrl = 'qa.esign.np.digital.business.comcast.com';

export const pqaUrl = 'pqa.esign.np.digital.business.comcast.com';

export const stgUrl = 'stg.esign.np.digital.business.comcast.com';

export const perfUrl = 'perf.esign.np.digital.business.comcast.com';

export const prodUrl = 'esign.digital.business.comcast.com';

import SignaturePad from 'react-signature-canvas';
import { PrismButton, PrismText, PrismIcon } from '@prism-ui/react';
import { useRef, useState, useEffect } from 'react';
import './style.css';
import { getConfig } from '../../utility/env';
import { uploadDocument, uploadDocumentToS3Bucket,uploadDocumentStatus } from '../../api';
import { useQuery } from 'react-query';
import { BOOTSTRAP_CALL } from '../../utility/global-constants/queryKeys';
import { getBootstrapData } from '../../api/index';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import { getSignBlobObject } from '../../utility/helper';
import { packagePromotion, signNow, customerInitials } from '../../utility/global-constants/contractTypes';

export default function Signaturepad(props: any) {
  let sigPad = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  const config = getConfig();
  const { data: bootstrapData } = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;
  const [isAccepted, setIsAccepted] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const env = getConfig();
  const signtype = props.section?.type === packagePromotion ? 'initials' : 'sign-now';

  const clearSignature = () => {
    sigPad.current.clear();
    setIsAccepted(false);
    if (props.checkboxClicked === true) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  };

  const onSignatureBegin = () => {
    setErrorState(false);
    setIsAccepted(false);
    props.signNowValidate(false);
  };

  useEffect(() => {
    if (props.checkboxClicked === true && sigPad?.current?.isEmpty()) {
      setErrorState(true);
      props.signNowValidate(true);
    } else {
      setErrorState(false);
      props.signNowValidate(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkboxClicked]);

  const updateSignature = async () => {
    try {
      if (sigPad.current.isEmpty()) {
        setErrorState(true);
      } else {
        setIsLoading(true);
        setErrorState(false);

        const finalBlobOject = getSignBlobObject(sigPad.current.toDataURL());

        let reqBody;

        if (props.section?.type === packagePromotion) {
          let docID;
          props.documents.forEach(function (item: any) {
            if (item.type === customerInitials) {
              docID = item.id;
            }
          });
          reqBody = {
            id: props.sessionData.id,
            type: customerInitials,
            file: customerInitials + '.png',
            environment: config.name,
            documentId: docID,
          };
        } else {
          let docID;
          props.documents.forEach((item: any) => {
            if (item.type === signNow) {
              docID = item.id;
            }
          });

          props.documents.forEach(function (item: any) {
            if (item.type === signNow) {
              docID = item.id;
            }
          });
          reqBody = {
            id: props.sessionData.id,
            file: signNow + '.png',
            type: signNow,
            environment: config.name,
            documentId: docID,
          };
        }

        var response = await uploadDocument(reqBody, cimaToken);
        const finalResponse = response.data.fields;

        setIsAccepted(true);

        const formData = new FormData();

        if (response.status === 'Success') {
          formData.append('Content-Type', finalResponse['Content-Type']);
          formData.append('key', finalResponse['key']);
          formData.append('bucket', finalResponse['bucket']);
          formData.append('X-Amz-Algorithm', finalResponse['X-Amz-Algorithm']);
          formData.append('X-Amz-Credential', finalResponse['X-Amz-Credential']);
          formData.append('X-Amz-Date', finalResponse['X-Amz-Date']);
          formData.append('X-Amz-Security-Token', finalResponse['X-Amz-Security-Token']);
          formData.append('Policy', finalResponse['Policy']);
          formData.append('X-Amz-Signature', finalResponse['X-Amz-Signature']);
          formData.append('X-Amz-Meta-Doc-Type', finalResponse['X-Amz-Meta-Doc-Type']);
          formData.append('file', finalBlobOject);

          var s3Response = await uploadDocumentToS3Bucket(formData, response.data.url);
          console.log('s3Response', s3Response);
          if (s3Response === '') {
            let docID;
            let fileName;
            if (props.section?.type === packagePromotion) {
              props.documents.forEach((item: any) => {
                if (item.type === customerInitials) {
                  docID = item.id;
                  fileName = customerInitials + '.png';
                }
              });
            } else {
              props.documents.forEach((item: any) => {
                if (item.type === signNow) {
                  docID = item.id;
                  fileName = signNow + '.png';
                }
              });
            }
            let statusReqBody = {
              id: props.sessionData.id,
              fileName: fileName,
              documentId: docID,
            };
            var statusResponse = await uploadDocumentStatus(statusReqBody, cimaToken);
            const finalStatusResponse = statusResponse.data.status;
            console.log('finalStatusResponse', finalStatusResponse);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="sign-container">
      {isLoading && <LoadingIndicator />}
      <div className="sign-heading">
        <PrismText display="heading7">{props.signNowLabels?.label}</PrismText>
      </div>
      <div className={!errorState ? 'sign-pad' : 'invalid-sign-pad-error'}>
        <SignaturePad
          ref={sigPad}
          canvasProps={{ width: 900, height: 200, role: 'SignPad', className: !isAccepted ? 'canvas-enable' : 'canvas-disable' }}
          onBegin={() => onSignatureBegin()}
        ></SignaturePad>
        <div className="sign-pad-clear">
          <PrismIcon
            slot="icon"
            data-testid="clearButton"
            path={`${env.content.baseUrl}/images/icons/reset-button.svg`}
            size="sm"
            theme="outline"
            onClick={() => clearSignature()}
          />
        </div>
      </div>
      {isAccepted ? (
        <div className="sign-accepted-button">
          <div>
            <PrismIcon
              slot="icon"
              path={`${env.content.baseUrl}/images/icons/verification-check.svg`}
              size="sm"
              theme="outline"
            />
          </div>
          <div className="sign-accepted-text">
            <PrismText display="heading7">{props.signNowLabels?.acceptedButtonText}</PrismText>
          </div>
        </div>
      ) : (
        <div className={`sign-accept-button ${signtype}-acc-button`}>
          <PrismButton  data-testid="updateButton" display="fill" onClick={() => updateSignature()}>
            {props.signNowLabels?.buttonText}
          </PrismButton>
        </div>
      )}
      {errorState ? (
        <div className="invalid-sign-accept-button-error">
          <PrismText display="bodyS"> {props.signNowLabels?.validationMessage}</PrismText>
        </div>
      ) : props?.acceptedError && !isAccepted ? (
        <div className="invalid-sign-accept-button-error">
          <PrismText display="bodyS"> {props.signNowLabels?.acceptMessage}</PrismText>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

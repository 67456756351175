import {
  pageRegistration,
  pageConfirmation,
  pageError,
  pageOrderDetails,
  pageTermsAndConditions,
  pageDocumentsAndAgreements,
  phoneTransfer
} from '../global-constants/pages';
import InterfaceContentData from '../../interfaces/api/content';
export function getHeading({ page, contentData }: { page: string; contentData: InterfaceContentData }) {
  try {
    const {
      pages: { agreements, confirmation, review, accountRegistration }
    } = contentData;

    let heading = '';

    switch (page) {
      case pageRegistration.name:
        heading = accountRegistration.pageTitle;
        break;
      case pageConfirmation.name:
        heading = confirmation.inviteUser.pageTitle;
        break;
      case pageOrderDetails.name:
        heading = review.pageTitle;
        break;
      case pageTermsAndConditions.name:
        heading = agreements.documents.pageTitle;
        break;
      case pageDocumentsAndAgreements.name:
        heading = agreements.documents.pageTitle;
        break;
      case phoneTransfer.name:
        heading = review.pageTitle;
        break;
      case pageError.name:
      default:
        break;
    }

    return heading;
  } catch (err) {
    return '';
  }
}

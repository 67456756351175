import { getConfig } from './../../utility/env';
import { v4 as uuidv4 } from 'uuid';

export const BOOTSTRAP_CALL = 'bootstrapData';

export const GET_INITIALIZE_DATA_CALL = 'getInitializeData';
export const GET_CONTENT_DATA_CALL = 'getContentData';

export const env = getConfig();

export let trackingID;

if (window.sessionStorage.getItem('TrackingID')) {
  trackingID = window.sessionStorage.getItem('TrackingID');
} else {
  trackingID = uuidv4();
  window.sessionStorage.setItem('TrackingID', trackingID);
}

export const bootstrapHeaders = {
  mode: 'no-cors',
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': env['api-key'],
  'tracking-id': trackingID,
};

export const staleTime = 300000;

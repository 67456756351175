import { PrismButton, PrismNextStepBar } from '@prism-ui/react';
import './style.css';
import { getConfig } from '../../utility/env';
import { useQuery } from 'react-query';
import { BOOTSTRAP_CALL } from '../../utility/global-constants/queryKeys';
import { getBootstrapData, getUpdateOrder } from '../../api/index';
import { pageConfirmation, pageError } from '../../utility/global-constants/pages';
import UserAgentContext from '../../context/userAgent';
import { useContext, useState } from 'react';
import { isLastStep } from '../../utility/steps';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';

export default function NextStepBar(props) {
  const { data: bootstrapData } = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;
  const [isLoading, setIsLoading] = useState(false);

  const handleError = () => {
    setIsLoading(false);
    props.history.push(pageError.route);
  };

  const userRedirect = async () => {
    if (!props.completeOrder) {
      if (props.nextClicked) {
        return props.nextClicked('next');
      }
      if (props.pageData) {
        var isRedirected;
        props.pageData.finalRoutes.forEach(function(route, index) {
          if (window.location.href.indexOf(route) > -1 && !isRedirected) {
            props.history.push(props.pageData.finalRoutes[index + 1]);
            isRedirected = true;
          }
        });
      }
    } else {
      if (props.nextClicked) {
        setIsLoading(true);
        try {
          setIsLoading(false);
          return props.nextClicked('nextclicked');
        } catch {
          handleError();
        }
      }
      if (!isUserAgent && !props?.isAcctReg) {
        setIsLoading(true);
        const response = await getUpdateOrder(cimaToken, props.sessionData.id).catch(() => {
          handleError();
        });
        if (response?.data && response?.status === 'Success') {
          setIsLoading(false);
          props.history.push(pageConfirmation.route);
        } else {
          handleError();
        }
      }
    }
  };

  const isUserAgent = useContext(UserAgentContext);
  const lastStep = isLastStep(props);
  const buttonType = isUserAgent ? '' : 'submit';

  const env = getConfig();

  return (
    <div>
      <div>
        {isLoading && <LoadingIndicator />}
        <PrismNextStepBar label="Secured Encryption" icon-path={`${env.content.baseUrl}images/icons/lock.svg`}>
          <div slot="bar-actions" className={!props.completeOrder ? 'next-container' : 'complete-container'}>
            <PrismButton
              type={buttonType}
              disabled={isUserAgent && lastStep}
              display="fill"
              onClick={() => userRedirect()}
            >
              {!props.completeOrder ? props?.content?.nextButton : props?.content?.orderCompleteButton}
            </PrismButton>
          </div>
        </PrismNextStepBar>
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import { PrismText } from '@prism-ui/react';
import * as yup from 'yup';
import './style.css';
import ValidationCriteria from '../../molecules/atoms/validationCriteria/index';

interface IPasswordEssentialsProps {
    PeFunctions: {
        setPeMinMax: Function,
        setPeOneUppercase: Function,
        setPeOneLowercase: Function,
        setPeOneDigit: Function,
        setPeOneSpecialChar: Function,
    };
    PeStates: {
        peMinMax: string,
        peOneUppercase: string,
        peOneLowercase: string,
        peOneDigit: string,
        peOneSpecialChar: string,
        peNoFirstLastName: string,
        peMustMatch: string
    };
    PeText: {
        passwordEssentials: string,
        lengthValidation: string,
        uppercase: string,
        lowercase: string,
        number: string,
        specialCharacter: string,
        noFirstOrLast: string,
        notEasy: string,
        mustMatch: string
    };
    watch: any
};

const peSchemaMinMax = yup.object().shape({
    password: yup.string().min(8).max(16),
});
const peSchemaUppercase = yup.object().shape({
    password: yup.string().matches(/(?=.*[A-Z])/),
});
const peSchemaLowercase = yup.object().shape({
    password: yup.string().matches(/(?=.*[a-z])/),
});
const peSchemaOneDigit = yup.object().shape({
    password: yup.string().matches(/[0-9]+/),
});
const peSchemaOneSpecialChar = yup.object().shape({
    password: yup.string().matches(/[#@\-.?!$*()+;:~/%_,=&[\]{}<>|""]+/),
});

export default function PasswordEssentials({PeFunctions, PeStates, PeText, watch} : IPasswordEssentialsProps) {
    let watchPassword = watch('password');
    let passwordIsBlank = watchPassword === '';

    const {
        setPeMinMax,
        setPeOneUppercase,
        setPeOneLowercase,
        setPeOneDigit,
        setPeOneSpecialChar
    } = PeFunctions;
    const {
        peMinMax,
        peOneUppercase,
        peOneLowercase,
        peOneDigit,
        peOneSpecialChar,
        peNoFirstLastName,
        peMustMatch
    } = PeStates;
    

    useEffect(() => {
        // Min Max Checking
        yup
            .reach(peSchemaMinMax, 'password')
            .validate(watchPassword)
            .then(() => {
            setPeMinMax('VALID');
        })
        .catch(() => {
            if (passwordIsBlank) {
                setPeMinMax('NEUTRAL');
            } else {
                setPeMinMax('INVALID');
            }
        });
        // One Lowercase Letter Checking
        yup
            .reach(peSchemaLowercase, 'password')
            .validate(watchPassword)
            .then(() => {
            setPeOneLowercase('VALID');
        })
        .catch(() => {
            if (passwordIsBlank) {
                setPeOneLowercase('NEUTRAL');
            } else {
                setPeOneLowercase('INVALID');
            }
        });
        // One Uppercase Letter Checking
        yup
            .reach(peSchemaUppercase, 'password')
            .validate(watchPassword)
            .then(() => {
            setPeOneUppercase('VALID');
        })
        .catch(() => {
            if (passwordIsBlank) {
                setPeOneUppercase('NEUTRAL');
            } else {
                setPeOneUppercase('INVALID');
            }
        });
        // One Digit Checking
        yup
            .reach(peSchemaOneDigit, 'password')
            .validate(watchPassword)
            .then(() => {
            setPeOneDigit('VALID');
        })
        .catch(() => {
            if (passwordIsBlank) {
                setPeOneDigit('NEUTRAL');
            } else {
                setPeOneDigit('INVALID');
            }
        });
        // One Special Character Checking
        yup
            .reach(peSchemaOneSpecialChar, 'password')
            .validate(watchPassword)
            .then(() => {
            setPeOneSpecialChar('VALID');
        })
        .catch(() => {
            if (passwordIsBlank) {
                setPeOneSpecialChar('NEUTRAL');
            } else {
                setPeOneSpecialChar('INVALID');
            }
        });
    }, [watchPassword, passwordIsBlank, setPeMinMax, setPeOneLowercase, setPeOneUppercase, setPeOneDigit, setPeOneSpecialChar]);

    return (
        <div className='password-essentials-container'>
            <PrismText display="functional1" tag="h2" className='password-essentials-header'>Password Essentials</PrismText>
            <ValidationCriteria criteriaStatus={peMinMax} criteriaText={PeText!.lengthValidation} />
            <ValidationCriteria criteriaStatus={peOneUppercase} criteriaText={PeText!.uppercase} />
            <ValidationCriteria criteriaStatus={peOneLowercase} criteriaText={PeText!.lowercase} />
            <ValidationCriteria criteriaStatus={peOneDigit} criteriaText={PeText!.number} />
            <ValidationCriteria criteriaStatus={peOneSpecialChar} criteriaText={PeText!.specialCharacter} />
            <ValidationCriteria criteriaStatus={peNoFirstLastName} criteriaText={PeText!.noFirstOrLast} />
            <ValidationCriteria criteriaStatus={peMustMatch} criteriaText={PeText!.mustMatch} />
        </div>
    );
}
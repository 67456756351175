import { apiKeys } from './../../utility/global-constants/authentication';

export const devConfig = {
  name: 'dev',
  logging: {
    baseUrl: 'https://dev.core-gateway.np.api-business.comcast.com/',
    key: 'JKbf1AExxt9Hmim9OsZsC3zjAMsBzOog4jPRJFLD',
  },
  eSign: {
    baseUrl: 'https://dev.ecom-gateway.np.api-business.comcast.com/',
  },
  content: {
    baseUrl: 'https://content.ecom.np.digital.business.comcast.com',
  },
  myAccount: {
    baseUrl: 'https://business.dev.comcast.com/',
  },
  frictionLess: {
    baseUrl: '',
  },
  dataLayer: {
    src: 'https://static.cimcontent.net/data-layer/nonprod/datalayer-commercial-dtm.min.js?appId=busn_ecom',
  },
  signNow: {
    confirmation: {
      new: 'https://comcast--sfdce2e1--c.cs93.visual.force.com/apex/FT_FlexiQuote?id=<opp_id>&page=schedule',
      existing: 'https://qa2.np.fxbuyflow.business.comcast.com/sign-now/success?id=<opp_id>',
    },
    back: {
      new: 'https://comcast--sfdce2e1--c.cs93.visual.force.com/apex/FT_FlexiQuote?id=<opp_id>',
      existing: 'https://qa2.np.fxbuyflow.business.comcast.com/sign-now/reconfigure?id=<opp_id>',
    },
  },
  'api-key': apiKeys.preProd,
  serviceLoggingEnabled: true,
  initializeCall504DelayEnabled: true,
  updateCall504DelayEnabled: true
};

import { useEffect, useState } from 'react';
import NavigationBar from '../../components/navigation-bar';
import { pageDocumentsAndAgreements } from '../../utility/global-constants/pages';
import { PrismSectionHeading, PrismPageHeading } from '@prism-ui/react';
import Container from '../../components/container';
import { useGetCustomerAndContentData } from '../../hooks/dml/documents.hooks';
import { pageAllocation } from '../../hooks/dml/documents.hooks';
import derivedData from './derivedData';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import NextStepBar from '../../containers/stepper/nextStepBar';
import StepperNavigation from '../../containers/stepper/stepperNavigation';
import { isLastStep } from '../../utility/steps';
import { Redirect } from 'react-router-dom';
import { useContext } from 'react';
import { SessionContext } from '../../App';
import Contracts from '../../components/contracts';
import UploadDocuments from '../../components/uploadDocuments';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { getBootstrapData } from '../../api/index';
import { useQuery } from 'react-query';
import { BOOTSTRAP_CALL } from '../../utility/global-constants/queryKeys';
import { pageViewUpdate, spaTransition } from '../../utility/updateDataLayer';
import {
  onSubmit,
  checkBoxEventListener,
  checkInvalidMessage,
  checkSignPadInvalidMessage,
  checkAcceptButton,
} from '../../utility/submit';
import TrackingData from '../../components/experienceTracking/TrackingData';
import Signaturepad from '../../components/signature-pad/signaturePad';

export default function DocumentsAndAgreements() {
  let data: any;
  const [docData, setDocData] = useState(data);
  const { isLoading, isError, initializeData, contentData } = useGetCustomerAndContentData();
  data = derivedData({ contentData, initializeData });
  var pageData: any;
  const history = useHistory();
  const sessionData = useContext(SessionContext);
  var isSubmission: boolean = false;
  const { data: bootstrapData } = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;
  var isPageError: boolean = false;
  const [isSignNowError, setIsSignNowError] = useState(false);
  const [isSignAccepted, setIsSignAccepted] = useState({ initial: false, sign: false });
  const [isLoader, setIsLoader] = useState(false);

  const page = {
    pageInfo: {
      screenName: pageDocumentsAndAgreements.screenName,
    },
  };

  useEffect(() => {
    data?.uploadDocuments.sections.forEach((item: any, index: any) => {
      data.uploadDocuments.sections[index].invalid = false;
      data.uploadDocuments.sections[index].invalidMessage = requiredMessage(item.title);
    });
    setDocData(data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = 'Documents & Agreements';
    checkBoxEventListener();
    pageViewUpdate();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return history?.listen(() => {
      spaTransition();
    });
  }, [history]);

  if (data) {
    pageData = pageAllocation(initializeData, contentData);
  }

  const { handleSubmit, control } = useForm<IFormInputs>();
  if (isError) return <Redirect to="/error" />;

  if (pageData && pageData.finalRoutes) {
    pageData.finalRoutes.forEach(function (route: any, index: any) {
      if (window.location.href.indexOf(route) > -1) {
        if (pageData.finalRoutes[pageData.finalRoutes.length - 1] === route) {
          isSubmission = true;
        }
      }
    });
  }

  interface IFormInputs {
    documents: boolean;
  }

  const validateDocument = (type: any, isError: any) => {
    var docFinal = { ...docData };
    data.uploadDocuments.sections.forEach((item: any, index: any) => {
      if (item.type === type) {
        if (isError.value === true) {
          docFinal.uploadDocuments.sections[index].isError = true;
          if (isError.empty) {
            docFinal.uploadDocuments.sections[index].invalid = false;
            docFinal.uploadDocuments.sections[index].invalidMessage = requiredMessage(item.title);
          } else {
            docFinal.uploadDocuments.sections[index].invalid = true;
            docFinal.uploadDocuments.sections[index].invalidMessage = data.uploadDocuments.fileGenericError;
          }
        } else {
          docFinal.uploadDocuments.sections[index].isError = false;
          docFinal.uploadDocuments.sections[index].invalid = false;
          docFinal.uploadDocuments.sections[index].invalidMessage = '';
        }
        setDocData(docFinal);
      }
    });
  };

  const requiredMessage = (document: string) => `Please upload a ${document.substring(5).toLowerCase()}`;

  const nextButtonClicked = (item: any) => {
    setImmediate(checkInvalidMessage);
    setIsSignNowError(true);
    if (checkSignPadInvalidMessage()) {
      setIsSignAccepted(checkAcceptButton());
      isPageError = true;
    } 
  };

  const validateSignNow = (value: any) => {
    isPageError = value;
  };

  const submit: SubmitHandler<IFormInputs> = async (value: any) => {
    data.uploadDocuments.sections.forEach((item: any, index: any) => {
      if (item.isError === true) {
        isPageError = true;
      }
    });
    if (checkSignPadInvalidMessage()) {
      setIsSignAccepted(checkAcceptButton());
      isPageError = true;
    } 
    if (!isPageError) {
      setIsLoader(true)
      const pageHistory = await onSubmit(
        isSubmission,
        sessionData?.id,
        cimaToken,
        history?.location?.pathname,
        pageData
      );
      history.push(pageHistory);
    }
  };

  return (
    <div>
      <NavigationBar page={pageDocumentsAndAgreements.name} />
      <StepperNavigation
        pageData={pageData}
        history={history}
        backButtonText={contentData?.common?.actions?.backAction}
      />
      <Container>
        {(!data || isLoading || isLoader) && <LoadingIndicator />}

        {data && (
          <div>
            <PrismSectionHeading heading={data.heading} />
            {docData?.uploadDocuments?.sections.length > 0 ? (
              <PrismPageHeading heading={data.uploadDocuments.title} description={data.uploadDocuments.description} />
            ) : (
              <div />
            )}
            <form onSubmit={handleSubmit(submit)}>
              <Controller
                name="documents"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <div>
                    {docData && (
                      <UploadDocuments
                        sections={docData.uploadDocuments.sections}
                        docs={docData.uploadDocuments.document}
                        token={cimaToken}
                        sessionData={sessionData}
                        validateDoc={validateDocument}
                      />
                    )}
                    <PrismPageHeading
                      heading={data.termsAndConditions.title}
                      description={data.termsAndConditions.description}
                    />
                    {docData && (
                      <Contracts
                        sections={data.termsAndConditions.sections}
                        docs={docData.uploadDocuments.document}
                        checkboxClicked={isSignNowError}
                        signNowStatus={validateSignNow}
                        acceptedError={isSignAccepted.initial}
                      />
                    )}
                    {docData && sessionData?.oppid !== '' && sessionData?.oppid !== undefined && (
                      <Signaturepad
                        sessionData={sessionData}
                        signNowLabels={data.termsAndConditions.signNowlabels}
                        documents={docData.uploadDocuments.document}
                        checkboxClicked={isSignNowError}
                        signNowValidate={validateSignNow}
                        acceptedError={isSignAccepted.sign}
                      ></Signaturepad>
                    )}
                  </div>
                )}
              />
              <NextStepBar
                pageData={pageData}
                history={history}
                completeOrder={isLastStep({ pageData })}
                sessionData={sessionData}
                nextClicked={nextButtonClicked}
                content={data}
              />
            </form>
          </div>
        )}
      </Container>
      <TrackingData type="page" trackingInfo={page} />
    </div>
  );
}

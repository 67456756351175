import { PACKAGE_INFO } from '../../utility/global-constants/components';
import { LineOfBusinessType, tempChargeValType } from '../../interfaces/api/initialize';
import { Row } from '../../interfaces';

export const addCharge = ({
  name,
  charge,
  configAttributes,
  groupSequence,
  configurationType,
  lineOfBusinessUnformatted,
  rows,
  standardName,
}: {
  name: string;
  charge: number;
  configAttributes: Object;
  groupSequence: number;
  configurationType: string;
  lineOfBusinessUnformatted: string;
  rows: Row[];
  standardName: string;
}) => ({
  left: name,
  right: charge ? convertToDollar(charge) : 'Included',
  config: configAttributes ? Object.values(configAttributes).map((value) => ({ left: value, right: '' })) : [],
  groupSequence: groupSequence,
  configurationType: configurationType,
  lineOfBusinessUnformatted: lineOfBusinessUnformatted,
  rows: rows,
  standardName,
});

export const getLineOfBusiness = (lineOfBusiness: string): LineOfBusinessType | null => {
  const lineOfBusinessUnchecked = lineOfBusiness.toLowerCase();
  const lineOfBusinessChecked =
    lineOfBusinessUnchecked === PACKAGE_INFO.PHONE ? PACKAGE_INFO.VOICE : lineOfBusinessUnchecked;

  if ([PACKAGE_INFO.INTERNET, PACKAGE_INFO.TV, PACKAGE_INFO.VOICE].includes(lineOfBusinessChecked)) {
    return lineOfBusinessChecked as LineOfBusinessType;
  }

  return null;
};

export const convertToDollar = (num: number): string => {
  if (Number(num) !== num) return '';
  let money = Number(num).toString();
  const endsWithOneDecimals = new RegExp(/\.\d{1}$/);
  const endsWithTwoDecimals = new RegExp(/\.\d{2}$/);
  if (endsWithOneDecimals.test(money)) {
    money += '0';
  } else if (!endsWithTwoDecimals.test(money)) {
    money += '.00';
  }

  return `$${money}`;
};

export const sortCartItems = (item1: tempChargeValType, item2: tempChargeValType) => {
  if (item1.configurationType === 'InternetSpeed') return -1;

  if (item2.configurationType === 'InternetSpeed') return 1;

  if (item1.standardName === 'COMM_CDV_TIER') return -1;

  if (item2.standardName === 'COMM_CDV_TIER') return 1;

  if (item1.standardName === 'COMM_VID_TIER') return -1;

  if (item2.standardName === 'COMM_VID_TIER') return 1;

  if (item1.configurationType > item2.configurationType) return -1;

  if (item1.configurationType < item2.configurationType) return 1;

  if (item1.groupSequence > item2.groupSequence) return 1;
  else return -1;
};

import Content from '../../interfaces/api/content';
import Initialize from '../../interfaces/api/initialize';
import { getConfig } from '../../utility/env';
import { sharedConfig } from '../../config/shared/config';

export default function derivedDataTerms<T extends Content, U extends Initialize>({
  contentData,
  initializeData,
}: {
  contentData: T;
  initializeData: U;
}) {
  try {
    const env = getConfig();
    const { contact } = initializeData;
    const { firstName, lastName, emailAddress } = contact;
    const mainTitle = contentData?.pages?.accountRegistration?.pageTitle;
    const accountSetup = contentData?.pages?.accountRegistration?.accountSetup;
    const temp = contentData?.pages?.accountRegistration?.accountSetup?.description.split('{{inviteUserLink}}');
    const setupTextOne = temp ? temp[0] : '';
    const setUpTextTwo = temp ? temp[1] : '';
    const selfRegistration = contentData?.pages?.accountRegistration?.selfRegistration;
    const inviteUserRegistration = contentData?.pages?.accountRegistration?.inviteUserRegistration;
    const orderCompleteButton = contentData?.pages?.accountRegistration?.orderCompleteButton;
    const termsOfServiceTemp =
      contentData.pages.accountRegistration.selfRegistration.termsOfService.split('{{termsOfServiceLink}}');
    const termsOfServiceText = termsOfServiceTemp ? termsOfServiceTemp[0] : '';
    const termsOfServiceLink = {
      text: contentData.pages.accountRegistration.selfRegistration.termsOfServiceLink.text,
      href: `${env?.myAccount?.baseUrl}${sharedConfig?.frictionLess?.routes?.termsOfServiceUrl}`
    }

    return {
      mainTitle,
      accountSetup,
      setupTextOne,
      setUpTextTwo,
      selfRegistration,
      inviteUserRegistration,
      orderCompleteButton,
      firstName,
      lastName,
      emailAddress,
      termsOfServiceText,
      termsOfServiceLink,
    };
  } catch (err) {
    return null;
  }
}

import NavigationBar from '../../components/navigation-bar';
import { pageConfirmation } from '../../utility/global-constants/pages';
import Container from '../../components/container';
import { PrismButton, PrismPageHeading, PrismSectionHeading, PrismText } from '@prism-ui/react';
import './style.css';
import { useGetCustomerAndContentData, useGetOnboardingUrl } from '../../hooks/dml/documents.hooks';
import derivedDataConfirmation from './derivedData';
import autoPayDerivedDataConfirmation from './autoPayDerivedData';
import MyAccountLink from './myAccountLink';
import NavigationSection from './navigationDetails';
import SalesRepInfo from './salesRepInfo';
import AddressInfo from './addressInfo';
import { getConfig } from '../../utility/env';
import { useEffect, useContext } from 'react';
import AccountRegistrationContext from '../../context/AccountRegistration';
import PrimaryManagerContext from '../../context/primaryManager';
import { Redirect } from 'react-router-dom';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import TrackingData from '../../components/experienceTracking/TrackingData';
import { pageViewUpdate } from '../../utility/updateDataLayer';
import { SessionContext } from '../../App';
import UserAgentContext from '../../context/userAgent';
import AutoPayconfirmation from './autoPayConfirmation';
import { registrationStatusValue, registrationTypeValue } from '../../utility/global-constants/registrationStates';

export default function Confirmation() {
  const { isLoading, isError, initializeData, contentData } = useGetCustomerAndContentData();
  const { registrationState } = useContext(AccountRegistrationContext);
  const { primaryManager } = useContext(PrimaryManagerContext);
  const { oppid, sourcesystem, id: sessionId } = useContext(SessionContext);
  const isUserAgent = useContext(UserAgentContext);
  const env = getConfig();
  const isAutoPayConfirmationPage =
    initializeData?.featureEnableEcoBill &&
    ((registrationState.type === registrationTypeValue.selfregistration &&
      registrationState.status === registrationStatusValue.success) ||
      registrationState.type === registrationTypeValue.linkAccount);

  var autoPayData;
  var data;
  var isOnboardingUrlRequired = !isUserAgent && registrationState.type !== registrationTypeValue.signNow;

  const { onboardingUrl, isLoading: isOnboardingUrlLoading } = useGetOnboardingUrl(
    sessionId,
    isOnboardingUrlRequired,
    env
  );

  if (isAutoPayConfirmationPage) {
    autoPayData = autoPayDerivedDataConfirmation({
      contentData,
      initializeData,
      registrationState,
      primaryManager,
      onboardingUrl,
    });
  } else {
    data = derivedDataConfirmation({ contentData, initializeData, registrationState, primaryManager, onboardingUrl });
  }

  var registrationUrl = !isUserAgent ? data?.registrationUrl : '';

  let page;

  useEffect(() => {
    document.title = 'Order Complete';
    pageViewUpdate();
    neutralizeBack(() => {});
    window.scrollTo(0, 0);
  }, []);

  const neutralizeBack = (callback: any) => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  let dashboardUrl = sourcesystem === 'EC' ? env.signNow.confirmation.existing : env.signNow.confirmation.new;
  dashboardUrl = !isUserAgent ? dashboardUrl.replace('<opp_id>', oppid) : '';

  let targetUrl = sourcesystem === 'EC' && oppid!=='' ? "_self":"_blank";

  if (isError) return <Redirect to="/error" />;

  if (data || autoPayData) {
    const pageData = isAutoPayConfirmationPage ? autoPayData : data;
    page = {
      pageInfo: {
        attributes: {
          pageLoadEvent : pageData?.pageLoadEventValue
        },
        screenName: pageData?.screenName,
      },
    };
  }

  return (
    <div>
      <NavigationBar page={pageConfirmation.name} />
      <div>
        {isAutoPayConfirmationPage && autoPayData ? (
          <AutoPayconfirmation
            autoPayData={autoPayData}
            registrationState={registrationState}
            isUserAgent={isUserAgent}
            isOnboardingUrlLoading={isOnboardingUrlLoading}
          />
        ) : (
          <div>
            <Container>
              {(!data || isLoading || isOnboardingUrlLoading) && <LoadingIndicator />}
              {data && (
                <>
                  <div>
                    <PrismSectionHeading heading={data.title} description={data.description} />
                  </div>
                  <div>
                    <PrismPageHeading heading={data.accountTitle} description={data.accountDescription} />
                  </div>

                  {data?.billPaymentTitle && data.servicesTitle && data.notificationsTitle && (
                    <div className="account-management-container">
                      <MyAccountLink
                        iconName={`${env.content.baseUrl}/images/icons/Configure.svg`}
                        title={data.servicesTitle}
                        description={data.servicesDescription}
                      ></MyAccountLink>
                      <MyAccountLink
                        iconName={`${env.content.baseUrl}/images/icons/Autopay.svg`}
                        title={data.billPaymentTitle}
                        description={data.billPaymentDescription}
                      ></MyAccountLink>
                      <MyAccountLink
                        iconName={`${env.content.baseUrl}/images/icons/Bell.svg`}
                        title={data.notificationsTitle}
                        description={data.notificationsDescription}
                      ></MyAccountLink>
                    </div>
                  )}

                  {data?.myAccountButton && (
                    <div slot="bar-actions" className="confirm-button">
                      <PrismButton disabled={isUserAgent} display="fill" target="_blank" href={registrationUrl}>
                        {data.myAccountButton}
                      </PrismButton>
                    </div>
                  )}

                  {data?.contactUs && (
                    <>
                      <div className="confirmation-rule"></div>
                      <div className="confirmation-info">
                        <SalesRepInfo contactUS={data.contactUs}></SalesRepInfo>
                      </div>
                    </>
                  )}

                  <div className="confirmation-rule"></div>
                  <div className="account-address-container">
                    <AddressInfo
                      accountLinkTitle={data.accountLinkTitle}
                      firstName={data.firstName}
                      lastName={data.lastName}
                      address={data.billingAddress}
                    ></AddressInfo>
                    <AddressInfo
                      accountLinkTitle={data.addressTitle}
                      firstName={''}
                      lastName={''}
                      address={data.serviceAddress}
                    ></AddressInfo>
                  </div>
                  {data?.dashboardbutton && (
                    <>
                      <div className="confirmation-rule"></div>
                      <div slot="bar-actions" className="dashboard-button">
                        <PrismButton disabled={isUserAgent} display="fill" target={targetUrl} href={dashboardUrl}>
                          {data?.dashboardbutton}
                        </PrismButton>
                      </div>
                    </>
                  )}
                </>
              )}
            </Container>
            {data?.navigation && (
              <>
                <div className="confirmation-rule"></div>
                <div className="confirmation-navigation-container-header">
                  <PrismText display={'heading4'}>{data.navigation.title}</PrismText>
                </div>
                <div className="confirmation-navigation-container">
                  <NavigationSection
                    image={`${env.content.baseUrl}/images/navigation1.png`}
                    title={data.navigation.home.title}
                    description={data.navigation.home.description}
                    label={data.navigation.home.homePageLink.text}
                    link={data.navigation.home.homePageLink.href.replace('{{baseURL}}', env.myAccount.baseUrl)}
                  ></NavigationSection>
                  <NavigationSection
                    image={`${env.content.baseUrl}/images/navigation2.png`}
                    title={data.navigation.support.title}
                    description={data.navigation.support.description}
                    label={data.navigation.support.homePageLink.text}
                    link={data.navigation.support.homePageLink.href.replace('{{baseURL}}', env.myAccount.baseUrl)}
                  ></NavigationSection>
                  <NavigationSection
                    image={`${env.content.baseUrl}/images/navigation3.png`}
                    title={data.navigation.forums.title}
                    description={data.navigation.forums.description}
                    label={data.navigation.forums.homePageLink.text}
                    link={data.navigation.forums.homePageLink.href.replace('{{baseURL}}', env.myAccount.baseUrl)}
                  ></NavigationSection>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <TrackingData type="page" trackingInfo={page} />
    </div>
  );
}

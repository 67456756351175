function SvgSpriteMap() {
  const mapStyles = {
    display: 'none',
  };
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" style={mapStyles}>
        <symbol id="visa-white" viewBox="0 0 58 38" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect fill="#FFFFFF" x="0" y="0" width="100%" height="100%" rx="5"></rect>
              <g>
                <g transform="translate(8.000000, 12.000000)">
                  <g transform="translate(29.280000, 0.237976)">
                    <path
                      d="M7.97054975,8.38943675 C7.63901475,6.8000055 7.31190225,5.23192425 6.984866,3.66376675 C6.97152225,3.66117425 6.9581785,3.65858175 6.94483475,3.65598925 C6.374866,5.22795925 5.80504975,6.8000055 5.22875225,8.38943675 L7.97054975,8.38943675 Z M12.0419185,12.9764843 L8.93114725,12.9764843 C8.817306,12.429543 8.69522975,11.8944968 8.59884975,11.3548755 C8.55889475,11.130853 8.48721975,11.0440043 8.23727225,11.0472068 C6.98997475,11.0632955 5.74221975,11.0594068 4.494846,11.0494943 C4.30597475,11.0479693 4.23094475,11.1103417 4.17345225,11.2822855 C4.001661,11.7965918 3.81370475,12.3055605 3.62140225,12.812623 C3.59532475,12.8814005 3.508781,12.975798 3.449611,12.9763318 C2.3252285,12.9860918 1.20076975,12.9835755 0.04390475,12.9835755 C0.254736,12.475903 0.4543585,11.9911055 0.657031,11.5076805 C2.10677225,8.04859925 3.5571235,4.58989925 5.0073985,1.131123 C5.32147225,0.3818905 5.792316,0.0681641111 6.60231975,0.0681641111 C7.50496725,0.0681641111 8.40761475,0.0681641111 9.333366,0.0681641111 C9.423646,0.4898605 9.514536,0.905423 9.601461,1.32182425 C10.314856,4.7400355 11.0265735,8.158628 11.7417223,11.576458 C11.8332985,12.0142855 11.941421,12.4486818 12.0419185,12.884603 L12.0419185,12.9764843 Z"
                      fill="#231F20"
                    ></path>
                  </g>
                  <g transform="translate(19.901250, 0.009226)">
                    <path
                      d="M7.86897713,0.067092375 C8.27950713,0.143266125 8.69316338,0.205943625 9.09957588,0.299731125 C9.47053213,0.385283625 9.83371087,0.504538625 10.2279234,0.616778625 C10.0292159,1.54603737 9.83523587,2.45287863 9.64544963,3.34019988 C9.12542463,3.18769988 8.62827463,3.01201987 8.11732337,2.89932238 C7.41841588,2.74499237 6.70227588,2.68620363 6.00306338,2.88102237 C5.71887963,2.96024613 5.43111213,3.11236488 5.20739463,3.30405737 C4.82362837,3.63292363 4.81844338,4.10628362 5.18512963,4.45154363 C5.43782212,4.68959613 5.73748463,4.88502488 6.03684212,5.06505113 C6.71744963,5.47436113 7.43214088,5.83029612 8.09353337,6.26743738 C8.87257962,6.78235362 9.44071838,7.49033487 9.56004962,8.44742488 C9.77103338,10.1402511 9.14494462,11.4737111 7.72295838,12.4170761 C6.82877463,13.0102249 5.81693713,13.2751936 4.76255213,13.3733274 C3.29024088,13.5103486 1.84713337,13.3454199 0.440473375,12.8832686 C0.346228375,12.8523111 0.254347125,12.8136524 0.162465875,12.7760611 C0.135015875,12.7647761 0.111149625,12.7449511 0.069059625,12.7187211 C0.267767125,11.7881661 0.466779625,10.8561624 0.671358375,9.89815738 C0.911164625,10.0028486 1.13625463,10.1077686 1.36630088,10.2004124 C2.46529213,10.6432724 3.60027338,10.8457924 4.77650587,10.6255061 C5.08257338,10.5681661 5.39466463,10.4329749 5.65406713,10.2591249 C6.21694463,9.88176363 6.27092962,9.17263862 5.75837713,8.72939738 C5.42150462,8.43812238 5.01661712,8.22294488 4.63277463,7.98908612 C4.03322088,7.62392488 3.39638088,7.31267238 2.82900462,6.90397237 C2.10180838,6.38005863 1.57514962,5.68153238 1.47526213,4.75303612 C1.32573588,3.36292238 1.84164337,2.23404113 2.90876212,1.35693737 C3.83725838,0.593827375 4.93960463,0.255124875 6.11393088,0.112918625 C6.17927712,0.104988625 6.24279338,0.082647375 6.30707213,0.067092375 L7.86897713,0.067092375 Z"
                      fill="#231F20"
                    ></path>
                  </g>
                  <g transform="translate(0.000000, 0.237976)">
                    <path
                      d="M15.6733934,0.07870525 C15.4986284,0.499224 15.3331659,0.90007025 15.1654921,1.300154 C13.5645471,5.11928775 11.9615434,8.937659 10.3671559,12.759614 C10.2911346,12.9416227 10.1981859,12.9894315 10.0135084,12.9879827 C9.00311963,12.979519 7.99250213,12.976164 6.98218963,12.9902746 C6.76800338,12.9930915 6.69815838,12.9180615 6.64882463,12.7258352 C5.79215588,9.38761025 4.92122838,6.052969 4.07431963,2.71222775 C3.91892213,2.0994065 3.60789838,1.680794 3.02450963,1.42390775 C2.08945588,1.0120815 1.11871713,0.722789 0.128534625,0.48771025 C0.084767125,0.47734025 0.041152125,0.46590275 -2.2875e-05,0.455609 C0.004933375,0.06818275 0.004933375,0.06818275 0.373449625,0.06818275 C2.14183963,0.06818275 3.91022962,0.06772525 5.67854338,0.06833525 C6.51729338,0.06864025 7.12279463,0.55587775 7.28131838,1.38608775 C7.73027838,3.7363415 8.16963088,6.088349 8.61310088,8.439594 C8.63246838,8.54245525 8.65458088,8.64478275 8.68393713,8.7895815 C8.72983963,8.70334275 8.76178838,8.65500025 8.78268088,8.602159 C9.88106213,5.82414275 10.9796721,3.046279 12.0724109,0.2660515 C12.1321146,0.11423775 12.2056196,0.062769 12.3694809,0.0641148661 C13.3952721,0.072224 14.4210634,0.067954 15.4469309,0.068564 C15.5131921,0.068564 15.5795296,0.07428275 15.6733934,0.07870525"
                      fill="#231F20"
                    ></path>
                  </g>
                  <path
                    d="M14.3341841,13.2219178 C15.2580291,8.9045665 16.1753166,4.6174865 17.0949679,0.31996025 L20.4456216,0.31996025 C20.3061604,0.97449025 20.1697491,1.61651525 20.0324991,2.25838775 C19.2734304,5.80813025 18.5114641,9.3571865 17.7607066,12.908759 C17.7085516,13.1554278 17.6210929,13.2338128 17.3650454,13.2288565 C16.4467666,13.2111665 15.5279541,13.2219178 14.6093704,13.2219178 L14.3341841,13.2219178 Z"
                    fill="#231F20"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </symbol>
        <symbol id="amazon-white" viewBox="0 0 244 154" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <path
                d="M0.804,141.275 L0.804,12.274 C0.844,5.634 6.163,0.314 12.804,0.274 L231.804,0.274 C238.446,0.314 243.764,5.634 243.804,12.274 L243.804,141.275 C243.764,147.917 238.446,153.234 231.804,153.275 L12.804,153.275 C6.163,153.234 0.845,147.917 0.804,141.275"
                id="Fill-1"
                fill="#FFFFFE"
              ></path>
              <path
                d="M180.985,80.204 C165.167,91.885 142.215,98.092 122.447,98.092 C94.747,98.092 69.828,87.863 50.95,70.798 C49.453,69.479 50.785,67.651 52.563,68.678 C72.926,80.533 98.113,87.682 124.101,87.682 C141.656,87.682 160.939,84.039 178.688,76.496 C181.366,75.368 183.612,78.276 180.985,80.204"
                id="Fill-2"
                fill="#EC9534"
              ></path>
              <path
                d="M187.586,72.679 C185.555,70.087 174.193,71.46 169.091,72.067 C167.541,72.245 167.302,70.889 168.694,69.924 C177.761,63.562 192.625,65.404 194.328,67.522 C196.08,69.68 193.882,84.56 185.401,91.653 C184.083,92.745 182.84,92.189 183.423,90.732 C185.315,85.945 189.607,75.267 187.586,72.679"
                id="Fill-3"
                fill="#EC9534"
              ></path>
              <path
                d="M210.965,25.849 C207.98,28.051 203.653,29.223 199.927,29.223 C194.704,29.223 190,27.293 186.44,24.078 C186.161,23.825 186.411,23.479 186.746,23.677 C190.586,25.911 195.334,27.257 200.24,27.257 C203.548,27.257 207.186,26.571 210.533,25.151 C211.037,24.937 211.46,25.484 210.965,25.849"
                id="Fill-4"
                fill="#EC9534"
              ></path>
              <path
                d="M212.208,24.43 C211.826,23.941 209.684,24.199 208.722,24.313 C208.43,24.349 208.387,24.095 208.648,23.909 C210.357,22.709 213.157,23.056 213.48,23.458 C213.811,23.862 213.396,26.67 211.795,28.011 C211.548,28.216 211.316,28.106 211.423,27.834 C211.784,26.935 212.593,24.92 212.208,24.43"
                id="Fill-5"
                fill="#EC9534"
              ></path>
              <path
                d="M208.79,15.432 L208.79,14.265 C208.79,14.087 208.923,13.969 209.086,13.969 L214.313,13.969 C214.479,13.969 214.612,14.091 214.614,14.263 L214.612,15.265 C214.612,15.432 214.471,15.649 214.221,15.998 L211.514,19.863 C212.518,19.839 213.581,19.989 214.494,20.504 C214.702,20.618 214.755,20.791 214.772,20.958 L214.772,22.203 C214.772,22.374 214.584,22.573 214.387,22.47 C212.778,21.626 210.643,21.536 208.865,22.479 C208.682,22.577 208.492,22.382 208.492,22.209 L208.492,21.026 C208.492,20.838 208.494,20.514 208.687,20.226 L211.824,15.725 L209.091,15.725 C208.925,15.725 208.79,15.606 208.79,15.432"
                id="Fill-6"
                fill="#1C1D1B"
              ></path>
              <path
                d="M189.723,22.715 L188.134,22.715 C187.983,22.705 187.86,22.591 187.849,22.447 L187.849,14.285 C187.849,14.12 187.988,13.99 188.155,13.99 L189.638,13.99 C189.794,13.998 189.917,14.115 189.927,14.265 L189.927,15.33 L189.957,15.33 C190.342,14.299 191.07,13.818 192.05,13.818 C193.042,13.818 193.667,14.299 194.111,15.33 C194.498,14.299 195.375,13.818 196.31,13.818 C196.977,13.818 197.707,14.094 198.149,14.713 C198.656,15.398 198.551,16.396 198.551,17.271 L198.55,22.422 C198.55,22.584 198.413,22.715 198.244,22.715 L196.657,22.715 C196.495,22.705 196.37,22.578 196.37,22.422 L196.37,18.095 C196.37,17.751 196.397,16.894 196.325,16.566 C196.206,16.018 195.849,15.862 195.389,15.862 C195.003,15.862 194.603,16.119 194.439,16.531 C194.276,16.945 194.292,17.631 194.292,18.095 L194.292,22.422 C194.292,22.584 194.155,22.715 193.984,22.715 L192.397,22.715 C192.236,22.705 192.111,22.578 192.111,22.422 L192.107,18.095 C192.107,17.185 192.258,15.846 191.129,15.846 C189.987,15.846 190.029,17.15 190.029,18.095 L190.029,22.422 C190.029,22.584 189.893,22.715 189.723,22.715"
                id="Fill-7"
                fill="#1C1D1B"
              ></path>
              <path
                d="M219.129,15.484 C217.956,15.484 217.880992,17.082 217.880992,18.078 C217.880992,19.074 217.866,21.203 219.112,21.203 C220.346,21.203 220.405,19.486 220.405,18.438 C220.405,17.751 220.375,16.928 220.167,16.275 C219.989,15.708 219.634,15.484 219.129,15.484 M219.112,13.818 C221.474,13.818 222.751,15.846 222.751,18.421 C222.751,20.911 221.34,22.888 219.112,22.888 C216.8,22.888 215.537,20.86 215.537,18.336 C215.537,15.795 216.813,13.818 219.112,13.818"
                id="Fill-8"
                fill="#1C1D1B"
              ></path>
              <path
                d="M225.812,22.715 L224.229,22.715 C224.069,22.705 223.94,22.578 223.94,22.422 L223.939,14.257 C223.952,14.107 224.084,13.99 224.244,13.99 L225.721,13.99 C225.857,13.998 225.974,14.093 226,14.219 L226,15.468 L226.032,15.468 C226.476,14.351 227.101,13.818 228.2,13.818 C228.912,13.818 229.609,14.077 230.056,14.78 C230.47,15.434 230.47,16.531 230.47,17.322 L230.47,22.459 C230.453,22.603 230.322,22.715 230.167,22.715 L228.571,22.715 C228.424,22.706 228.306,22.598 228.288,22.459 L228.288,18.026 C228.288,17.134 228.392,15.829 227.294,15.829 C226.907,15.829 226.55,16.085 226.374,16.48 C226.151,16.979 226.12,17.477 226.12,18.026 L226.12,22.422 C226.118,22.584 225.982,22.715 225.812,22.715"
                id="Fill-9"
                fill="#1C1D1B"
              ></path>
              <path
                d="M206.226,22.693 C206.122,22.788 205.971,22.796 205.851,22.73 C205.322,22.293 205.229,22.091 204.939,21.672 C204.066,22.562 203.448,22.828 202.317,22.828 C200.978,22.828 199.937,22.001 199.937,20.349 C199.937,19.058 200.636,18.181 201.632,17.75 C202.496,17.372 203.702,17.303 204.623,17.199 L204.623,16.993 C204.623,16.614 204.655,16.167 204.43,15.841 C204.235,15.547 203.864,15.428 203.537,15.428 C202.931,15.428 202.39,15.737 202.26,16.382 C202.231,16.524 202.126,16.666 201.983,16.674 L200.442,16.507 C200.312,16.478 200.168,16.374 200.204,16.175 C200.56,14.305 202.248,13.739 203.762,13.739 C204.535,13.739 205.547,13.946 206.155,14.532 C206.928,15.254 206.854,16.219 206.854,17.269 L206.854,19.746 C206.854,20.49 207.164,20.817 207.455,21.219 C207.557,21.363 207.58,21.537 207.45,21.642 C207.125,21.914 206.548,22.416 206.23,22.697 L206.226,22.693 Z M204.623,18.818 C204.623,19.437 204.639,19.954 204.326,20.505 C204.074,20.951 203.671,21.227 203.223,21.227 C202.616,21.227 202.257,20.763 202.257,20.073 C202.257,18.72 203.475,18.473 204.623,18.473 L204.623,18.818 L204.623,18.818 Z"
                id="Fill-10"
                fill="#1C1D1B"
              ></path>
              <path
                d="M185.381,22.693 C185.277,22.788 185.123,22.796 185.005,22.73 C184.477,22.293 184.383,22.091 184.093,21.672 C183.222,22.562 182.601,22.828 181.473,22.828 C180.133,22.828 179.091,22.001 179.091,20.349 C179.091,19.058 179.789,18.181 180.787,17.75 C181.65,17.372 182.854,17.303 183.778,17.199 L183.778,16.993 C183.778,16.614 183.807,16.167 183.585,15.841 C183.39,15.547 183.019,15.428 182.692,15.428 C182.085,15.428 181.545,15.737 181.414,16.382 C181.386,16.524 181.281,16.666 181.137,16.674 L179.596,16.507 C179.465,16.478 179.321,16.374 179.359,16.175 C179.713,14.305 181.401,13.739 182.913,13.739 C183.688,13.739 184.699,13.946 185.31,14.532 C186.083,15.254 186.01,16.219 186.01,17.269 L186.01,19.746 C186.01,20.49 186.318,20.817 186.61,21.219 C186.709,21.363 186.734,21.537 186.604,21.642 C186.28,21.914 185.702,22.416 185.384,22.697 L185.381,22.693 Z M183.778,18.818 C183.778,19.437 183.792,19.954 183.479,20.505 C183.227,20.951 182.825,21.227 182.378,21.227 C181.769,21.227 181.414,20.763 181.414,20.073 C181.414,18.72 182.627,18.473 183.778,18.473 L183.778,18.818 L183.778,18.818 Z"
                id="Fill-11"
                fill="#1C1D1B"
              ></path>
            </g>
          </g>
        </symbol>
        <symbol id="amazonprime-white" viewBox="0 0 149 93" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <g>
                <path
                  d="M14.573,37.109 C16.695,37.109 18.259,36.389 19.264,34.949 C20.268,33.508 20.771,31.234 20.771,28.127 C20.771,24.981 20.278,22.688 19.292,21.247 C18.306,19.807 16.734,19.087 14.573,19.087 C12.603,19.087 10.744,19.598 9.002,20.622 L9.002,35.574 C10.669,36.597 12.527,37.109 14.573,37.109 Z M2.123,54.449 C1.63,54.449 1.27,54.343 1.042,54.135 C0.816,53.927 0.701,53.557 0.701,53.027 L0.701,15.107 C0.701,14.577 0.816,14.208 1.042,13.999 C1.27,13.791 1.63,13.686 2.123,13.686 L6.216,13.686 C7.088,13.686 7.618,14.103 7.808,14.936 L8.206,16.415 C9.343,15.316 10.716,14.444 12.327,13.8 C13.939,13.156 15.577,12.833 17.245,12.833 C20.921,12.833 23.83,14.178 25.972,16.87 C28.114,19.561 29.185,23.2 29.185,27.785 C29.185,30.931 28.654,33.679 27.593,36.029 C26.532,38.38 25.091,40.189 23.272,41.458 C21.452,42.728 19.367,43.363 17.018,43.363 C15.463,43.363 13.986,43.116 12.583,42.624 C11.181,42.132 9.988,41.449 9.002,40.577 L9.002,53.027 C9.002,53.557 8.897,53.927 8.69,54.135 C8.481,54.343 8.111,54.449 7.58,54.449 L2.123,54.449 L2.123,54.449 Z"
                  fill="#fff"
                ></path>
                <path
                  d="M36.172,42.737 C35.679,42.737 35.319,42.624 35.091,42.396 C34.864,42.169 34.75,41.809 34.75,41.316 L34.75,15.107 C34.75,14.577 34.864,14.208 35.091,13.999 C35.319,13.791 35.679,13.686 36.172,13.686 L40.265,13.686 C41.137,13.686 41.667,14.103 41.857,14.936 L42.596,18.007 C44.112,16.34 45.542,15.155 46.888,14.454 C48.234,13.753 49.664,13.402 51.181,13.402 L51.977,13.402 C52.507,13.402 52.886,13.507 53.113,13.715 C53.341,13.923 53.454,14.293 53.454,14.823 L53.454,19.598 C53.454,20.092 53.35,20.451 53.142,20.678 C52.933,20.906 52.564,21.02 52.033,21.02 C51.768,21.02 51.427,21.001 51.01,20.963 C50.593,20.925 50.062,20.906 49.418,20.906 C48.546,20.906 47.486,21.03 46.235,21.276 C44.984,21.522 43.922,21.836 43.051,22.214 L43.051,41.316 C43.051,41.809 42.946,42.169 42.739,42.396 C42.53,42.624 42.159,42.737 41.629,42.737 L36.172,42.737"
                  fill="#fff"
                ></path>
                <path
                  d="M58.889,42.737 C58.397,42.737 58.036,42.624 57.809,42.396 C57.581,42.169 57.468,41.809 57.468,41.316 L57.468,15.107 C57.468,14.577 57.581,14.208 57.809,13.999 C58.036,13.791 58.397,13.686 58.889,13.686 L64.347,13.686 C64.877,13.686 65.247,13.791 65.455,13.999 C65.663,14.208 65.769,14.577 65.769,15.107 L65.769,41.316 C65.769,41.809 65.663,42.169 65.455,42.396 C65.247,42.624 64.877,42.737 64.347,42.737 L58.889,42.737 Z M61.618,9.195 C60.177,9.195 59.022,8.797 58.151,8.001 C57.278,7.205 56.843,6.125 56.843,4.76 C56.843,3.395 57.278,2.315 58.151,1.519 C59.022,0.723 60.177,0.326 61.618,0.326 C63.058,0.326 64.214,0.723 65.086,1.519 C65.957,2.315 66.394,3.395 66.394,4.76 C66.394,6.125 65.957,7.205 65.086,8.001 C64.214,8.797 63.058,9.195 61.618,9.195 L61.618,9.195 Z"
                  fill="#fff"
                ></path>
                <path
                  d="M74.229,42.737 C73.735,42.737 73.375,42.624 73.148,42.396 C72.921,42.169 72.807,41.809 72.807,41.316 L72.807,15.107 C72.807,14.577 72.921,14.208 73.148,13.999 C73.375,13.791 73.735,13.686 74.229,13.686 L78.322,13.686 C79.194,13.686 79.724,14.103 79.913,14.936 L80.368,16.471 C82.377,15.145 84.187,14.208 85.798,13.658 C87.408,13.108 89.067,12.833 90.773,12.833 C94.184,12.833 96.59,14.047 97.992,16.471 C99.925,15.183 101.744,14.255 103.45,13.686 C105.155,13.117 106.918,12.833 108.738,12.833 C111.39,12.833 113.446,13.572 114.905,15.05 C116.364,16.528 117.095,18.594 117.095,21.247 L117.095,41.316 C117.095,41.809 116.989,42.169 116.782,42.396 C116.573,42.624 116.203,42.737 115.673,42.737 L110.216,42.737 C109.723,42.737 109.363,42.624 109.136,42.396 C108.908,42.169 108.794,41.809 108.794,41.316 L108.794,23.066 C108.794,20.489 107.638,19.201 105.326,19.201 C103.279,19.201 101.213,19.693 99.129,20.678 L99.129,41.316 C99.129,41.809 99.025,42.169 98.817,42.396 C98.608,42.624 98.239,42.737 97.708,42.737 L92.25,42.737 C91.757,42.737 91.398,42.624 91.17,42.396 C90.943,42.169 90.829,41.809 90.829,41.316 L90.829,23.066 C90.829,20.489 89.672,19.201 87.362,19.201 C85.239,19.201 83.155,19.713 81.108,20.735 L81.108,41.316 C81.108,41.809 81.002,42.169 80.795,42.396 C80.586,42.624 80.216,42.737 79.686,42.737 L74.229,42.737"
                  fill="#fff"
                ></path>
                <g transform="translate(0.000000, 12.000000)">
                  <path
                    d="M134.946,13.739 C136.878,13.739 138.28,13.445 139.153,12.857 C140.024,12.27 140.46,11.37 140.46,10.157 C140.46,7.769 139.039,6.575 136.196,6.575 C132.558,6.575 130.454,8.812 129.886,13.284 C131.402,13.588 133.088,13.739 134.946,13.739 Z M136.821,31.59 C132.121,31.59 128.512,30.302 125.991,27.724 C123.47,25.147 122.211,21.452 122.211,16.638 C122.211,11.712 123.498,7.845 126.076,5.04 C128.654,2.236 132.236,0.833 136.821,0.833 C140.347,0.833 143.104,1.686 145.094,3.391 C147.083,5.097 148.078,7.371 148.078,10.214 C148.078,13.056 147.007,15.208 144.866,16.667 C142.725,18.126 139.57,18.855 135.401,18.855 C133.24,18.855 131.363,18.647 129.772,18.23 C129.999,20.769 130.767,22.589 132.074,23.687 C133.382,24.788 135.363,25.337 138.016,25.337 C139.076,25.337 140.11,25.27 141.114,25.137 C142.117,25.005 143.511,24.711 145.292,24.257 C145.406,24.218 145.52,24.19 145.634,24.171 C145.747,24.152 145.842,24.142 145.918,24.142 C146.562,24.142 146.884,24.579 146.884,25.45 L146.884,28.065 C146.884,28.672 146.799,29.098 146.629,29.344 C146.458,29.592 146.126,29.809 145.634,29.998 C142.866,31.059 139.929,31.59 136.821,31.59 L136.821,31.59 Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M133.847,62.674 C117.634,74.638 94.131,81 73.892,81 C45.528,81 19.985,70.515 0.655,53.062 C-0.862,51.691 0.491,49.82 2.316,50.881 C23.17,63.016 48.96,70.324 75.596,70.324 C93.564,70.324 113.316,66.595 131.49,58.886 C134.231,57.723 136.529,60.692 133.847,62.674"
                    fill="#fff"
                  ></path>
                  <path
                    d="M140.593,54.971 C138.519,52.317 126.887,53.714 121.66,54.341 C120.076,54.53 119.831,53.146 121.258,52.144 C130.538,45.627 145.743,47.508 147.511,49.691 C149.291,51.892 147.043,67.137 138.347,74.413 C137.009,75.531 135.737,74.934 136.33,73.457 C138.287,68.57 142.668,57.629 140.593,54.971"
                    fill="#fff"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </symbol>
        <symbol id="visa-black" viewBox="0 0 58 38" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect fill="#000000" x="0" y="0" width="100%" height="100%" rx="5"></rect>
              <g>
                <g transform="translate(8.000000, 12.000000)">
                  <g transform="translate(29.280000, 0.237976)">
                    <path
                      d="M7.97054975,8.38943675 C7.63901475,6.8000055 7.31190225,5.23192425 6.984866,3.66376675 C6.97152225,3.66117425 6.9581785,3.65858175 6.94483475,3.65598925 C6.374866,5.22795925 5.80504975,6.8000055 5.22875225,8.38943675 L7.97054975,8.38943675 Z M12.0419185,12.9764843 L8.93114725,12.9764843 C8.817306,12.429543 8.69522975,11.8944968 8.59884975,11.3548755 C8.55889475,11.130853 8.48721975,11.0440043 8.23727225,11.0472068 C6.98997475,11.0632955 5.74221975,11.0594068 4.494846,11.0494943 C4.30597475,11.0479693 4.23094475,11.1103417 4.17345225,11.2822855 C4.001661,11.7965918 3.81370475,12.3055605 3.62140225,12.812623 C3.59532475,12.8814005 3.508781,12.975798 3.449611,12.9763318 C2.3252285,12.9860918 1.20076975,12.9835755 0.04390475,12.9835755 C0.254736,12.475903 0.4543585,11.9911055 0.657031,11.5076805 C2.10677225,8.04859925 3.5571235,4.58989925 5.0073985,1.131123 C5.32147225,0.3818905 5.792316,0.0681641111 6.60231975,0.0681641111 C7.50496725,0.0681641111 8.40761475,0.0681641111 9.333366,0.0681641111 C9.423646,0.4898605 9.514536,0.905423 9.601461,1.32182425 C10.314856,4.7400355 11.0265735,8.158628 11.7417223,11.576458 C11.8332985,12.0142855 11.941421,12.4486818 12.0419185,12.884603 L12.0419185,12.9764843 Z"
                      fill="#fff"
                    ></path>
                  </g>
                  <g transform="translate(19.901250, 0.009226)">
                    <path
                      d="M7.86897713,0.067092375 C8.27950713,0.143266125 8.69316338,0.205943625 9.09957588,0.299731125 C9.47053213,0.385283625 9.83371087,0.504538625 10.2279234,0.616778625 C10.0292159,1.54603737 9.83523587,2.45287863 9.64544963,3.34019988 C9.12542463,3.18769988 8.62827463,3.01201987 8.11732337,2.89932238 C7.41841588,2.74499237 6.70227588,2.68620363 6.00306338,2.88102237 C5.71887963,2.96024613 5.43111213,3.11236488 5.20739463,3.30405737 C4.82362837,3.63292363 4.81844338,4.10628362 5.18512963,4.45154363 C5.43782212,4.68959613 5.73748463,4.88502488 6.03684212,5.06505113 C6.71744963,5.47436113 7.43214088,5.83029612 8.09353337,6.26743738 C8.87257962,6.78235362 9.44071838,7.49033487 9.56004962,8.44742488 C9.77103338,10.1402511 9.14494462,11.4737111 7.72295838,12.4170761 C6.82877463,13.0102249 5.81693713,13.2751936 4.76255213,13.3733274 C3.29024088,13.5103486 1.84713337,13.3454199 0.440473375,12.8832686 C0.346228375,12.8523111 0.254347125,12.8136524 0.162465875,12.7760611 C0.135015875,12.7647761 0.111149625,12.7449511 0.069059625,12.7187211 C0.267767125,11.7881661 0.466779625,10.8561624 0.671358375,9.89815738 C0.911164625,10.0028486 1.13625463,10.1077686 1.36630088,10.2004124 C2.46529213,10.6432724 3.60027338,10.8457924 4.77650587,10.6255061 C5.08257338,10.5681661 5.39466463,10.4329749 5.65406713,10.2591249 C6.21694463,9.88176363 6.27092962,9.17263862 5.75837713,8.72939738 C5.42150462,8.43812238 5.01661712,8.22294488 4.63277463,7.98908612 C4.03322088,7.62392488 3.39638088,7.31267238 2.82900462,6.90397237 C2.10180838,6.38005863 1.57514962,5.68153238 1.47526213,4.75303612 C1.32573588,3.36292238 1.84164337,2.23404113 2.90876212,1.35693737 C3.83725838,0.593827375 4.93960463,0.255124875 6.11393088,0.112918625 C6.17927712,0.104988625 6.24279338,0.082647375 6.30707213,0.067092375 L7.86897713,0.067092375 Z"
                      fill="#fff"
                    ></path>
                  </g>
                  <g transform="translate(0.000000, 0.237976)">
                    <path
                      d="M15.6733934,0.07870525 C15.4986284,0.499224 15.3331659,0.90007025 15.1654921,1.300154 C13.5645471,5.11928775 11.9615434,8.937659 10.3671559,12.759614 C10.2911346,12.9416227 10.1981859,12.9894315 10.0135084,12.9879827 C9.00311963,12.979519 7.99250213,12.976164 6.98218963,12.9902746 C6.76800338,12.9930915 6.69815838,12.9180615 6.64882463,12.7258352 C5.79215588,9.38761025 4.92122838,6.052969 4.07431963,2.71222775 C3.91892213,2.0994065 3.60789838,1.680794 3.02450963,1.42390775 C2.08945588,1.0120815 1.11871713,0.722789 0.128534625,0.48771025 C0.084767125,0.47734025 0.041152125,0.46590275 -2.2875e-05,0.455609 C0.004933375,0.06818275 0.004933375,0.06818275 0.373449625,0.06818275 C2.14183963,0.06818275 3.91022962,0.06772525 5.67854338,0.06833525 C6.51729338,0.06864025 7.12279463,0.55587775 7.28131838,1.38608775 C7.73027838,3.7363415 8.16963088,6.088349 8.61310088,8.439594 C8.63246838,8.54245525 8.65458088,8.64478275 8.68393713,8.7895815 C8.72983963,8.70334275 8.76178838,8.65500025 8.78268088,8.602159 C9.88106213,5.82414275 10.9796721,3.046279 12.0724109,0.2660515 C12.1321146,0.11423775 12.2056196,0.062769 12.3694809,0.0641148661 C13.3952721,0.072224 14.4210634,0.067954 15.4469309,0.068564 C15.5131921,0.068564 15.5795296,0.07428275 15.6733934,0.07870525"
                      fill="#fff"
                    ></path>
                  </g>
                  <path
                    d="M14.3341841,13.2219178 C15.2580291,8.9045665 16.1753166,4.6174865 17.0949679,0.31996025 L20.4456216,0.31996025 C20.3061604,0.97449025 20.1697491,1.61651525 20.0324991,2.25838775 C19.2734304,5.80813025 18.5114641,9.3571865 17.7607066,12.908759 C17.7085516,13.1554278 17.6210929,13.2338128 17.3650454,13.2288565 C16.4467666,13.2111665 15.5279541,13.2219178 14.6093704,13.2219178 L14.3341841,13.2219178 Z"
                    fill="#fff"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </symbol>
        <symbol id="amazon-black" viewBox="0 0 244 154" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <path
                d="M0.804,141.275 L0.804,12.275 C0.844,5.634 6.163,0.315 12.804,0.275 L231.804,0.275 C238.446,0.315 243.764,5.634 243.804,12.275 L243.804,141.275 C243.764,147.917 238.446,153.235 231.804,153.275 L12.804,153.275 C6.163,153.235 0.845,147.917 0.804,141.275"
                fill="#1C1D1B"
              ></path>
              <path
                d="M180.985,80.204 C165.167,91.885 142.215,98.092 122.447,98.092 C94.747,98.092 69.828,87.863 50.95,70.798 C49.453,69.478 50.785,67.65 52.563,68.678 C72.926,80.533 98.113,87.682 124.101,87.682 C141.656,87.682 160.939,84.039 178.688,76.496 C181.366,75.368 183.612,78.275 180.985,80.204"
                fill="#EC9534"
              ></path>
              <path
                d="M187.586,72.679 C185.555,70.087 174.193,71.46 169.091,72.067 C167.541,72.245 167.302,70.888 168.694,69.924 C177.761,63.561 192.625,65.404 194.328,67.521 C196.08,69.68 193.882,84.56 185.401,91.653 C184.083,92.746 182.84,92.188 183.423,90.731 C185.315,85.945 189.607,75.266 187.586,72.679"
                fill="#EC9534"
              ></path>
              <path
                d="M210.965,25.849 C207.98,28.051 203.653,29.223 199.927,29.223 C194.704,29.223 190,27.293 186.44,24.078 C186.161,23.826 186.411,23.48 186.746,23.676 C190.586,25.912 195.334,27.257 200.24,27.257 C203.548,27.257 207.186,26.571 210.533,25.151 C211.037,24.937 211.46,25.484 210.965,25.849"
                fill="#EC9534"
              ></path>
              <path
                d="M212.208,24.43 C211.826,23.941 209.684,24.199 208.722,24.314 C208.43,24.348 208.387,24.094 208.648,23.91 C210.357,22.709 213.157,23.056 213.48,23.458 C213.811,23.862 213.396,26.67 211.795,28.011 C211.548,28.216 211.316,28.106 211.423,27.833 C211.784,26.934 212.593,24.92 212.208,24.43"
                fill="#EC9534"
              ></path>
              <path
                d="M208.79,15.432 L208.79,14.264 C208.79,14.087 208.923,13.968 209.086,13.968 L214.313,13.968 C214.479,13.968 214.612,14.091 214.614,14.263 L214.612,15.264 C214.612,15.432 214.471,15.649 214.221,15.998 L211.514,19.863 C212.518,19.839 213.581,19.99 214.494,20.504 C214.702,20.619 214.755,20.79 214.772,20.958 L214.772,22.203 C214.772,22.374 214.584,22.573 214.387,22.47 C212.778,21.626 210.643,21.536 208.865,22.48 C208.682,22.577 208.492,22.381 208.492,22.209 L208.492,21.027 C208.492,20.838 208.494,20.514 208.687,20.226 L211.824,15.725 L209.091,15.725 C208.925,15.725 208.79,15.606 208.79,15.432"
                fill="#FFFFFE"
              ></path>
              <path
                d="M189.723,22.715 L188.134,22.715 C187.983,22.705 187.86,22.591 187.849,22.447 L187.849,14.285 C187.849,14.121 187.988,13.99 188.155,13.99 L189.638,13.99 C189.794,13.998 189.917,14.116 189.927,14.265 L189.927,15.33 L189.957,15.33 C190.342,14.299 191.07,13.818 192.05,13.818 C193.042,13.818 193.667,14.299 194.111,15.33 C194.498,14.299 195.375,13.818 196.31,13.818 C196.977,13.818 197.707,14.094 198.149,14.712 C198.656,15.399 198.551,16.395 198.551,17.27 L198.55,22.421 C198.55,22.583 198.413,22.715 198.244,22.715 L196.657,22.715 C196.495,22.705 196.37,22.579 196.37,22.421 L196.37,18.094 C196.37,17.751 196.397,16.894 196.325,16.566 C196.206,16.018 195.849,15.862 195.389,15.862 C195.003,15.862 194.603,16.12 194.439,16.532 C194.276,16.945 194.292,17.631 194.292,18.094 L194.292,22.421 C194.292,22.583 194.155,22.715 193.984,22.715 L192.397,22.715 C192.236,22.705 192.111,22.579 192.111,22.421 L192.107,18.094 C192.107,17.185 192.258,15.845 191.129,15.845 C189.987,15.845 190.029,17.15 190.029,18.094 L190.029,22.421 C190.029,22.583 189.893,22.715 189.723,22.715"
                fill="#FFFFFE"
              ></path>
              <path
                d="M219.129,15.484 C217.956,15.484 217.880992,17.082 217.880992,18.078 C217.880992,19.074 217.866,21.204 219.112,21.204 C220.346,21.204 220.405,19.486 220.405,18.437 C220.405,17.751 220.375,16.927 220.167,16.275 C219.989,15.707 219.634,15.484 219.129,15.484 M219.112,13.818 C221.474,13.818 222.751,15.845 222.751,18.421 C222.751,20.911 221.34,22.887 219.112,22.887 C216.8,22.887 215.537,20.86 215.537,18.335 C215.537,15.794 216.813,13.818 219.112,13.818"
                fill="#FFFFFE"
              ></path>
              <path
                d="M225.812,22.715 L224.229,22.715 C224.069,22.705 223.94,22.579 223.94,22.421 L223.939,14.257 C223.952,14.108 224.084,13.99 224.244,13.99 L225.721,13.99 C225.857,13.998 225.974,14.093 226,14.219 L226,15.468 L226.032,15.468 C226.476,14.35 227.101,13.818 228.2,13.818 C228.912,13.818 229.609,14.077 230.056,14.78 C230.47,15.433 230.47,16.532 230.47,17.323 L230.47,22.458 C230.453,22.603 230.322,22.715 230.167,22.715 L228.571,22.715 C228.424,22.706 228.306,22.598 228.288,22.458 L228.288,18.027 C228.288,17.134 228.392,15.829 227.294,15.829 C226.907,15.829 226.55,16.085 226.374,16.48 C226.151,16.978 226.12,17.477 226.12,18.027 L226.12,22.421 C226.118,22.583 225.982,22.715 225.812,22.715"
                fill="#FFFFFE"
              ></path>
              <path
                d="M206.226,22.694 C206.122,22.788 205.971,22.796 205.851,22.731 C205.322,22.293 205.229,22.09 204.939,21.672 C204.066,22.561 203.448,22.828 202.317,22.828 C200.978,22.828 199.937,22.001 199.937,20.349 C199.937,19.058 200.636,18.18 201.632,17.75 C202.496,17.372 203.702,17.303 204.623,17.199 L204.623,16.993 C204.623,16.614 204.655,16.167 204.43,15.841 C204.235,15.547 203.864,15.427 203.537,15.427 C202.931,15.427 202.39,15.737 202.26,16.382 C202.231,16.524 202.126,16.666 201.983,16.674 L200.442,16.507 C200.312,16.478 200.168,16.374 200.204,16.175 C200.56,14.305 202.248,13.74 203.762,13.74 C204.535,13.74 205.547,13.946 206.155,14.532 C206.928,15.254 206.854,16.218 206.854,17.268 L206.854,19.746 C206.854,20.491 207.164,20.817 207.455,21.219 C207.557,21.363 207.58,21.537 207.45,21.642 C207.125,21.915 206.548,22.415 206.23,22.698 L206.226,22.694 Z M204.623,18.818 C204.623,19.437 204.639,19.954 204.326,20.505 C204.074,20.951 203.671,21.227 203.223,21.227 C202.616,21.227 202.257,20.762 202.257,20.074 C202.257,18.72 203.475,18.473 204.623,18.473 L204.623,18.818 L204.623,18.818 Z"
                fill="#FFFFFE"
              ></path>
              <path
                d="M185.381,22.694 C185.277,22.788 185.123,22.796 185.005,22.731 C184.477,22.293 184.383,22.09 184.093,21.672 C183.222,22.561 182.601,22.828 181.473,22.828 C180.133,22.828 179.091,22.001 179.091,20.349 C179.091,19.058 179.789,18.18 180.787,17.75 C181.65,17.372 182.854,17.303 183.778,17.199 L183.778,16.993 C183.778,16.614 183.807,16.167 183.585,15.841 C183.39,15.547 183.019,15.427 182.692,15.427 C182.085,15.427 181.545,15.737 181.414,16.382 C181.386,16.524 181.281,16.666 181.137,16.674 L179.596,16.507 C179.465,16.478 179.321,16.374 179.359,16.175 C179.713,14.305 181.401,13.74 182.913,13.74 C183.688,13.74 184.699,13.946 185.31,14.532 C186.083,15.254 186.01,16.218 186.01,17.268 L186.01,19.746 C186.01,20.491 186.318,20.817 186.61,21.219 C186.709,21.363 186.734,21.537 186.604,21.642 C186.28,21.915 185.702,22.415 185.384,22.698 L185.381,22.694 Z M183.778,18.818 C183.778,19.437 183.792,19.954 183.479,20.505 C183.227,20.951 182.825,21.227 182.378,21.227 C181.769,21.227 181.414,20.762 181.414,20.074 C181.414,18.72 182.627,18.473 183.778,18.473 L183.778,18.818 L183.778,18.818 Z"
                fill="#FFFFFE"
              ></path>
            </g>
          </g>
        </symbol>
        <symbol id="amazonprime-black" viewBox="0 0 149 93" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <path
                d="M14.573,37.109 C16.695,37.109 18.259,36.389 19.264,34.949 C20.268,33.508 20.771,31.234 20.771,28.127 C20.771,24.981 20.278,22.688 19.292,21.247 C18.306,19.807 16.734,19.087 14.573,19.087 C12.603,19.087 10.744,19.598 9.002,20.622 L9.002,35.574 C10.669,36.597 12.527,37.109 14.573,37.109 Z M2.123,54.449 C1.63,54.449 1.27,54.343 1.042,54.135 C0.816,53.927 0.701,53.557 0.701,53.027 L0.701,15.107 C0.701,14.577 0.816,14.208 1.042,13.999 C1.27,13.791 1.63,13.686 2.123,13.686 L6.216,13.686 C7.088,13.686 7.618,14.103 7.808,14.936 L8.206,16.415 C9.343,15.316 10.716,14.444 12.327,13.8 C13.939,13.156 15.577,12.833 17.245,12.833 C20.921,12.833 23.83,14.178 25.972,16.87 C28.114,19.561 29.185,23.2 29.185,27.785 C29.185,30.931 28.654,33.679 27.593,36.029 C26.532,38.38 25.091,40.189 23.272,41.458 C21.452,42.728 19.367,43.363 17.018,43.363 C15.463,43.363 13.986,43.116 12.583,42.624 C11.181,42.132 9.988,41.449 9.002,40.577 L9.002,53.027 C9.002,53.557 8.897,53.927 8.69,54.135 C8.481,54.343 8.111,54.449 7.58,54.449 L2.123,54.449 L2.123,54.449 Z"
                fill="#0A0B09"
              ></path>
              <path
                d="M36.172,42.737 C35.679,42.737 35.319,42.624 35.091,42.396 C34.864,42.169 34.75,41.809 34.75,41.316 L34.75,15.107 C34.75,14.577 34.864,14.208 35.091,13.999 C35.319,13.791 35.679,13.686 36.172,13.686 L40.265,13.686 C41.137,13.686 41.667,14.103 41.857,14.936 L42.596,18.007 C44.112,16.34 45.542,15.155 46.888,14.454 C48.234,13.753 49.664,13.402 51.181,13.402 L51.977,13.402 C52.507,13.402 52.886,13.507 53.113,13.715 C53.341,13.923 53.454,14.293 53.454,14.823 L53.454,19.598 C53.454,20.092 53.35,20.451 53.142,20.678 C52.933,20.906 52.564,21.02 52.033,21.02 C51.768,21.02 51.427,21.001 51.01,20.963 C50.593,20.925 50.062,20.906 49.418,20.906 C48.546,20.906 47.486,21.03 46.235,21.276 C44.984,21.522 43.922,21.836 43.051,22.214 L43.051,41.316 C43.051,41.809 42.946,42.169 42.739,42.396 C42.53,42.624 42.159,42.737 41.629,42.737 L36.172,42.737"
                fill="#0A0B09"
              ></path>
              <path
                d="M58.889,42.737 C58.397,42.737 58.036,42.624 57.809,42.396 C57.581,42.169 57.468,41.809 57.468,41.316 L57.468,15.107 C57.468,14.577 57.581,14.208 57.809,13.999 C58.036,13.791 58.397,13.686 58.889,13.686 L64.347,13.686 C64.877,13.686 65.247,13.791 65.455,13.999 C65.663,14.208 65.769,14.577 65.769,15.107 L65.769,41.316 C65.769,41.809 65.663,42.169 65.455,42.396 C65.247,42.624 64.877,42.737 64.347,42.737 L58.889,42.737 Z M61.618,9.195 C60.177,9.195 59.022,8.797 58.151,8.001 C57.278,7.205 56.843,6.125 56.843,4.76 C56.843,3.395 57.278,2.315 58.151,1.519 C59.022,0.723 60.177,0.326 61.618,0.326 C63.058,0.326 64.214,0.723 65.086,1.519 C65.957,2.315 66.394,3.395 66.394,4.76 C66.394,6.125 65.957,7.205 65.086,8.001 C64.214,8.797 63.058,9.195 61.618,9.195 L61.618,9.195 Z"
                fill="#0A0B09"
              ></path>
              <path
                d="M74.229,42.737 C73.735,42.737 73.375,42.624 73.148,42.396 C72.921,42.169 72.807,41.809 72.807,41.316 L72.807,15.107 C72.807,14.577 72.921,14.208 73.148,13.999 C73.375,13.791 73.735,13.686 74.229,13.686 L78.322,13.686 C79.194,13.686 79.724,14.103 79.913,14.936 L80.368,16.471 C82.377,15.145 84.187,14.208 85.798,13.658 C87.408,13.108 89.067,12.833 90.773,12.833 C94.184,12.833 96.59,14.047 97.992,16.471 C99.925,15.183 101.744,14.255 103.45,13.686 C105.155,13.117 106.918,12.833 108.738,12.833 C111.39,12.833 113.446,13.572 114.905,15.05 C116.364,16.528 117.095,18.594 117.095,21.247 L117.095,41.316 C117.095,41.809 116.989,42.169 116.782,42.396 C116.573,42.624 116.203,42.737 115.673,42.737 L110.216,42.737 C109.723,42.737 109.363,42.624 109.136,42.396 C108.908,42.169 108.794,41.809 108.794,41.316 L108.794,23.066 C108.794,20.489 107.638,19.201 105.326,19.201 C103.279,19.201 101.213,19.693 99.129,20.678 L99.129,41.316 C99.129,41.809 99.025,42.169 98.817,42.396 C98.608,42.624 98.239,42.737 97.708,42.737 L92.25,42.737 C91.757,42.737 91.398,42.624 91.17,42.396 C90.943,42.169 90.829,41.809 90.829,41.316 L90.829,23.066 C90.829,20.489 89.672,19.201 87.362,19.201 C85.239,19.201 83.155,19.713 81.108,20.735 L81.108,41.316 C81.108,41.809 81.002,42.169 80.795,42.396 C80.586,42.624 80.216,42.737 79.686,42.737 L74.229,42.737"
                fill="#0A0B09"
              ></path>
              <g transform="translate(0.000000, 12.000000)">
                <path
                  d="M134.946,13.739 C136.878,13.739 138.28,13.445 139.153,12.857 C140.024,12.27 140.46,11.37 140.46,10.157 C140.46,7.769 139.039,6.575 136.196,6.575 C132.558,6.575 130.454,8.812 129.886,13.284 C131.402,13.588 133.088,13.739 134.946,13.739 Z M136.821,31.59 C132.121,31.59 128.512,30.302 125.991,27.724 C123.47,25.147 122.211,21.452 122.211,16.638 C122.211,11.712 123.498,7.845 126.076,5.04 C128.654,2.236 132.236,0.833 136.821,0.833 C140.347,0.833 143.104,1.686 145.094,3.391 C147.083,5.097 148.078,7.371 148.078,10.214 C148.078,13.056 147.007,15.208 144.866,16.667 C142.725,18.126 139.57,18.855 135.401,18.855 C133.24,18.855 131.363,18.647 129.772,18.23 C129.999,20.769 130.767,22.589 132.074,23.687 C133.382,24.788 135.363,25.337 138.016,25.337 C139.076,25.337 140.11,25.27 141.114,25.137 C142.117,25.005 143.511,24.711 145.292,24.257 C145.406,24.218 145.52,24.19 145.634,24.171 C145.747,24.152 145.842,24.142 145.918,24.142 C146.562,24.142 146.884,24.579 146.884,25.45 L146.884,28.065 C146.884,28.672 146.799,29.098 146.629,29.344 C146.458,29.592 146.126,29.809 145.634,29.998 C142.866,31.059 139.929,31.59 136.821,31.59 L136.821,31.59 Z"
                  fill="#0A0B09"
                ></path>
                <path
                  d="M133.847,62.674 C117.634,74.638 94.131,81 73.892,81 C45.528,81 19.985,70.515 0.655,53.062 C-0.862,51.691 0.491,49.82 2.316,50.881 C23.17,63.016 48.96,70.324 75.596,70.324 C93.564,70.324 113.316,66.595 131.49,58.886 C134.231,57.723 136.529,60.692 133.847,62.674"
                  fill="#0A0B09"
                ></path>
                <path
                  d="M140.593,54.971 C138.519,52.317 126.887,53.714 121.66,54.341 C120.076,54.53 119.831,53.146 121.258,52.144 C130.538,45.627 145.743,47.508 147.511,49.691 C149.291,51.892 147.043,67.137 138.347,74.413 C137.009,75.531 135.737,74.934 136.33,73.457 C138.287,68.57 142.668,57.629 140.593,54.971"
                  fill="#0A0B09"
                ></path>
              </g>
            </g>
          </g>
        </symbol>
      </svg>
    </>
  );
}

export default SvgSpriteMap;

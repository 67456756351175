import { PrismText, PrismIcon } from '@prism-ui/react';
import './style.css';
import classNames from 'classnames';

interface Props {
  left: string;
  right?: string;
  rule?: boolean;
  prominent?: boolean;
  indented?: boolean;
  card?: string;
  provider?: string;
}

function TableRow({ left, right, rule, prominent, indented, card, provider }: Props) {
  const tableRowClass = classNames({
    'table-row': true,
    'table-row-rule': rule && !prominent,
    'table-row-rule-prominent': rule && prominent,
    'table-row-prominent': !rule && prominent,
    'table-row-indented': indented,
    'table-row-card': card,
  });

  const tableRowKeyClass = classNames({
    'table-row-key-card': card,
    'table-row-key-prominent': prominent,
    'table-row-key': !prominent,
  });

  return (
    <div className={tableRowClass}>
      <div className={tableRowKeyClass}>
        {card && provider && (
          <div className="table-row-key-card-icon">
            <svg className="prepaid-card-icon" role="img">
              <title>{provider}</title>
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#${card}`} fill="#fff" />
            </svg>
          </div>
        )}
        <PrismText className={rule ? 'table-key-bold' : ''} display="bodyL">
          {left}
        </PrismText>
      </div>
      {(right || card) && (
        <div className={prominent ? 'table-row-value-prominent' : 'table-row-value'}>
          <PrismText display="bodyL">{right}</PrismText>
          {card && <PrismIcon name="check"></PrismIcon>}
        </div>
      )}
    </div>
  );
}

export default TableRow;

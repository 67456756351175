import { PrismText } from '@prism-ui/react';
import './style.css';

interface Props {
  left: string;
  right: string;
}

function TableSubtotal({ left, right }: Props) {
  return (
    <div className="table-subtotal">
      <PrismText display="heading6"> {left} </PrismText>
      <PrismText className="table-subtotal-right" display="heading6">
        {right}
      </PrismText>
    </div>
  );
}

export default TableSubtotal;

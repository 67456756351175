export const letterOfAgency = 'LETTER_OF_AGENCY';

export const respOrgAgreement = 'RESP_ORG';

export const contractAddendum = ['PACKAGE_CONTRACT_ADDENDUM', 'ALACARTE_CONTRACT_ADDENDUM'];

export const voiceOfAgreement = 'E911';

export const packagePromotion = 'PACKAGE_CONTRACT_ADDENDUM';

export const signNow = 'SIGN_NOW';

export const customerInitials = 'CUSTOMER_INITIALS';

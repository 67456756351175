// library imports
import { PrismGrid } from '@prism-ui/react';
// components from the inside the application
import Documents from '../components/documents/index';

const Home = () => {
  return (
    <>
      <PrismGrid>
        <Documents />
      </PrismGrid>
    </>
  );
};

export default Home;
import { RegistrationAPIPasswordValidationCodes } from '../../../utility/global-constants/registrationAPICodes';

const ProcessServerSideError = (messages, availableFunctions) => {
    let isError = false;
    let funcsToRun = [];

    for (const message in messages) {
        if (messages[message]) {
            switch (messages[message].code) {
                case RegistrationAPIPasswordValidationCodes.PasswordLengthValidationFailed:
                    funcsToRun.push(() => availableFunctions.setPeMinMax('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordShouldHaveUppercaseLetter:
                    funcsToRun.push(() => availableFunctions.setPeOneUppercase('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordShouldHaveLowercaseLetter:
                    funcsToRun.push(() => availableFunctions.setPeOneLowercase('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordShouldHaveNumber:
                    funcsToRun.push(() => availableFunctions.setPeOneDigit('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordCannotContainFirstName:
                    funcsToRun.push(() => availableFunctions.setPeNoFirstLastName('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordCannotContainLastName:
                    funcsToRun.push(() => availableFunctions.setPeNoFirstLastName('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordDoesNotMatchConfirmPassword:
                    funcsToRun.push(() => availableFunctions.setPeMustMatch('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordShouldHaveSpecialCharacter:
                    funcsToRun.push(() => availableFunctions.setPeOneSpecialChar('INVALID'));
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.PasswordCanBeEasilyGuessed:
                    funcsToRun.push(() => availableFunctions.setEasyToGuessError());
                    isError = true;
                    break;
                case RegistrationAPIPasswordValidationCodes.SecurityAnswerFailed:
                    funcsToRun.push(() => availableFunctions.setSecurityQuestionError());
                    isError = true;
                    break;
                default:
                    break;
            }
        }
    }

    return {
        isError,
        funcsToRun
    };
};

export default ProcessServerSideError;
import { PrismButton, PrismText } from '@prism-ui/react';
import './style.css';
import Container from '../../components/container';
import AddressInfo from './addressInfo';
import AutoAccountLink from './autoAccountLink';
import { getConfig } from '../../utility/env';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import './style.css';
import { registrationTypeValue } from '../../utility/global-constants/registrationStates';

export default function AutoPayconfirmation(props: any) {
  const env = getConfig();
  var buttonHref = !props.isUserAgent ? props.autoPayData?.registrationUrl : '';
  var buttonName = '';
  var title = '';
  var description = '';

  if (props.registrationState.type === registrationTypeValue.linkAccount) {
    buttonName = props.autoPayData?.linkButton;
    description = props.autoPayData?.linkDescription;
    title = props.autoPayData?.linkTitle;
  }
  else {
    buttonName = props.autoPayData?.orderButton;
    title = props.autoPayData?.orderTitle;
    description = props.autoPayData?.orderDescription;
  }

  return (
    <div>
      <Container>
        {!props.autoPayData && props.isOnboardingUrlLoading && <LoadingIndicator />}
        {props.autoPayData && (
          <div>
            <div className="autopayConfirmationRule"></div>
            <div className="title-container">
              <div>
                <PrismText display={'heading8'}>{props.autoPayData?.title}</PrismText>
              </div>
              <div className="title-description">
                <PrismText display={'bodyL'}>{props.autoPayData?.description}</PrismText>
              </div>
            </div>
            <div className="autopayConfirmationRule"></div>
            <div className="heading-text">
              <PrismText display={'heading5'}>{props.autoPayData?.descriptionTitle}</PrismText>
            </div>
            <div>
              <AutoAccountLink
                iconName={`${env.content.baseUrl}/images/icons/calendar.svg`}
                title={props.autoPayData?.scheduleTitle}
                description={props.autoPayData?.scheduleDescription}
              ></AutoAccountLink>
              {props.autoPayData?.isEPCEcoBillDiscountEnabled ? (
                <AutoAccountLink
                  iconName={`${env.content.baseUrl}/images/icons/dollar.svg`}
                  title={props.autoPayData?.activeTitle}
                  description={props.autoPayData?.activeDescription}
                ></AutoAccountLink>
              ) : (
                <AutoAccountLink
                  iconName={`${env.content.baseUrl}/images/icons/Autopay.svg`}
                  title={props.autoPayData?.enrollTitle}
                  description={props.autoPayData?.enrollDescription}
                ></AutoAccountLink>
              )}
            </div>
            <div className="autopayConfirmationRule"></div>
            <div className="title-container">
              <div>
                <PrismText display={'heading8'}>{title}</PrismText>
              </div>
              <div className="title-description">
                <PrismText display={'bodyL'}>{description}</PrismText>
              </div>
            </div>
            <div slot="bar-actions" className="order-button">
              <PrismButton disabled={props.isUserAgent} display="fill" target="_blank" href={buttonHref}>
                {buttonName}
              </PrismButton>
            </div>

            <div className="autopayConfirmationRule"></div>

            <div className="autopay-account-address-container">
              <AddressInfo
                accountLinkTitle={props.autoPayData?.accountTitle}
                firstName={props.autoPayData?.firstName}
                lastName={props.autoPayData?.lastName}
                address={props.autoPayData?.billingAddress}
                isAutoPay={true}
              ></AddressInfo>
              <AddressInfo
                accountLinkTitle={props.autoPayData?.addressTitle}
                firstName={''}
                lastName={''}
                address={props.autoPayData?.serviceAddress}
                isAutoPay={true}
              ></AddressInfo>
              <AddressInfo
                accountLinkTitle={props.autoPayData?.comcastTitle}
                firstName={''}
                lastName={''}
                address={''}
                occupation={props.autoPayData?.salesrepBussinessName}
                emailId={props.autoPayData?.salesrepEmail}
                telephoneNumber={props.autoPayData?.salesrepPhone}
                isAutoPay={true}
              ></AddressInfo>
            </div>

            <div className="autopayConfirmationRule"></div>

            <div className="quetion-section">
              <PrismText display={'heading8'}>{props.autoPayData?.questionTitle}</PrismText>
              <div className="sales-section">
                <PrismText display={'bodyL'}>{props.autoPayData?.contactTitle}</PrismText>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

import TableHeader from '../tableHeader';
import { Heading } from '../../../interfaces';
import './style.css';

interface Props extends Heading {
  children: JSX.Element[] | JSX.Element;
}

function Table({ heading, children }: Props) {
  return (
    <div className="table">
      <TableHeader heading={heading} />
      <div className="table-body">{children}</div>
    </div>
  );
}

export default Table;

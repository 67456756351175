import axios from 'axios';
import { getConfig } from './env';
import api from '../api';

const env = getConfig();

export const setSALogging = (trackingID, id) => {
    id && !window.sessionStorage.getItem('sessionID') && window.sessionStorage.setItem('sessionID', id);
    const sessionID = window.sessionStorage.getItem('sessionID')

    if (env.serviceLoggingEnabled) {
        axios.interceptors.request.use((config) => {
        config.metadata = {startTime: new Date()}
        return config;
        });
        axios.interceptors.response.use((response) => {
        response.config.metadata.endTime = new Date();
        api.serviceLogging(response, trackingID, sessionID);
        return response;
        }, (error) => {
        error.response.config.metadata.endTime = new Date();
        api.serviceLogging(error.response, trackingID, sessionID);
        return Promise.reject(error);
        });
    }
};

export const isSuccessCode = (code) => {
    if (code > 199 && code < 300) {
        return true;
    }

    return false
}

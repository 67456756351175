import { Address, Row } from '../..';

export type LineOfBusinessType = 'internet' | 'tv' | 'voice';
export default interface Initialize {
  contact: Initialize_ContactInformation;
  attachments: Initialize_Attachments;
  salesAgentInfo: Initialize_SalesAgentInfo;
  cartItems: InitializeCartItems[];
  totalMonthlyCharges: number;
  totalOneTimeCharges: number;
  offer: InitializeOffer;
  specialInstructions: string;
}
export interface Initialize_ContactInformation {
  billingAddress: Address;
  serviceAddress: Address;
  firstName: string;
  lastName: string;
  emailAddress: string;
  businessName: string;
  telephoneNumber: string;
}
interface Initialize_Attachments {
  documents: Initialize_Attachment_Documents[];
  contracts: Initialize_Attachment_Contracts[];
}

export interface Initialize_Attachment_Contracts {
  contractType: string;
  status: string;
}

interface Initialize_Attachment_Documents {
  type: string;
  status: string;
}
export interface Initialize_SalesAgentInfo {
  telephoneNumber: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  businessName: string;
}

export interface InitializeCartItems {
  hideInCart: boolean;
  configurationType: string;
  oneTimeCharges: number;
  monthlyCharges: number;
  configAttributes: Object;
  giftCardType: string;
  name: string;
  lineOfBusiness: LineOfBusinessType;
  groupSequence: number;
  isChildItem: boolean;
  selectedValue: string;
  standardName: string;
}

interface InitializeOffer {
  name: string;
  price: number;
  promoCodeText: string;
  contractDescription: string;
  isEPCEcoBillDiscountEnabled: boolean;
}

export interface OfferType {
  provider: string;
  left: string;
  card: string;
}
export interface InitializePackageProps {
  name: string;
  total: string;
  packageHeader: string;
  contractDetails: string | null;
  monthlyChargesHeader: string;
  oneTimeChargesDescription: string;
  oneTimeChargesHeader: string;
  oneTimeTotalCharges: string;
  specialOffersHeader: string;
  totalMonthlyChargesHeader: string;
  monthlyCharges: ChargesType;
  oneTimeCharges: ChargesType;
  specialOffers: SpecialOffer;
  professionalInstall: tempChargeValType | null;
}

export interface InitializeTempVal {
  monthlyCharges: tempChargeType;
  oneTimeCharges: tempChargeType;
  specialOffers: OfferType[];
  professionalInstall: proInstallType | null;
}

type tempChargeType = {
  [key in LinesOfBusiness]: tempChargeValType[];
};

export type proInstallType = {
  left: string;
  right: number;
};

export type tempChargeValType = {
  left: string;
  right: string;
  config: object;
  rows: Row[];
  groupSequence: number;
  configurationType: string;
  lineOfBusinessUnformatted: string;
  standardName: string;
};

type SpecialOffer = {
  rows: OfferType[];
};

export enum LinesOfBusiness {
  INTERNET = 'internet',
  VOICE = 'voice',
  TV = 'tv'
}
export interface ChargesType {
  total: string;
  rows: ChargesKeyValues[];
}

type ChargesKeyValues = {
  heading: string;
  rows: tempChargeValType[];
};

enum PackageKeys {
  INTERNET = 'INTERNET',
  TV = 'TV',
  VOICE = 'VOICE',
  PHONE = 'PHONE',
  AMAZON = 'AMAZON',
  VISA = 'VISA'
}

type PackageValues = string;

export type PackageInfoType = Record<PackageKeys, PackageValues>;

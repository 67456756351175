import { PrismText } from '@prism-ui/react';
import './style.css';

export default function AddressInfo(props: any) {
  return (
    <div className={!props.isAutoPay ? 'account-address-section' : 'auto-pay-account-address-section'}>
      <PrismText display={'heading8'}>{props.accountLinkTitle}</PrismText>
      <PrismText display={'bodyL'}>
        {props.firstName} {props.lastName}
      </PrismText>
      <PrismText display={'bodyL'}>{props.address}</PrismText>
      <PrismText display={'bodyL'}>{props.occupation}</PrismText>
      <PrismText display={'bodyL'}>{props.emailId}</PrismText>
      <PrismText display={'bodyL'}>{props.telephoneNumber}</PrismText>
    </div>
  );
}

import { PrismText } from '@prism-ui/react';
import './style.css';

interface IValidationCriteriaProps {
    criteriaText: string,
    criteriaStatus: string
};

enum CriteriaStatus {
    neutral = "NEUTRAL",
    valid = "VALID",
    invalid = "INVALID",
};

function ValidationCriteria({criteriaText, criteriaStatus}: IValidationCriteriaProps) {
    const iconBaseUrl = 'https://content.ecom.np.digital.business.comcast.com/images/icons/';
    const neutralicon = 'default';
    const failureicon = 'failure';
    const successicon = 'success';
    let fullIconPath = '';

    switch (criteriaStatus) {
        case CriteriaStatus.neutral:
            fullIconPath = `${iconBaseUrl}acctreg-${neutralicon}.svg`;
            break;
        case CriteriaStatus.valid:
            fullIconPath = `${iconBaseUrl}acctreg-${successicon}.svg`;
            break;
        case CriteriaStatus.invalid:
            fullIconPath = `${iconBaseUrl}acctreg-${failureicon}.svg`;
            break;
        default:
            fullIconPath = `${iconBaseUrl}acctreg-${neutralicon}.svg`;
    }

    return (
        <div className="validation-criteria">
            <span className="criteria-img-container"><img src={fullIconPath} className='criteria-image' alt="" /></span>
            <PrismText tag="span" display="label" className='text'>{criteriaText}</PrismText>
        </div>
    );
}

export default ValidationCriteria; 
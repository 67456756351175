import { PackageInfoType } from '../../interfaces/api/initialize';

export const nextStepBar = {
  nextButton: 'NEXT',
  securedEncryption: 'SECURED ENRYPTION',
};

export const contactInformation = {
  companyName: 'Company Name',
  contactName: "Contact/Signer's Name",
  serviceAddress: 'Service Address',
  billingAddress: 'Billing Address',
  businessPhoneNumber: 'Business Phone Number',
};

export const PACKAGE_INFO: PackageInfoType = {
  INTERNET: 'internet',
  TV: 'tv',
  VOICE: 'voice',
  PHONE: 'phone',
  AMAZON: 'amazon',
  VISA: 'visa',
};

export const LINES_OF_BUSINESS = [PACKAGE_INFO.INTERNET, PACKAGE_INFO.TV, PACKAGE_INFO.PHONE];
export const GIFT_CARDS = [PACKAGE_INFO.AMAZON, PACKAGE_INFO.VISA];

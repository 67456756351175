import { Content_Pages_TermsAndConditions } from '../../interfaces/api/content';
import { Initialize_Attachment_Contracts, Initialize_ContactInformation } from '../../interfaces/api/initialize';
import { contractAddendum } from '../../utility/global-constants/contractTypes';

export const termsAndConditionsObject = (
  termsAndConditions: Content_Pages_TermsAndConditions,
  contracts: Initialize_Attachment_Contracts[],
  contact: Initialize_ContactInformation,
  specialInstructions: string = ''
) => {
  const termsOfConditionsTypes = contracts.map(contract =>
    contract.status === 'Pending' ? contract.contractType : ''
  );
  return {
    title: termsAndConditions.title,
    description: termsAndConditions.description.replace('{{contact.emailAddress}}', contact.emailAddress),
    signNowlabels: termsAndConditions.signNow,
    sections: Object.keys(termsAndConditions)
      .filter(key => termsAndConditions[key].type && termsOfConditionsTypes.includes(termsAndConditions[key].type))
      .map(key => {
        if (contractAddendum.includes(termsAndConditions[key].type)) {
          termsAndConditions[key].description = specialInstructions;
        }
        termsAndConditions[key].acknowledgement = termsAndConditions[key].acknowledgement
          .replace('{{contact.firstName}}', contact.firstName)
          .replace('{{contact.lastName}}', contact.lastName);
        return termsAndConditions[key];
      })
  };
};

import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { PrismCard, PrismGrid, PrismLoading, PrismNotification, PrismText } from '@prism-ui/react';
import axios from 'axios';
import { Fragment } from 'react';

const queryClient = new QueryClient();
const Result = () => {
  const { isLoading, error, data, isFetching } = useQuery('getSingleUser', () =>
    axios.get('https://jsonplaceholder.typicode.com/users/2').then((res) => res.data)
  );

  if (isLoading) return <PrismLoading>Loading...</PrismLoading>;

  if (error) return <PrismNotification status="error">An error has occurred: {error.message}</PrismNotification>;

  return (
    <PrismGrid>
      <PrismCard heading={data.name} className="w-1/3" align="left">
        <ul>
          <li>
            <strong>UserName:</strong> {data.username}
          </li>
          <li>
            <strong>E-Mail:</strong> {data.email}
          </li>
          <li>
            <address>
              {data.address.street} <br />
              {data.address.suite}
              <br />
              {data.address.city}
              <br />
              {data.address.zipcode}
            </address>
          </li>
          <li>
            <strong>Phone:</strong> {data.phone}
          </li>
          <li>
            <strong>Website:</strong> <a href={data.website}>{data.website}</a>
          </li>
        </ul>
        {isFetching && (
          <PrismText tag="span" display="caption">
            updating...
          </PrismText>
        )}
      </PrismCard>
    </PrismGrid>
  );
};

const About = () => {
  return (
    <Fragment>
      <PrismGrid>
        <PrismText tag="h2" display="display2">
          {' '}
          About{' '}
        </PrismText>
      </PrismGrid>
      <QueryClientProvider client={queryClient}>
        <Result />
      </QueryClientProvider>
    </Fragment>
  );
};

export default About;

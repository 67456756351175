import { localhost, devUrl, qaUrl, pqaUrl, stgUrl, perfUrl, prodUrl } from './global-constants/pages';
import { sharedConfig } from '../config/shared/config';
import { SENSITIVE_FIELDS } from './global-constants/sensitiveFields';

export const getIsLocal = () => window.location.hostname === localhost;

export const getIsDev = () => window.location.hostname === devUrl;

export const getIsQA = () => window.location.hostname === qaUrl;

export const getIsPqa = () => window.location.hostname === pqaUrl;

export const getIsStage = () => window.location.hostname === stgUrl;

export const getIsPerf = () => window.location.hostname === perfUrl;

export const getIsProd = () => window.location.hostname === prodUrl;

export const getQueryStringObject = () => {
  var queryParams = {};
  let queryStringValues = new URLSearchParams(document.location.search.substring(1));
  const queryString = new URLSearchParams([...queryStringValues].map(([key, value]) => [key.toLowerCase(), value]));
  queryParams['id'] = queryString.get('id');
  queryParams['timestamp'] = queryString.get('timestamp');
  let securityHashCode = queryString.get('securityhash');
  queryParams['securityhash'] = securityHashCode ? securityHashCode.replaceAll(' ', '+') : '';
  queryParams['accesstype'] = queryString.get('accesstype') ? queryString.get('accesstype') : '';
  queryParams['oppid'] = queryString.get('oppid') ? queryString.get('oppid') : '';
  queryParams['isvalidationdisabled'] = queryString.get('isvalidationdisabled')
    ? queryString.get('isvalidationdisabled')
    : false;
  queryParams['sourcesystem'] = queryString.get('sourcesystem');
  queryParams['triggererror'] = queryString.get('triggererror') ? true : false;
  return queryParams;
};

// check session query string parameters
export const checkSessionQueryParams = () => {
  const params = getQueryStringObject();
  if (params.oppid !== '') return params.id !== null && params.securityhash !== '' && params.timestamp !== null;
  return params.id !== null && params.accesstype !== '' && params.securityhash !== '' && params.timestamp !== null;
};

export const getSignBlobObject = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

/* istanbul ignore next */
// Returns an array of sensitive fields
export const getRequestSensitiveFields = (dataURI) => {
  switch (dataURI) {
    case sharedConfig.frictionLess.routes.createUser:
      return SENSITIVE_FIELDS.accountRegistration.createUser;
    case sharedConfig.frictionLess.routes.inviteUser:
      return SENSITIVE_FIELDS.accountRegistration.inviteUser;
    default:
      return null;
  }
};

/* istanbul ignore next */
// Returns an array of sensitive fields
export const getResponseSensitiveFields = (dataURI) => {
  switch (dataURI) {
    case sharedConfig.frictionLess.routes.initialize:
      return SENSITIVE_FIELDS.frictionless.initialize;
    default:
      return null;
  }
};

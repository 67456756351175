import { apiKeys } from './../../utility/global-constants/authentication';

export const pqaConfig = {
  name: 'pqa',
  logging: {
    baseUrl: 'https://dev.core-gateway.np.api-business.comcast.com/',
    key: 'JKbf1AExxt9Hmim9OsZsC3zjAMsBzOog4jPRJFLD'
  },
  eSign: {
    baseUrl: 'https://pqa.ecom-gateway.np.api-business.comcast.com/'
  },
  content: {
    baseUrl: 'https://content.ecom.np.digital.business.comcast.com'
  },
  myAccount: {
    baseUrl: 'https://business.pqa.comcast.com/'
  },
  frictionLess: {
    baseUrl: ''
  },
  dataLayer: {
    src: 'https://static.cimcontent.net/data-layer/nonprod/datalayer-commercial-dtm.min.js?appId=busn_ecom'
  },
  signNow: {
    confirmation: {
      new: 'https://comcast--sfdce2e2--c.cs53.visual.force.com/apex/FT_FlexiQuote?id=<opp_id>&page=schedule',
      existing: 'https://qa.np.fxbuyflow.business.comcast.com/sign-now/success?id=<opp_id>',
    },
    back: {
      new: 'https://comcast--sfdce2e2--c.cs53.visual.force.com/apex/FT_FlexiQuote?id=<opp_id>',
      existing: 'https://qa.np.fxbuyflow.business.comcast.com/sign-now/reconfigure?id=<opp_id>',
    },
  },
  'api-key': apiKeys.preProd,
  serviceLoggingEnabled: true,
  initializeCall504DelayEnabled: true,
  updateCall504DelayEnabled: true
};

import { apiKeys } from './../../utility/global-constants/authentication';

export const localConfig = {
  name: 'local',
  logging: {
    baseUrl: 'https://dev.core-gateway.np.api-business.comcast.com/',
    key: 'JKbf1AExxt9Hmim9OsZsC3zjAMsBzOog4jPRJFLD',
  },
  content: {
    baseUrl: 'https://content.ecom.np.digital.business.comcast.com',
  },
  eSign: {
    baseUrl: 'http://localhost:3001/',
  },
  myAccount: {
    baseUrl: 'http://localhost:3001/',
  },
  frictionLess: {
    baseUrl: 'http://localhost:3001/',
  },
  dataLayer: {
    src: 'https://static.cimcontent.net/data-layer/nonprod/datalayer-commercial-dtm.min.js?appId=busn_ecom',
  },
  signNow: {
    confirmation: {
      new: 'http://localhost:3001/',
      existing: 'http://localhost:3001/',
    },
    back: {
      new: 'http://localhost:3001/',
      existing: 'http://localhost:3001/',
    },
  },
  'api-key': apiKeys.preProd,
  serviceLoggingEnabled: true,
  initializeCall504DelayEnabled: true,
  updateCall504DelayEnabled: true
};

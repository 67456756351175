import TableRow from '../tableRow';
import TableSubsectionHeader from '../tableSubsectionHeader';
import { Heading, Row } from '../../../interfaces';
import './style.css';
interface Props extends Heading {
  subsectionKey: string;
  rows?: Row[];
  rule?: boolean;
}

function TableSubsection({ subsectionKey, heading, rows = [], rule }: Props) {
  return (
    <div className={rule ? 'table-subsection-rule' : ''}>
      {heading && <TableSubsectionHeader heading={heading} />}
      {rows.map((row: Row, rowIndex: number) => {
        const tableRowKey = `${subsectionKey}-table-row-${rowIndex}`;
        return (
          <div key={tableRowKey}>
            <TableRow left={row.left} right={row.right} prominent={true} />
            {row.rows &&
              row.rows.map((row: Row, innerRowIndex: number) => (
                <TableRow
                  key={`${tableRowKey}-inner-row-${innerRowIndex}`}
                  left={row.left}
                  right={row.right}
                  prominent={true}
                  indented={true}
                />
              ))}
          </div>
        );
      })}
    </div>
  );
}

export default TableSubsection;

import { PrismText } from '@prism-ui/react';
import { Heading } from '../../../interfaces';
import './style.css';

function TableSubsectionHeader({ heading }: Heading) {
  return (
    <div className="table-subsection-header">
      <PrismText display="functional2">{heading}</PrismText>
    </div>
  );
}

export default TableSubsectionHeader;

import React, { useContext, useEffect, useState } from 'react';
import NavigationBar from '../../components/navigation-bar';
import Container from '../../components/container';
import { PrismButton, PrismIcon, PrismPageHeading, PrismSectionHeading } from '@prism-ui/react';
import StepperNavigation from '../../containers/stepper/stepperNavigation';
import TableFormat from '../../components/tables/contactInformation';
import { useGetCustomerAndContentData, pageAllocation } from '../../hooks/dml/documents.hooks';
import { phoneTransfer } from '../../utility/global-constants/pages';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import NextStepBar from '../../containers/stepper/nextStepBar';
import { useQuery } from 'react-query';
import { Redirect, useHistory } from 'react-router-dom';
import derivedData from './derivedData';
import { BOOTSTRAP_CALL } from '../../utility/global-constants/queryKeys';
import { getBootstrapData } from '../../api/index';
import './style.css';
import { SessionContext } from '../../../src/App';
import { getConfig } from '../../utility/env';
import { pageViewUpdate, spaTransition } from '../../utility/updateDataLayer';
import TrackingData from '../../components/experienceTracking/TrackingData';
import Contracts from '../../components/contracts';
import LoadingIndicator from '../../components/molecules/atoms/accents/loadingIndicator';
import { isLastStep } from '../../utility/steps';
import { onSubmit, checkBoxEventListener, checkInvalidMessage } from '../../utility/submit';
import { IFormInputs } from '../../interfaces';

export default function PhoneTransfer() {
  const { isLoading, isError, initializeData, contentData } = useGetCustomerAndContentData();
  const data = derivedData({ contentData, initializeData });
  var pageData: any;
  var history = useHistory();
  var isSubmission: boolean = false;
  const sessionData = useContext(SessionContext);
  const [isLoader, setIsLoader] = useState(false);

  const { data: bootstrapData } = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;
  const env = getConfig();

  const page = {
    pageInfo: {
      screenName: phoneTransfer?.screenName,
    },
  };

  const { handleSubmit, control } = useForm<IFormInputs>();

  useEffect(() => {
    document.title = 'Phone Transfer Agreements';
    checkBoxEventListener();
    pageViewUpdate();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return history?.listen(() => {
      spaTransition();
    });
  }, [history]);

  if (data) {
    pageData = pageAllocation(initializeData, contentData);
  }

  if (isError) return <Redirect to="/error" />;

  if (pageData && pageData.finalRoutes) {
    pageData.finalRoutes.forEach(function (route: any, index: any) {
      if (window.location.href.indexOf(route) > -1) {
        if (pageData.finalRoutes[pageData.finalRoutes.length - 1] === route) {
          isSubmission = true;
        }
      }
    });
  }

  const nextButtonClicked = (item: any) => {
    setImmediate(checkInvalidMessage);
  };

  const submit: SubmitHandler<IFormInputs> = async (value: any) => {
    setIsLoader(true);
    const pageHistory = await onSubmit(isSubmission, sessionData?.id, cimaToken, history?.location?.pathname, pageData);
    history.push(pageHistory);
  };

  return (
    <div>
      <NavigationBar page={phoneTransfer.name} />
      <StepperNavigation
        pageData={pageData}
        history={history}
        backButtonText={contentData?.common?.actions?.backAction}
      />
      <Container>
        {(!data || isLoading || isLoader) && <LoadingIndicator />}
        {data && (
          <div>
            <PrismSectionHeading heading={data.pageSectionHeading} />
            <PrismPageHeading heading={data.pageHeading}>
              <div slot="action" className="phone-transfer-action">
                <PrismButton display="link" onClick={() => window.print()}>
                  <PrismIcon slot="icon" path={`${env?.content?.baseUrl}/images/icons/print.svg`} />
                  Print
                </PrismButton>
              </div>
            </PrismPageHeading>
            <div className="phone-transfer-tables">
              <TableFormat heading={data?.orderInfoHeading} rows={data?.orderInfoRows} />

              {data.transferNumberRows.length !== 0 && (
                <TableFormat heading={data.transferNumberHeading} rows={data.transferNumberRows} />
              )}

              {data?.transferTollFreeRows?.length !== 0 && (
                <TableFormat heading={data.transferTollFreeNumberHeading} rows={data.transferTollFreeRows} />
              )}
            </div>

            <form onSubmit={handleSubmit(submit)}>
              {data.termsAndConditions?.sections?.length !== 0 && (
                <div className="phone-transfer-terms-and-condition">
                  <PrismPageHeading
                    heading={data.termsAndConditions.title}
                    description={data.termsAndConditions.description}
                  />
                  <Controller
                    name="legalAgreement"
                    control={control}
                    defaultValue={true}
                    rules={{ required: true }}
                    render={({ field }) => <Contracts sections={data.termsAndConditions.sections} />}
                  />
                </div>
              )}
              <NextStepBar
                pageData={pageData}
                history={history}
                completeOrder={isLastStep({ pageData })}
                sessionData={sessionData}
                nextClicked={nextButtonClicked}
                content={data}
              />
            </form>
          </div>
        )}
      </Container>
      <TrackingData type="page" trackingInfo={page} />
    </div>
  );
}

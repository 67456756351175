import Table from '../../listTable/table';
import TableSectionHeader from '../../listTable/tableSectionHeader';
import TableSubtotal from '../../listTable/tableSubtotal';
import TableSubsection from '../../listTable/tableSubsection';
import SpecialOffers from '../specialOffers';
import { Row } from '../../../interfaces';
import OfferDetails from './offerDetails';

interface HeadingRows {
  heading: string;
  rows?: Row[];
}

function Package({ service }: any) {
  const monthlyChargesRows =
    service?.monthlyCharges && service?.monthlyCharges?.rows ? service.monthlyCharges.rows : [];
  const oneTimeChargesRows = service?.oneTimeCharges && service?.oneTimeCharges.rows ? service.oneTimeCharges.rows : [];
  const proInstall = service?.professionalInstall ? 1 : 0;
  const oneTimeCharges =
    oneTimeChargesRows?.length !== 0
      ? (oneTimeChargesRows[0]?.rows?.length || 0) +
        (oneTimeChargesRows[1]?.rows?.length || 0) +
        (oneTimeChargesRows[2]?.rows?.length || 0) +
        proInstall
      : 0;

  const offerDetails = {
    name: service?.name,
    total: service?.total,
    contractDetails: service?.contractDetails
  };

  return (
    <Table heading={service?.packageHeader}>
      <TableSectionHeader heading={service?.monthlyChargesHeader} />
      <OfferDetails service={offerDetails}></OfferDetails>
      {monthlyChargesRows.map(
        (subsection: HeadingRows, index: number) =>
          subsection?.rows?.length !== 0 && (
            <TableSubsection
              key={`monthly-charges-subsection-${index}`}
              subsectionKey={`monthly-charges-subsection-${index}`}
              heading={subsection.heading}
              rows={subsection.rows ? subsection.rows : []}
              rule={true}
            />
          )
      )}
      <TableSubtotal left={service?.totalMonthlyChargesHeader} right={service?.monthlyCharges?.total} />
      {service?.specialOffers?.rows?.length !== 0 && (
        <>
          <TableSectionHeader heading={service?.specialOffersHeader} />
          <SpecialOffers specialOffers={service?.specialOffers} />
        </>
      )}
      {oneTimeCharges > 0 && (
        <>
          <TableSectionHeader
            heading={service?.oneTimeChargesHeader}
            description={service?.oneTimeChargesDescription}
          />
          {service?.professionalInstall && (
            <TableSubsection
              key={`one-time-charges-subsection-proInstall`}
              subsectionKey={`one-time-charges-subsection-proInstall`}
              heading={''}
              rows={[service?.professionalInstall]}
              rule={true}
            />
          )}
          {oneTimeChargesRows.map(
            (subsection: HeadingRows, index: number) =>
              subsection?.rows?.length !== 0 && (
                <TableSubsection
                  key={`one-time-charges-subsection-${index}`}
                  subsectionKey={`one-time-charges-subsection-${index}`}
                  heading={subsection.heading}
                  rows={subsection.rows}
                  rule={true}
                />
              )
          )}
          <TableSubtotal left={service?.oneTimeTotalCharges} right={service?.oneTimeCharges?.total} />
        </>
      )}
    </Table>
  );
}

export default Package;

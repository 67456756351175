export const registrationTypeValue = {
  invite: 'Invite',
  selfregistration: 'SelfRegistration',
  linkAccount: 'LinkAccount',
  signNow: 'SignNow',
};

export const registrationStatusValue = {
  success: 'Success',
  failed: 'Failed',
};

import { localConfig } from '../config/local/config';
import { devConfig } from './../config/dev/config';
import { qaConfig } from './../config/qa/config';
import { pqaConfig } from './../config/pqa/config';
import { stgConfig } from './../config/stage/config';
import { perfConfig } from './../config/perf/config';
import { prodConfig } from './../config/prod/config';
import { getIsLocal, getIsDev, getIsQA, getIsPqa, getIsStage, getIsPerf, getIsProd } from './helper';

export const getConfig = () => {
  switch (true) {
    case getIsLocal():
      return localConfig;
    case getIsDev():
      return devConfig;
    case getIsQA():
      return qaConfig;
    case getIsPqa():
      return pqaConfig;
    case getIsStage():
      return stgConfig;
    case getIsPerf():
      return perfConfig;
    case getIsProd():
      return prodConfig;
    default:
      return devConfig;
  }
};

import { Address } from '../../interfaces';
import { includes } from 'lodash';

export function fixCasing(str: string) {
  return str
    .match(/\w+/g)
    ?.map((ele) => {
      return `${ele[0]}${ele.slice(1).toLowerCase()}`;
    })
    .join(' ');
}

export function formatAddress(address: Address) {
  let { streetAddress, unitNumber, city, state, zipCode } = address;

  return `${fixCasing(streetAddress)}${
    unitNumber ? `, ${/[A-Za-z]{2,}/g.test(unitNumber) ? helpers.fixCasing(unitNumber) : unitNumber},` : ','
  } ${helpers.fixCasing(city)}, ${state}${zipCode ? ` ${zipCode}` : ''}`;
}

export function formatPhone(str: string) {
  return str.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function returnFileSize(number: number) {
  if(number < 1024) {
    return number + 'bytes';
  } else if(number >= 1024 && number < 1048576) {
    return (number/1024).toFixed(1) + 'KB';
  } else if(number >= 1048576) {
    return (number/1048576).toFixed(1) + 'MB';
  }
}

export function maskFields(data:any, sensitiveFields:string[]) {
  const hasSensitiveFields = sensitiveFields && sensitiveFields.length;
  const isObject = typeof data === 'object' && !Array.isArray(data);

  if (!data || !hasSensitiveFields || !isObject) {
    return data;
  }
  return cloneObjectMasked(data, sensitiveFields);
}

function maskValue(value:any, trailingDigits = 4, character = 'X') {
  const string = String(value);
  const regex = new RegExp(`.(?=.{${trailingDigits}})`, 'g');
  return string.replace(regex, character);
}

function cloneObjectMasked(object:any, sensitiveFields:string[]) {
  return Object.entries(object).reduce((acc:any, [key, value]) => {
    if (object[key] && includes(sensitiveFields, key)) {
      acc[key] = maskValue(value, 0, '*');
    } else if (typeof value === 'object') {
      acc[key] = maskFields(value, sensitiveFields);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
}


const helpers = {
  formatAddress,
  fixCasing,
  formatPhone,
  returnFileSize,
  maskFields
};

export default helpers;

import { PrimaryManagerState } from '../../interfaces';
import { Initialize_SalesAgentInfo } from '../../interfaces/api/initialize';
import { registrationTypeValue } from '../../utility/global-constants/registrationStates';

export const updateTempValues = (
  confirmationContent: any,
  salesAgentInfo: Initialize_SalesAgentInfo,
  registrationType: string,
  primaryManager: PrimaryManagerState
) => {
  if (registrationType === registrationTypeValue.invite) {
    return {
      accountTitle: confirmationContent?.inviteUser?.title,
      accountDescription: replaceContact(confirmationContent?.inviteUser?.description || '', primaryManager),
      servicesTitle: null,
      servicesDescription: null,
      billPaymentTitle: null,
      billPaymentDescription: null,
      notificationsTitle: null,
      notificationsDescription: null,
      myAccountButton: null,
      contactUs: null,
      dashboardbutton: null,
    };
  } else {
    return {
      accountTitle: confirmationContent?.accountManagement?.title,
      accountDescription: confirmationContent?.accountManagement?.description,
      servicesTitle: confirmationContent?.accountManagement?.servicesTitle,
      servicesDescription: confirmationContent?.accountManagement?.servicesDescription,
      billPaymentTitle: confirmationContent?.accountManagement?.billPaymentTitle,
      billPaymentDescription: confirmationContent?.accountManagement?.billPaymentDescription,
      notificationsTitle: confirmationContent?.accountManagement?.notificationsTitle,
      notificationsDescription: confirmationContent?.accountManagement?.notificationsDescription,
      myAccountButton: confirmationContent?.accountManagement?.myAccountButton,
      contactUs:
        registrationType === registrationTypeValue.signNow
          ? null
          : replaceSalesAgentInfo(confirmationContent?.contactUs || '', salesAgentInfo),
      dashboardbutton: registrationType === registrationTypeValue.signNow ? confirmationContent?.dashboardbutton : null,
    };
  }
};

export const replaceSalesAgentInfo = (contactUs: string, salesAgentInfo: Initialize_SalesAgentInfo) => {
  return contactUs
    .replace('{{salesAgentInfo.firstName}}', salesAgentInfo?.firstName)
    .replace('{{salesAgentInfo.lastName}}', salesAgentInfo?.lastName)
    .replace('{{salesAgentInfo.telephoneNumber}}', salesAgentInfo?.telephoneNumber)
    .replace('{{salesAgentInfo.emailAddress}}', salesAgentInfo?.emailAddress);
};

export const replaceContact = (description: string, contact: PrimaryManagerState) => {
  return description
    .replace('{{contact.firstName}}', contact?.firstName)
    .replace('{{contact.lastName}}', contact?.lastName)
    .replace('{{contact.emailAddress}}', contact?.emailAddress);
};

export const autoUpdateTempValues = (
  confirmationContent: any,
  salesAgentInfo: Initialize_SalesAgentInfo
) => {
    return {
      title:confirmationContent?.orderConfirmation?.title,
      description:confirmationContent?.orderConfirmation?.description,
      descriptionTitle:confirmationContent?.accountManagement?.title,
      scheduleTitle: confirmationContent?.accountManagement?.scheduleTitle,
      scheduleDescription: confirmationContent?.accountManagement?.scheduleDescription,
      enrollTitle: confirmationContent?.accountManagement?.enrollTitle,
      enrollDescription: confirmationContent?.accountManagement?.enrollDescription,
      activeTitle: confirmationContent?.accountManagement?.activeTitle,
      activeDescription: confirmationContent?.accountManagement?.activeDescription,
      orderTitle: confirmationContent?.accountManagement?.orderTitle,
      orderDescription: confirmationContent?.accountManagement?.orderDescription,
      linkTitle: confirmationContent?.accountManagement?.linkTitle,
      linkDescription: confirmationContent?.accountManagement?.linkDescription,
      linkButton: confirmationContent?.accountManagement?.linkButton,
      orderButton: confirmationContent?.accountManagement?.orderButton,
      accountTitle: confirmationContent?.accountTitle,
      addressTitle: confirmationContent?.addressTitle,
      comcastTitle: confirmationContent?.comcastTitle,
      questionTitle: confirmationContent?.questionTitle,
      contactTitle: confirmationContent?.contactTitle,
      salesrepFirstName : salesAgentInfo?.firstName,
      salesrepLastName : salesAgentInfo?.lastName,
      salesrepEmail : salesAgentInfo?.emailAddress,
      salesrepBussinessName : salesAgentInfo?.businessName,
      salesrepPhone : salesAgentInfo?.telephoneNumber
    };
  }


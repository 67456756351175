import { PrismText, PrismIcon } from '@prism-ui/react';
import './style.css';

const fileIconUrl = 'https://content.ecom.np.digital.business.comcast.com/images/icons/pdf.svg';

interface UploadedDocumentProps {
    header: string;
    description: string;
    fileName: string;
    fileSize: string;
    handleClick: Function;
}

export default function UploadedDocument ({
    header,
    description,
    fileName,
    fileSize,
    handleClick
}: UploadedDocumentProps) {

    return (
        <div className='uploaded-doc-container'>
            <div className='uploaded-doc-description'>
                <PrismText className='uploaded-doc-header'>{header}</PrismText>
                <PrismText className='uploaded-doc-header-desc'>{description}</PrismText>
            </div>
            <span className='uploaded-doc-info'>
                <span className='uploaded-doc-icon'>
                    <PrismIcon slot="icon" name="pdf" path={fileIconUrl} size="md" theme="outline" />
                </span>
                <div>
                    <PrismText className='upload-doc-file-name'>{fileName}</PrismText>
                    <PrismText className='upload-doc-file-size'>{fileSize}</PrismText>
                </div>
                <button
                    onClick={() => handleClick()}
                    className='uploaded-doc-button'
                    >
                    X
                </button>
            </span>
        </div>
    );
};

import './style.css';
import InviteRegistration from '../../components/account-registration/InviteRegistration/index';
import SelfRegistration from '../../components/account-registration/SelfRegistration/index';
import SetupText from '../../components/account-registration/setupText/index';
import NavigationBar from '../../components/navigation-bar';
import { useHistory } from 'react-router-dom';
import derivedDataTerms from './derivedData';
import { useGetCustomerAndContentData, pageAllocation } from '../../hooks/dml/documents.hooks';
import NextStepBar from '../../containers/stepper/nextStepBar';
import { useEffect, useContext, useState, useRef } from 'react';
import { pageRegistration } from '../../utility/global-constants/pages';
import { pageViewUpdate, spaTransition, customPageStartEvent } from '../../utility/updateDataLayer';
import TrackingData from '../../components/experienceTracking/TrackingData';
import { SessionContext } from '../../../src/App';
import StepperNavigation from '../../containers/stepper/stepperNavigation';
import { getBootstrapData } from '../../api/index';
import { useQuery } from 'react-query';
import { BOOTSTRAP_CALL, trackingID } from '../../utility/global-constants/queryKeys';
import { tracking } from '../../utility/global-constants/analytics';
import { version as packageJsonVersion } from '../../../package.json';

export default function AccountRegistration() {
  const [isSelfReg, setIsSelfReg] = useState(true);
  const { initializeData, contentData } = useGetCustomerAndContentData();
  const sessionData = useContext(SessionContext);
  const elementRef = useRef(0);
  let data = derivedDataTerms({ contentData, initializeData });
  let pageData: any;
  let history = useHistory();

  const page = {
    pageInfo: {
      screenName: pageRegistration.screenName,
    },
    codebase: {
      name: tracking.BSEE,
      releaseVersion: packageJsonVersion,
    },
    category: {
      primaryCategory: tracking.SELF_SERVICE,
      subCategory1: tracking.FRICTIONLESS,
      subCategory2: tracking.INVITATION_SETUP,
      businessType: tracking.BUSN,
      siteType: tracking.MY_ACCOUNT,
      designType: tracking.RESPONSIVE,
    },
  };

  const trackingData = {
    page: page,
    siteInfo: {
      sessionID: sessionData.id,
      visitorID: trackingID,
    },
  };

  useEffect(() => {
    document.title = 'Set Up My Account';
    pageViewUpdate();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return history?.listen(() => {
      spaTransition();
    });
  }, [history]);

  useEffect(() => {
    customPageStartEvent(elementRef.current, tracking.ACCOUNT_REGISTRATION);
  }, []);

  if (data) {
    pageData = pageAllocation(initializeData, contentData);
    console.log(pageData);
  }

  const { data: bootstrapData } = useQuery(BOOTSTRAP_CALL, getBootstrapData);
  const cimaToken = bootstrapData?.cimaToken?.accessToken;

  const getNextStepBar = () => {
    return <NextStepBar pageData={pageData} history={history} completeOrder={true} isAcctReg={true} content={data} />;
  };

  return (
    <>
      <NavigationBar page={pageRegistration.name} />
      <StepperNavigation
        pageData={pageData}
        history={history}
        backButtonText={contentData?.common?.actions?.backAction}
      />
      <TrackingData type="page" trackingInfo={page} />
      <div className="account-reg-container" ref={elementRef}>
        <SetupText
          header={data!.accountSetup!.title}
          textOne={isSelfReg ? data!.setupTextOne : data!.inviteUserRegistration!.title}
          textTwo={isSelfReg ? data!.setUpTextTwo : data!.inviteUserRegistration!.description}
          linkText={
            isSelfReg
              ? data!.accountSetup!.inviteUserLink.text
              : data!.inviteUserRegistration!.selfRegistrationLink.text
          }
          linkHref={data!.accountSetup!.inviteUserLink.href}
          tooltipLabel={data!.accountSetup!.modal!.header}
          isSelfReg={isSelfReg}
          regTypeToggle={setIsSelfReg}
          modalHeader={data!.accountSetup!.modal!.header}
          modalBody={data!.accountSetup!.modal!.body}
        />
        {isSelfReg ? (
          <SelfRegistration
            nameLabel={data!.selfRegistration!.name}
            firstName={data!.firstName}
            lastName={data!.lastName}
            emailLabel={data!.selfRegistration!.emailAddress}
            emailValue={data!.emailAddress}
            createPassword={data!.selfRegistration!.createPassword}
            confirmPassword={data!.selfRegistration!.confirmPassword}
            securityQuestion={data!.selfRegistration!.securityQuestion}
            securityQuestions={data!.selfRegistration!.securityQuestions}
            securityAnswer={data!.selfRegistration!.securityAnswer}
            PeText={data!.selfRegistration!.passwordValidation}
            errorTreatment={data!.selfRegistration!.errorTreatment}
            showLink={data!.selfRegistration!.showLink}
            requiredField={data!.selfRegistration!.requiredFields}
            termsOfServiceText={data!.termsOfServiceText}
            termsOfServiceLink={data!.termsOfServiceLink}
            sessionId={sessionData.id}
            cimaToken={cimaToken}
            getNextStepBar={getNextStepBar}
          />
        ) : (
          <InviteRegistration
            firstName={data!.inviteUserRegistration!.firstName}
            lastName={data!.inviteUserRegistration!.lastName}
            emailAddress={data!.inviteUserRegistration!.emailAddress}
            confirmEmailAddress={data!.inviteUserRegistration!.confirmEmailAddress}
            requiredFields={data!.inviteUserRegistration!.requiredFields}
            sessionId={sessionData.id}
            cimaToken={cimaToken}
            getNextStepBar={getNextStepBar}
            validation={data!.inviteUserRegistration!.validation}
          />
        )}
      </div>
      <TrackingData type="digitalData" trackingInfo={trackingData} />
    </>
  );
}

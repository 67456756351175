import { AccountRegistrationState, PrimaryManagerState } from '../../interfaces';
import Content from '../../interfaces/api/content';
import Initialize from '../../interfaces/api/initialize';
import { formatAddress } from '../../utility/format';
import { autoUpdateTempValues } from './helper';
import { registrationTypeValue } from '../../utility/global-constants/registrationStates';
import { confirmationPages, confirmationScreenNames } from '../../utility/global-constants/pages';

export default function autoPayDerivedDataConfirmation<
  T extends Content,
  U extends Initialize,
  X extends AccountRegistrationState,
  V extends PrimaryManagerState,
  W extends String
>({
  contentData,
  initializeData,
  registrationState,
  onboardingUrl,
}: {
  contentData: T;
  initializeData: U;
  registrationState: X;
  primaryManager: V;
  onboardingUrl: W;
}) {
  const { contact, salesAgentInfo, offer: { isEPCEcoBillDiscountEnabled }  } = initializeData;
  const { firstName, lastName, billingAddress, serviceAddress ,emailAddress} = contact;
  const { type: registrationType } = registrationState;

  const autoPay = contentData?.pages?.confirmation?.autopay;
  let tempValues;

  let screenName;
  let pageLoadEventValue;
  switch (registrationType) {
    case registrationTypeValue.selfregistration:
        screenName = confirmationScreenNames.selfRegistrationSuccess;
        pageLoadEventValue = confirmationPages.selfRegistrationSuccess;
      break;
    case registrationTypeValue.linkAccount:
      screenName = confirmationScreenNames.linkAccount;
      pageLoadEventValue = confirmationPages.linkAccount;
      break;
  }

  tempValues = autoUpdateTempValues(autoPay, salesAgentInfo);

  const finalData = {
    firstName: firstName,
    lastName: lastName,
    billingAddress: formatAddress(billingAddress),
    serviceAddress: formatAddress(serviceAddress),
    title: tempValues.title.replace('{{contact.firstName}}', firstName),
    description: tempValues.description.replace('{{contact.emailAddress}}', emailAddress),
    descriptionTitle: tempValues.descriptionTitle,
    scheduleTitle: tempValues.scheduleTitle,
    scheduleDescription: tempValues.scheduleDescription,
    enrollTitle: tempValues.enrollTitle,
    enrollDescription: tempValues.enrollDescription,
    activeTitle: tempValues.activeTitle,
    activeDescription: tempValues.activeDescription,
    orderTitle: tempValues.orderTitle,
    orderDescription: tempValues.orderDescription,
    linkTitle: tempValues.linkTitle,
    linkDescription: tempValues.linkDescription,
    linkButton: tempValues.linkButton,
    orderButton: tempValues.orderButton,
    accountTitle: tempValues.accountTitle,
    addressTitle: tempValues.addressTitle,
    comcastTitle: tempValues.comcastTitle,
    questionTitle: tempValues.questionTitle,
    contactTitle: tempValues.contactTitle,
    salesrepFirstName : tempValues.salesrepFirstName,
    salesrepLastName : tempValues.salesrepLastName,
    salesrepEmail : tempValues.salesrepEmail,
    salesrepBussinessName : tempValues.salesrepBussinessName,
    salesrepPhone : tempValues.salesrepPhone,
    isEPCEcoBillDiscountEnabled: isEPCEcoBillDiscountEnabled,
    registrationUrl: onboardingUrl,
    screenName: screenName,
    pageLoadEventValue: pageLoadEventValue
  }
  return finalData;
}

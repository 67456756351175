export const sharedConfig = {
  frictionLess: {
    routes: {
      initialize: 'frictionless/v1/initialize',
      bootstrap: 'bootstrap/v1/frictionless/token',
      content: '/frictionless.json',
      errorLog: 'logging-api/event',
      update: 'frictionless/v1/update',
      createUser: 'accountregistration/v4/frictionless/user/create',
      inviteUser: 'accountregistration/v4/frictionless/user/invite',
      contractData: 'accountregistration/v4/contract',
      myAccount: 'account/',
      uploadDocument: 'frictionless/v1/document/signedurl',
      onboardingUrl: 'accountregistration/v4/frictionless/onboarding/',
      updateStatus: 'frictionless/v1/status/document/',
      termsOfServiceUrl: 'terms/web',
    },
  },
};

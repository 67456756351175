export const SENSITIVE_FIELDS = {
    accountRegistration: {
        // 'accountregistration/v3/frictionless/user/create'
        createUser: [
            'password',
            'confirmPassword',
            'secretAnswer'
        ],
        inviteUser: [
            'confirmEmail',
            'firstName',
            'lastName',
            'contactEmail'
        ]
    },
    frictionless: {
        // 'frictionless/v1/initialize'
        initialize: [
            'firstName',
            'lastName',
            'emailAddress',
            'telephoneNumber'
        ]
    }
};